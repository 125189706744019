import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Animation_Custom from '../AnimationCustom/Animation_Custom';

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(null);

  useEffect(() => {
      const fetchData = async () => {
          const token = localStorage.getItem("accessToken");
          const url = `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?campaign_type=3,4&status=3&all=true&page=${page}`;

          try {
            setLoading(true)
              const response = await fetch(url, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              });

              const result = await response.json();

              if(result?.error_code === 400){
                  setHasMore(null);
              }

              if (result.status === 'SUCCESS') {
                  setCampaigns(prevCampaigns => [...prevCampaigns, ...result.data.results]);
                  setHasMore(result.data.next);
              } else {
                  if (result.code === 'token_not_valid') {
                      window.localStorage.removeItem("refreshToken");
                      window.localStorage.removeItem("accessToken");
                      window.localStorage.removeItem("userName");
                      window.localStorage.removeItem("isLogedin");
                      console.error('Token is invalid or expired');
                      window.location.href = '/signin';
                  } else {
                      throw new Error('Failed to fetch data');
                  }
              }
          } catch (error) {
              console.error('Failed to fetch campaigns:', error);
          } finally {
              setLoading(false);
          }
      };

      fetchData();
  }, [page]);

    const containerRef = useRef();

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = container;
            // Check if user scrolled to bottom of container
            if (
                scrollTop + clientHeight >= scrollHeight - 10 && // Adjust threshold as needed
                hasMore && 
                !loading
            ) {
                setPage(prevPage => prevPage + 1); // Increment page number
            }
        };

        // Attach scroll event listener to the container
        container.addEventListener("scroll", handleScroll);
    
        // Cleanup the event listener on unmount
        return () => container.removeEventListener("scroll", handleScroll);
    }, [hasMore, loading]);

    if (!campaigns || campaigns.length === 0) {
        // return <h1 className="text-center">No campaigns available <Link to='/campaigns_create' style={{ color: "#120c34" }}><u>create</u></Link>.</h1>;
        return(
            <div className="d-flex justify-content-center align-items-center">
                        <Animation_Custom />
                    </div>
        )
    }


    return (
      <div className="row" ref={containerRef} id="campaign_container" style={{ height: "80vh", overflowY: "scroll", scrollbarWidth: "none", msOverflowStyle: "none", "&::-webkit-scrollbar": { display: "none" } }}>
        {campaigns.map((campaign, index) => (
          <div className="col-xs-12 col-sm-6 col-lg-4 col-xl-3 mb-3" key={index}>
            <Link
              to={`/manage_campaigns?id=${campaign.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="card text-center border-0">
                <div
                  className="card-body text-white px-0 py-0 pt-4 border-0"
                  style={{
                    borderRadius: "25px",
                    background: "#120c34",
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={campaign.brand.images}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                    />
                    <h2 className="text-center">{campaign.brand.name}</h2>
                  </div>
                  <h3 className="mb-3">{campaign.title}</h3>
                  <div
                    className="card-footer m-0 p-0"
                    style={{
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderBottomLeftRadius: "25px",
                      borderBottomRightRadius: "25px",
                    }}
                  >
                    <p
                      className="m-0"
                      style={{
                        fontSize: "x-large",
                        fontWeight: "bold",
                      }}
                    >
                      ${campaign.min_earn}-${campaign.max_earn}
                    </p>
                    <p
                      className="m-0"
                      style={{
                        fontSize: "large",
                      }}
                    >
                      Estimated earning
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}

        {loading && (
            <div className="d-flex justify-content-center align-items-center">
                <Animation_Custom />
            </div>
        )}
      </div>
    );
};

export default function All_campaign() {
    return (
      <div className="container-fluid px-5 mt-4">
        <div className="container-fluid mb-5">
          <div className="row mt-3">
            <div className="col-md-4 col-12">
              <h3>
                campaigns{" "}
                <span className="hide" style={{ fontWeight: "800" }}>
                  / all campaign's
                </span>
              </h3>
            </div>

            <div className="offset-md-4 col-md-4 col-12 text-md-right text-center">
              <Link
                to="/home/active_campaign"
                className="btn btn-lg"
                style={{
                  width: "100%",
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  marginLeft: "-32px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    border: "0",
                    borderRadius: "0",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  active campaign
                </span>
              </Link>
            </div>
          </div>
        </div>
        <Campaigns />
      </div>
    );
}
