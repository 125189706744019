import React, { useEffect, useRef, useState } from "react";
import Errors from "../../CommonComponents/Errors";
import { format } from "date-fns";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const EditMarketplaceRequest = ({ data, handleClose, counter }) => {
  const [state, setState] = useState({
    data: [],
    countries: [],
    loading: true,
    min_age: "",
    max_age: "",
    gender: "",
    followers_min: "",
    followers_max: "",
    interests: [],
    isLoading: true,
    selected_creators: 0,
    isActive: false,
    showForm: false,
    currentStep: 1,
    page: 1,
    showModal: false,
    userID: "",
    startDate: "",
    requestType: "",
    Platform: "",
    requestInstructionsText: "",
    WebsiteUrl: "",
    file: null,
    countInput: 0, // in-feed photo post
    countInput1: 0, // 15-30 second reel
    countInput2: 0, // 3-frame photo story
    countInput3: 0, // 3-frame video story
    postFrequencyCount: "1 Post", // post frequency select option
    postFrequencyPer: "week", // post frequency select option
    offerAmount: "",
    productIncluded: "",
    productURL: "",
    productValue: "",
    count: 0,
    shouldNavigate: false,
    interestOptions: [],
  });
  const navigate = useNavigate();
  const maxLength = 800;
  const formRef = useRef();
  const nextStep = (event) => {
    event.preventDefault();
    const currentStep = state?.currentStep;
    let valid = true;
    const errors = {}; // To collect error messages for each field

    try {
      switch (currentStep) {
        case 1:
          if (!state.startDate) {
            errors.startDate = "Please select a start date.";
            valid = false;
          }
          if (!state.requestType) {
            errors.requestType = "Please select a request type.";
            valid = false;
          }
          if (!state.Platform) {
            errors.Platform = "Please select a platform.";
            valid = false;
          }
          break;
        case 2:
          if (!state.requestInstructionsText) {
            errors.requestInstructionsText =
              "Please enter a request instructions text.";
            valid = false;
          }
          if (!state.WebsiteUrl) {
            errors.WebsiteUrl = "Please enter a url.";
            valid = false;
          }
          break;
        // case 3:
        // if (state.contentGoal === "Content") {
        //   setState({ Platform: "" });
        //   if (!state.contentFormate) {
        //     errors.contentFormate = "Please select content format.";
        //     valid = false;
        //   }
        //   if (!state.minimumDileverable) {
        //     errors.minimumDileverable = "Please provide minimum deliverable.";
        //     valid = false;
        //   }
        // } else {
        //   if (!state.Platform) {
        //     errors.Platform = "Please select a platform.";
        //     valid = false;
        //   }
        //   if (!state.contentFormate) {
        //     errors.contentFormate = "Please select content format.";
        //     valid = false;
        //   }
        //   if (!state.minimumDileverable) {
        //     errors.minimumDileverable = "Please provide minimum deliverable.";
        //     valid = false;
        //   }
        // }
        // break;
        case 4:
          if (!state.offerAmount) {
            errors.offerAmount = "Please enter a offerAmount";
            valid = false;
          }

          if (!state.productIncluded) {
            errors.productIncluded = "Please select a product included";
            valid = false;
          }
          if (state.productIncluded === "yes" && !state.productURL) {
            errors.productURL = "Please enter a product url.";
            valid = false;
          }
          if (state.productIncluded === "yes" && !state.productValue) {
            errors.productValue = "Please enter a product value";
            valid = false;
          }
          break;
        case 5:
          if (!state.maximumBudget) {
            errors.maximumBudget = "Please enter the maximum budget.";
            valid = false;
          }
          if (!state.maximumAudience) {
            errors.maximumAudience = "Please enter the maximum audience size.";
            valid = false;
          }
          if (
            !state.participantAudienceSizeMIN ||
            !state.participantAudienceSizeMAX
          ) {
            errors.participantSize =
              "Please enter the min and max participant audience size.";
            valid = false;
          }
          if (!state.estimatedParticipants) {
            errors.estimatedParticipants =
              "Please enter the estimated number of participants.";
            valid = false;
          }
          if (!state.costPerTarget) {
            errors.costPerTarget = "Please enter the cost per target.";
            valid = false;
          }
          break;
        default:
          break;
      }
      if (valid) {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep + 1,
          errors: {},
        }));
        if (currentStep + 1 > 5) formRef.current.requestSubmit();
      } else {
        // If there are validation errors, update the errors state
        setState((prev) => ({ ...prev, errors }));
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };

  const prevStep = () => {
    if (state.currentStep === 1) {
      setState((prevState) => ({
        ...prevState,
        showForm: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  };

  function formatInputDate(dateStr) {
    // Create a Date object from the input string
    const date = new Date(dateStr);

    // Get the month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    // Return the formatted date in mm/dd/yyyy format
    return `${month}/${day}/${year}`;
  }

  const convertDate = (inputDate) => {
    // Parse the date from MM/DD/YYYY format
    const dateParts = inputDate.split("/");
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1; // Adjust month from 1-12 to 0-11 for JS Date
    const day = parseInt(dateParts[1], 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Format the date as YYYY-MM-DDT00:00:00.000000Z
    const isoString = date?.toISOString(); // Returns YYYY-MM-DDTHH:MM:SS.sssZ
    const startDatetime = isoString?.split("T")[0] + "T00:00:00.000000Z"; // Restrict time to 00:00:00.000000Z

    // Example to show how to use it in state, if needed
    // this.setState({ start_datetime: startDatetime });

    return startDatetime;
  };

  const handleInputFormChange = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
  const handleInputFormChange1 = (key, value, e) => {
    e.stopPropagation();
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const increment = (id, e) => {
    e.stopPropagation();
    const input = document.getElementById(id);
    let currentValue = parseInt(input.value, 10) || 0; // Ensure a number is always parsed
    let newValue = currentValue + 1;
    input.value = newValue; // Update the input's value
    handleInputFormChange(id, newValue); // Call your function with the new value
  };

  const decrement = (id, e) => {
    e.stopPropagation();
    const input = document.getElementById(id);
    let currentValue = parseInt(input.value, 10) || 0;
    let newValue = currentValue === 0 ? 0 : currentValue - 1;
    input.value = newValue;
    handleInputFormChange(id, newValue);
  };

  const handleDateSelect = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    setState((prev) => ({ ...prev, startDate: formattedDate }));
  };

  const handleDateChange = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    setState((prev) => ({ ...prev, startDate: date }));
    handleInputFormChange("startDate", formattedDate);
  };

  const handleInputChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from submitting traditionally
    const token = localStorage.getItem("accessToken");

    const requestBody = {
      title: "Market Place Request",
      // creator: parseInt(state.userID), // Assuming static for the example
      brand_id: 1, // Assuming static for the example
      start_datetime: convertDate(state.startDate),
      campaign_type: state.contentGoal == "UGC" ? 1 : 2, // Assuming contentGoal corresponds to campaign_type
      budget: parseInt(state.offerAmount), // Parse the budget to an integer
      creator_id: state.userID,
      counter: counter,
      details: {
        creator_id: state.userID,
        platform: state.Platform,
        description: state.requestInstructionsText,
        url: state.WebsiteUrl, // Assuming url corresponds to WebsiteUrl in state
        attachments: [], // Assuming no attachments
        photo_post: parseInt(state.countInput), // Conversion to integer from countInput
        reel: parseInt(state.countInput1), // Conversion to integer from countInput1
        photo_story: parseInt(state.countInput2), // Conversion to integer from countInput2
        video_story: parseInt(state.countInput3), // Conversion to integer from countInput3
        post_frequency: state.postFrequencyCount,
        post_schedule: state.postFrequencyPer, // Corresponds to postFrequencyPer in state
        product_included: state.productIncluded, // Assuming this should be an integer
        product_url: state.productURL, // Matches productURL in state
        product_value: parseFloat(state.productValue), // Assuming this should be a float
      },
    };
    const fetchOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include the Bearer token in the header
      },
      body: JSON.stringify(requestBody),
    };

    // Make the API request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/${data.id}`,
        fetchOptions
      );
      const responseData = await response.json(); // Assuming the server responds with JSON
      // console.log('API Response:', responseData);

      // Handle response data or errors here
      if (response.ok) {
        // Successful POST request (e.g., status code 200-299)
        // console.log('Campaign created successfully:', responseData);
        handleClose();
        navigate("/manage_req");
        window.location.href = "/manage_req";
      } else {
        // Server responded with a status code outside the 200 range (e.g., error)
        console.error("Failed to create campaign:", responseData);
      }
    } catch (error) {
      // An error occurred during the fetch operation
      console.error("Error during fetch:", error);
    }
  };

  useEffect(() => {
    if (data) {
      setState((prev) => ({
        ...prev,
        userID: data?.details?.creator_id,
        startDate:
          data?.start_datetime && formatInputDate(data?.start_datetime),
        requestType: data?.campaign_type === 1 ? "UGC" : "Posted Content",
        Platform: data?.details?.platform,
        requestInstructionsText: data?.details?.description,
        WebsiteUrl: data?.details?.url,
        offerAmount: data?.budget,
        countInput: data?.details?.photo_post,
        countInput1: data?.details?.reel,
        countInput2: data?.details?.photo_story,
        countInput3: data?.details?.video_story,
        postFrequencyCount: data?.details?.post_frequency,
        postFrequencyPer: data?.details?.post_schedule,
        productIncluded: data?.details?.product_included,
        productURL: data?.details?.product_url,
        productValue: data?.details?.product_value,
      }));
    }
  }, [data]);

  return (
    <form
      style={{ color: "white",  maxWidth: "100%", margin: "0 auto"  }}
      ref={formRef}
      onSubmit={handleSubmit}
    >
      {state?.currentStep === 1 && (
        <div
          className="text-center"
          style={{
            background: "#120c34",
            // width: "90%",
            // margin: 'auto',
            borderRadius: "25px",
            padding: "20px",
          }}
        >
            <ul id="progressbar" className="d-flex justify-content-center p-0 flex-wrap" style={{gap: '1rem'}} >
              <li className="active" id="step1" >
                <strong></strong>
              </li>
              <li id="step2">
                <strong></strong>
              </li>
              <li id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
          {/* <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required /> */}
          <h3 className="mt-5 fs-4 fs-md-3" style={{ fontWeight: "800" }}>
    request start date
  </h3>
          {/* <input type="text" onChange={onDate} onClick={(e) => e.stopPropagation()} name="startDate" className="input-box text-center rounded-pill mx-3 " style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} value={state.startDate} placeholder="MM/DD/YYYY" /> */}
          
          {/* <div style={{width: '90%', margin: 'auto'}}> */}
          <DatePicker
            selected={
              state.startDate
                ? format(state.startDate, "MM/dd/yyyy", new Date())
                : null
            }
            onSelect={handleDateSelect}
            onChange={handleDateChange}
            // isClearable
            dateFormat="MM/dd/yyyy" // Ensures date format is MM/DD/YYYY in the picker
            placeholderText="mm/dd/yyyy"
            minDate={new Date()}
            customInput={
              <input
                type="text"
                onChange={handleInputChange}
                onClick={(e) => e.stopPropagation()}
                name="startDate"
                className="input-box text-center rounded-pill margin-responsive"
                style={{
                  fontWeight: "800",
                  fontSize: "1em",
                  backgroundColor: "white",
                  width: "80%",
                  padding: "15px",
                  borderRadius: "25px",
                  color: "#120c34",
                }}
                value={
                  state.startDate ? format(state.startDate, "MM/dd/yyyy") : ""
                }
                placeholder="MM/DD/YYYY"
              />
            }
          />
          <Errors mid={true} msg={state?.errors?.startDate} />
          {/* </div> */}
          {/* <input type="text" onChange={e => handleInputChange('showDate', e.target.value)} onClick={(e) => e.stopPropagation()} name="startDate" className="input-box text-center rounded-pill margin-responsive" style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} placeholder="xx/xx/xxx" /> */}

          <h3 className="mt-5 fs-4 fs-md-3" style={{ fontWeight: "800" }}>
            request type
          </h3>
          <div className="row mt-3">
            <div className="col-12 col-sm-6 mb-3">
              <input
                onClick={(e) =>
                  handleInputFormChange1("requestType", e.target.value, e)
                }
                type="button"
                className=" rounded-pill margin-responsive selected w-100"
                style={{
                  color:
                    state.requestType === "Posted Content"
                      ? "#120c34"
                      : "white",
                  border: "2px solid white",
                  fontSize: "1em",
                  backgroundColor:
                    state.requestType === "Posted Content"
                      ? "white"
                      : "transparent",
                  padding: "15px",
                  borderRadius: "25px",
                }}
                value="Posted Content"
              />
            </div>
            <div className="col-12 col-sm-6" >
              <input
                onClick={(e) =>
                  handleInputFormChange1("requestType", e.target.value, e)
                }
                type="button"
                className=" rounded-pill margin-responsive w-100 "
                style={{
                  color: state.requestType === "UGC" ? "#120c34" : "white",
                  border: "2px solid white",
                  fontSize:"1em",
                  backgroundColor:
                    state.requestType === "UGC" ? "white" : "transparent",
                  // width: "50%",
                  padding: "15px 36px",
                  borderRadius: "25px",
                }}
                value="UGC"
              />
            </div>
          </div>
          <Errors mid={true} msg={state?.errors?.requestType} />

          <div className="text-center  ">
          <h3 className="mt-5 fs-4 fs-md-3" style={{ fontWeight: "800" }}>
            platform
          </h3>
            <div className="container">
              <div className="row">
              <div className="col-4 text-center">
                  <label htmlFor="logo1">
                    <img
                      src="/images/instagram.png"
                      onClick={(e) => e.stopPropagation()}
                      alt="Instagram Logo"
                      className="img-fluid-forms"
                      style={{
                        width: "80px",
                        height: "80px",
                        display: "block",
                        margin: "auto",
                      }}
                    />
                    <input
                      type="radio"
                      checked={state.Platform === "instagram"}
                      onChange={(e) =>
                        handleInputFormChange1("Platform", e.target.value, e)
                      }
                      onClick={(e) => e.stopPropagation()}
                      id="logo1"
                      name="logo"
                      value="instagram"
                    />
                  </label>
                </div>
                <div className="col-4 text-center">
                  <label htmlFor="logo2">
                    <img
                      src="/images/tiktok.png"
                      onClick={(e) => e.stopPropagation()}
                      alt="TikTok Logo"
                      className="img-fluid-forms"
                      style={{
                        width: "80px",
                        height: "80px",
                        display: "block",
                        margin: "auto",
                      }}
                    />
                    <input
                      type="radio"
                      checked={state.Platform === "tiktok"}
                      onClick={(e) =>
                        handleInputFormChange("Platform", e.target.value)
                      }
                      id="logo2"
                      name="logo"
                      value="tiktok"
                    />
                  </label>
                </div>
                <div className="col-4 text-center">
                  <label htmlFor="logo3">
                    <img
                      src="/images/youtube.png"
                      onClick={(e) => e.stopPropagation()}
                      alt="YouTube Logo"
                      className="img-fluid-forms"
                      style={{
                        width: "80px",
                        height: "80px",
                        display: "block",
                        margin: "auto",
                      }}
                    />
                    <input
                      type="radio"
                      checked={state.Platform === "youtube"}
                      onClick={(e) =>
                        handleInputFormChange("Platform", e.target.value)
                      }
                      id="logo3"
                      name="logo"
                      value="youtube"
                    />
                  </label>
                </div>
              </div>
            </div>
            <Errors mid={true} msg={state?.errors?.Platform} />
          </div>

          <div className="row mt-5 pb-3">
            <div className="col-6 text-center">
              <input
                type="button"
                onClick={(e) => prevStep(e)}
                className=" rounded-pill mx-3 bg-transparent text-white"
                style={{
                  border: "2px solid white",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  backgroundColor: "",
                  padding: "15px",
                  borderRadius: "25px",
                  color: "#120c34",
                }}
                value="cancel"
              />
            </div>
            <div className="col-6 text-center">
              <button
                type="button"
                className="btn btn-lg form-button"
                onClick={(e) => nextStep(e)}
                style={{
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  marginLeft: "-32px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    // backgroundColor: '#3898ec',
                    border: "0",
                    borderRadius: "0",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  continue
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      {state.currentStep === 2 && (
        <div
          className="text-center"
          style={{
            background: "#120c34",
            width: "100%",
            borderRadius: "25px",
            padding: "20px",
          }}
        >
          <ul id="progressbar" className="d-flex justify-content-center p-0 flex-wrap" style={{gap: '1rem'}} >
              <li id="step1" >
                <strong></strong>
              </li>
              <li  className="active" id="step2" >
                <strong></strong>
              </li>
              <li id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            <h3 className="mt-5" style={{ fontWeight: "800", fontSize: "clamp(18px, 4vw, 24px)", color: "#fff" }}>
              Request Instructions
            </h3>

          <div className=" mx-3">
            <div className=" position-relative">
              <textarea
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  handleInputFormChange(
                    "requestInstructionsText",
                    e.target.value
                  )
                }
                value={state.requestInstructionsText}
                className="form-control"
                style={{
                  fontSize: "18px",
                  height: "20em",
                  borderRadius: "25px",
                  resize: "none",
                }}
                placeholder="Tell the creators what you'd like to post"
                maxLength={maxLength}
              />
              <div
                className="position-absolute"
                style={{
                  bottom: "5px",
                  right: "10px",
                  fontSize: "12px",
                  color: "gray",
                }}
              >
                {/* Placeholder text, since we're not managing state here */}
                <b
                  style={{ color: "#120c34" }}
                >{`${maxLength}/${maxLength} characters remaining`}</b>
              </div>
            </div>
            <Errors msg={state?.errors?.requestInstructionsText} />
          </div>

          <div className="card mx-3 mt-3 px-2" style={{ borderRadius: "25px", margin: '1rem 0' }}>
            <div className="d-flex align-items-center">
              <span style={{ color: "#120c34" }}>
                <b>url:</b>
              </span>
              <input
                value={state.WebsiteUrl}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  handleInputFormChange("WebsiteUrl", e.target.value)
                }
                className="border  border-0 form-control"
                style={{
                  border: "0",
                  borderRadius: "25px",
                  boxShadow: "none",
                  border: "none",
                }}
                type="text"
                placeholder="yourwebiste.com"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
          <Errors className="mx-3" msg={state?.errors?.WebsiteUrl} />

          <div className="card mx-3 px-2" style={{ borderRadius: "25px" }}>
            <div className="d-flex">
              <h4 className="my-2 " style={{ color: "#120c34", fontSize: "clamp(16px, 3vw, 18px)" }}>
                <b>attachment (optional):</b>
              </h4>
              <input
                type="file"
                id="fileInput"
                style={{ margin: "10px 0", display: "none" }}
              />
              <label
                htmlFor="fileInput"
                className=""
                style={{ cursor: "pointer", color: "#120c34" }}
              >
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="fa fa-upload my-2 me-2"
                    style={{ fontSize: "1.5em" }}
                  ></i>
                  <h4 className="my-2"  style={{ fontSize: "clamp(16px, 3vw, 18px)" }}>click to upload</h4>
                </div>
              </label>
            </div>
          </div>

          <div className="row mt-5 pb-3">
            <div className="col-6 col-sm-6">
              <input
                type="button"
                onClick={(e) => prevStep(e)}
                className=" rounded-pill mx-3 bg-transparent text-white"
                style={{
                  border: "2px solid white",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  backgroundColor: "",
                  padding: "15px",
                  borderRadius: "25px",
                  color: "#120c34",
                }}
                value="back"
              />
            </div>
            <div className="col-6 col-sm-6">
              <button
                type="button"
                className="btn btn-lg form-button"
                onClick={(e) => nextStep(e)}
                style={{
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  marginLeft: "-32px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    // backgroundColor: '#3898ec',
                    border: "0",
                    borderRadius: "0",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  continue
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      {state.currentStep === 3 && (
        <div
          className="text-center"
          style={{
            background: "#120c34",
            width: "100%",
            borderRadius: "25px",
            paddingTop: '1rem'
          }}
        >
          <ul id="progressbar" className="d-flex justify-content-center p-0 flex-wrap" style={{gap: '1rem'}} >
            <li className="" id="step1" >
              <strong></strong>
            </li>
            <li className="" id="step2">
              <strong></strong>
            </li>
            <li className="active" id="step3">
              <strong></strong>
            </li>
            <li id="step4">
              <strong></strong>
            </li>
            <li id="step5">
              <strong></strong>
            </li>
          </ul>
          <h3 className="mt-5" style={{ fontWeight: "800" }}>
            <b>select deliverable</b>
          </h3>

          <div className="container-fluid mt-3">
            <div className="row  mt-3">
              <div
                className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                style={{ display: "flex", alignItems: "center" }}
              >
                <h2
                  className="my-3 form_market_font"
                  style={{ fontWeight: "600" }}
                >
                  in-feed photo post{" "}
                </h2>
              </div>

              <div className="col-6 col-sm-6">
                <div
                  className="d-flex align-items-center justify-content-center form_market_input"
                  style={{
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    padding: "20px",
                    background: "white",
                    color: "#120c34",
                  }}
                >
                  <i
                    className="fas fa-minus"
                    onClick={(e) => decrement("countInput", e)}
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <input
                    className="bg-transparent "
                    id="countInput"
                    type="text"
                    defaultValue="0"
                    readOnly
                    style={{
                      width: "50%",
                      textAlign: "center",
                      border: "0",
                      fontWeight: "600",
                    }}
                    name="countInput"
                    value={state.countInput}
                  />
                  <i
                    className="fas fa-plus"
                    onClick={(e) => increment("countInput", e)}
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="row  mt-3">
              <div
                className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                style={{ display: "flex", alignItems: "center" }}
              >
                <h2
                  className="my-3 form_market_font"
                  style={{ fontWeight: "600" }}
                >
                  15-30 second reel
                </h2>
              </div>

              <div className="col-6 col-sm-6">
                <div
                  className="d-flex align-items-center justify-content-center form_market_input"
                  style={{
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    padding: "20px",
                    background: "white",
                    color: "#120c34",
                  }}
                >
                  <i
                    className="fas fa-minus"
                    onClick={(e) => decrement("countInput1", e)}
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <input
                    className="bg-transparent "
                    id="countInput1"
                    type="text"
                    defaultValue="0"
                    readOnly
                    style={{
                      width: "50%",
                      textAlign: "center",
                      border: "0",
                      fontWeight: "600",
                    }}
                    name="countInput1"
                    value={state.countInput1}
                  />
                  <i
                    className="fas fa-plus"
                    onClick={(e) => increment("countInput1", e)}
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="row  mt-3">
              <div
                className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                style={{ display: "flex", alignItems: "center" }}
              >
                <h2
                  className="my-3 form_market_font"
                  style={{ fontWeight: "600" }}
                >
                  3-frame photo story
                </h2>
              </div>

              <div className="col-6 col-sm-6">
                <div
                  className="d-flex align-items-center justify-content-center form_market_input  "
                  style={{
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    padding: "20px",
                    background: "white",
                    color: "#120c34",
                  }}
                >
                  <i
                    className="fas fa-minus"
                    onClick={(e) => decrement("countInput2", e)}
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <input
                    className="bg-transparent "
                    id="countInput2"
                    type="text"
                    defaultValue="0"
                    readOnly
                    style={{
                      width: "50%",
                      textAlign: "center",
                      border: "0",
                      fontWeight: "600",
                    }}
                    name="countInput2"
                    value={state.countInput2}
                  />
                  <i
                    className="fas fa-plus"
                    onClick={(e) => increment("countInput2", e)}
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="row  mt-3">
              <div
                className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                style={{ display: "flex", alignItems: "center" }}
              >
                <h2
                  className="my-3 form_market_font"
                  style={{ fontWeight: "600" }}
                >
                  3-frame video story
                </h2>
              </div>

              <div className="col-6 col-sm-6">
                <div
                  className="d-flex align-items-center justify-content-center form_market_input"
                  style={{
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    padding: "20px",
                    background: "white",
                    color: "#120c34",
                  }}
                >
                  <i
                    className="fas fa-minus"
                    onClick={(e) => decrement("countInput3", e)}
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <input
                    className="bg-transparent"
                    id="countInput3"
                    type="text"
                    defaultValue="0"
                    readOnly
                    style={{
                      width: "50%",
                      textAlign: "center",
                      border: "0",
                      fontWeight: "600",
                    }}
                    name="countInput3"
                    value={state.countInput3}
                  />
                  <i
                    className="fas fa-plus"
                    onClick={(e) => increment("countInput3", e)}
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </div>
            </div>

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              <b>post frequency</b>
            </h3>

            <div className="row mt-3">
              <div className="col-5 col-sm-5">
                <div
                  className="dropdown show"
                  style={{
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    padding: "20px",
                    background: "white",
                    color: "#120c34",
                  }}
                >
                  <div className="card border-0 text-center p-0">
                    <select
                      className="custom-select"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleInputFormChange(
                          "postFrequencyCount",
                          e.target.value
                        )
                      }
                      value={state.postFrequencyCount}
                      style={{
                        border: 0,
                        background: "transparent",
                        fontWeight: "600",
                        color: "#120c34",
                        textAlignLast: "center",
                        WebkitAppearance: "none",
                        MozAppearance: "none",
                        appearance: "none",
                      }}
                      aria-label="Post selection"
                    >
                      <option value={1} selected>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                    </select>
                    <div
                      className="dropdown-toggle"
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "12px",
                        pointerEvents: "none",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div
                className="col-2 col-sm-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                <h2
                  className="my-3 form_market_font"
                  style={{ fontWeight: "600" }}
                >
                  per{" "}
                </h2>
              </div>
              <div className="col-5 col-sm-5">
                <div
                  className="dropdown show"
                  style={{
                    borderRadius: "25px",
                    border: "1px solid #ccc",
                    padding: "20px",
                    background: "white",
                    color: "#120c34",
                  }}
                >
                  <div className="card border-0 text-center p-0">
                    <select
                      className="custom-select"
                      onClick={(e) => e.stopPropagation()}
                      value={state.postFrequencyPer}
                      onChange={(e) =>
                        handleInputFormChange(
                          "postFrequencyPer",
                          e.target.value
                        )
                      }
                      style={{
                        border: 0,
                        background: "transparent",
                        fontWeight: "600",
                        color: "#120c34",
                        textAlignLast: "center",
                        WebkitAppearance: "none",
                        MozAppearance: "none",
                        appearance: "none",
                      }}
                      aria-label="Post selection"
                    >
                      <option value="day" selected>
                        day
                      </option>
                      <option value="week">week</option>
                      <option value="month">month</option>
                    </select>
                    <div
                      className="dropdown-toggle"
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "12px",
                        pointerEvents: "none",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-3 pb-3"  style={{marginTop: '1rem'}}>
              <div className="col-6 col-sm-6">
                <input
                  type="button"
                  onClick={(e) => prevStep(e)}
                  className=" rounded-pill mx-3 bg-transparent text-white"
                  style={{
                    border: "2px solid white",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    backgroundColor: "",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  value="back"
                />
              </div>
              <div className="col-6 col-sm-6">
                <button
                  type="button"
                  className="btn btn-lg form-button"
                  onClick={(e) => nextStep(e)}
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    continue
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.currentStep === 4 && (
        <div
          className="text-center"
          style={{
            background: "#120c34",
            width: "100%",
            borderRadius: "25px",
            padding: "20px",
          }}
        >
          <ul id="progressbar" className="d-flex justify-content-center p-0 flex-wrap" style={{gap: '1rem'}} >
            <li className="" id="step1" >
              <strong></strong>
            </li>
            <li className="" id="step2">
              <strong></strong>
            </li>
            <li className="" id="step3">
              <strong></strong>
            </li>
            <li className="active" id="step4">
              <strong></strong>
            </li>
            <li id="step5">
              <strong></strong>
            </li>
          </ul>
          <h3 className="mt-5 fs-3 fs-md-4" style={{ fontWeight: "800" }}>
            <b>make an offer</b>
          </h3>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "x-large",
                    color: '#000'
                  }}
                >
                  $
                </span>
                <input
                  type="number"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleInputFormChange("offerAmount", e.target.value)
                  }
                  style={{
                    border: "#120c34 2px solid",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    paddingLeft: "30px",
                  }}
                  placeholder="2,000"
                  className="input-box rounded-pill p-2 text-center marketplace_form_fields"
                  name="offerAmount"
                  value={state.offerAmount}
                />
              </div>
          <Errors mid={true} msg={state?.errors?.offerAmount} />

          <h3 className="mt-3 fs-3 fs-md-4" style={{ fontWeight: "800" }}>
            product included
          </h3>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <input
                type="button"
                className=" rounded-pill mx-3 selected marketplace_form_fields"
                onClick={(e) =>
                  handleInputFormChange1("productIncluded", e.target.value, e)
                }
                style={{
                  fontWeight: "800",
                  fontSize: "x-large",
                  padding: "15px",
                  borderRadius: "25px",
                  color: state.productIncluded === "yes" ? "#120c34" : "white",
                  border: "2px solid white",
                  backgroundColor:
                    state.productIncluded === "yes" ? "white" : "transparent",
                }}
                value="yes"
              />
            </div>
            <div className="col-12 col-md-6">
              <input
                type="button"
                className=" rounded-pill mx-3 marketplace_form_fields"
                onClick={(e) =>
                  handleInputFormChange1("productIncluded", e.target.value, e)
                }
                style={{
                  fontWeight: "800",
                  fontSize: "x-large",
                  padding: "15px",
                  borderRadius: "25px",
                  color: state.productIncluded === "no" ? "#120c34" : "white",
                  border: "2px solid white",
                  backgroundColor:
                    state.productIncluded === "no" ? "white" : "transparent",
                }}
                value="no"
              />
            </div>
          </div>
          <Errors mid={true} msg={state?.errors?.productIncluded} />

          <h3 className="mt-3 fs-3 fs-md-4" style={{ fontWeight: "800" }}>
            product url
          </h3>
          <div className="card mx-3 mt-2 px-2" style={{ borderRadius: "25px" }}>
    <div className="row align-items-center">
      <div className="col-12">
                {/* <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4> */}
                <input
                  className="border  border-0 form-control"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleInputFormChange("productURL", e.target.value)
                  }
                  style={{
                    fontWeight: "800",
                    fontSize: "x-large",
                    border: "0",
                    borderRadius: "25px",
                  }}
                  name="productURL"
                  value={state.productURL}
                  type="text"
                  placeholder="yourwebiste.com"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
          <Errors mid={true} msg={state?.errors?.productURL} />

          <h3 className="mt-3 fs-3 fs-md-4" style={{ fontWeight: "800" }}>
    <b>product value</b>
  </h3>
          <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "x-large",
                    color: '#000'
                  }}
                >
                  $
                </span>
                <input
                  type="number"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleInputFormChange("productValue", e.target.value)
                  }
                  style={{
                    border: "#120c34 2px solid",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    paddingLeft: "30px",
                  }}
                  placeholder="0"
                  className="input-box rounded-pill p-2 text-center marketplace_form_fields"
                  name="productValue"
                  value={state.productValue}
                />
              </div>
          <Errors mid={true} msg={state?.errors?.productValue} />

          <div className="container">
            <div className="row mt-5 pb-3">
              <div className="col-6 col-sm-6">
                <input
                  type="button"
                  onClick={(e) => prevStep(e)}
                  className=" rounded-pill mx-3 bg-transparent text-white"
                  style={{
                    fontWeight: "800",
                    fontSize: "x-large",
                    border: "2px solid white",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    backgroundColor: "",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  defaultValue="back"
                />
              </div>
              <div className="col-6 col-sm-6">
                <button
                  type="button"
                  className="btn btn-lg form-button"
                  onClick={(e) => nextStep(e)}
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                      fontWeight: "800",
                      fontSize: "x-large",
                    }}
                  >
                    continue
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.currentStep === 5 && (
        <div
          className="text-center"
          style={{
            background: "#120c34",
            width: "100%",
            borderRadius: "25px",
            paddingTop: '1rem'
          }}
        >
         <ul id="progressbar" className="d-flex justify-content-center p-0 flex-wrap" style={{gap: '1rem'}} >
            <li className="" id="step1" >
              <strong></strong>
            </li>
            <li className="" id="step2">
              <strong></strong>
            </li>
            <li className="" id="step3">
              <strong></strong>
            </li>
            <li id="step4">
              <strong></strong>
            </li>
            <li className="active" id="step5">
              <strong></strong>
            </li>
          </ul>

          <h3 className="mt-3" style={{ fontWeight: "800" }}>
            <b>select deliverable</b>
          </h3>

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="row text-center">
                  <div className="col-md-12 mb-1 text-center">
                    <h5 className="text-center" style={{ fontWeight: "800" }}>
                      <b>request details</b>
                    </h5>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        Start Date : {state.startDate}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        {state.requestType}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        platform : {state.Platform}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 text-center">
                    <h5 className="text-center" style={{ fontWeight: "800" }}>
                      <b>deliverables</b>
                    </h5>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        {state.countInput} in-feed photo post
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        {state.countInput1} 15-30 second reel
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        {state.countInput2} frame photo story
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        {state.countInput3} frame video story
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        {state.postFrequencyCount} per week
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-md-12 mb-1 text-center">
                  <h5 className="text-center" style={{ fontWeight: "800" }}>
                    <b>Instructions</b>
                  </h5>
                </div>

                <div className="col-md-12 mb-1">
                  <div
                    className="card align-items-center "
                    style={{ borderRadius: "25px" }}
                  >
                    <textarea
                      style={{
                        textAlign: "center",
                        borderRadius: "25px",
                        fontSize: "large", // Note: Adjusted to use a string value instead of "large"
                        border: "none", // Adjusted to use 'none' instead of "0"
                        fontWeight: "800",
                        width: "100%",
                        color: "#120c34", // Applied to the textarea itself
                        resize: "none", // Prevents the user from resizing the textarea
                        overflow: "hidden", // Ensures the text does not overflow the textarea
                        height: "150px", // Equivalent to 5 rows of text (assuming an average line height)
                      }}
                      rows={5}
                      disabled
                    >
                      {state.requestInstructionsText}
                    </textarea>
                  </div>
                </div>
                <div className="col-md-12 mb-1">
                  <div
                    className="card align-items-center "
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="d-flex align-items-center">
                      <h5 className="my-2" style={{ color: "#120c34" }}>
                        <b>url:</b>
                      </h5>
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        {state.WebsiteUrl}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-1">
                  <div
                    className="card align-items-center "
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      className="d-flex align-items-center gap-3"
                      style={{ gap: "8px" }}
                    >
                      <i
                        className="fa fa-upload my-2 me-2"
                        style={{ fontSize: "1.5em", color: "#120c34" }}
                      ></i>
                      <h4 className="my-2" style={{ color: "#120c34" }}>
                        photo.jpeg
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-1 text-center">
                  <h5 className="text-center" style={{ fontWeight: "800" }}>
                    <b>Offer</b>
                  </h5>
                </div>

                <div className="col-md-12 mb-1">
                  <div
                    className="card align-items-center "
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="d-flex align-items-center">
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        $ {state.offerAmount}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-1">
                  <div
                    className="card align-items-center "
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="d-flex align-items-center">
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        Product URL : {state.productURL}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-1">
                  <div
                    className="card align-items-center "
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="d-flex align-items-center">
                      <span
                        className="py-2"
                        style={{
                          textAlign: "center",
                          fontSize: "large",
                          border: "0",
                          fontWeight: "800",
                          color: "#120c34",
                        }}
                      >
                        Product Value $ {state.productValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-3 pb-3">
              <div className="col-6 col-sm-6">
                <input
                  type="button"
                  onClick={(e) => prevStep(e)}
                  className=" rounded-pill mx-3 bg-transparent text-white"
                  style={{
                    fontWeight: "800",
                    fontSize: "x-large",
                    border: "2px solid white",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    backgroundColor: "",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  defaultValue="back"
                />
              </div>
              <div className="col-6 col-sm-6">
                <button
                  type="submit"
                  className="btn btn-lg form-button"
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                      fontWeight: "800",
                      fontSize: "x-large",
                    }}
                  >
                    Submit
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default EditMarketplaceRequest;
