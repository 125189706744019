import { Icon } from '@iconify-icon/react/dist/iconify.mjs';
import React, { useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { toast } from 'react-toastify';

const TrendingCampaignDetails = () => {
  const { campaignId } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [approvalDetails, setApprovalDetails] = useState(null);

    const [checkApprovalModal, setCheckApproval] = useState(false)
  
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const onApprovalModalOpen = () => setCheckApproval(true);
    const onApprovalModalClose = () => setCheckApproval(false);


    const fetchCampaignApprovals = async () => {
      const token = localStorage.getItem("accessToken");
      const headers = new Headers({
        'Authorization': `Bearer ${token}`
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/${data?.id}`, {
          method: 'GET',
          headers: headers
        }); 

        const result = await response.json();

        if (response.ok && result.status === 'SUCCESS') {
          setApprovalDetails(result.data);
        } else if (result.code === 'token_not_valid') {
          window.localStorage.clear();
          console.error('Token is invalid or expired');
          window.location.href = '/signin'; // Automatically redirect to /signin
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
      //   setError(error.message);
        console.error(error.message);
      }
    }

    useEffect(()=> {
      if(data?.id) {
        fetchCampaignApprovals();
      }
    }, [data])

    const fetchCampaignData = async () => {
        const token = localStorage.getItem("accessToken");
        const headers = new Headers({
          'Authorization': `Bearer ${token}`
        });
  
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/ambassador/${campaignId}`, {
            method: 'GET',
            headers: headers
          });

          const result = await response.json();
  
          if (response.ok && result.status === 'SUCCESS') {
            if (!result?.data?.id) {
              navigate('/home')
            }
            setData(result.data);
          } else if (result.code === 'token_not_valid') {
            window.localStorage.clear();
            console.error('Token is invalid or expired');
            window.location.href = '/signin'; // Automatically redirect to /signin
          } else {
            throw new Error('Failed to fetch data');
          }
        } catch (error) {
        //   setError(error.message);
          console.error(error.message);
        }
    }

    useEffect(()=> {
        if (campaignId) {
            fetchCampaignData();
        }
    }, [])

  return (
    <div className="container-fluid mt-3 px-md-5">
        <div 
        onClick={() => navigate('/home')}
        style={{fontSize: '20px', display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer'}}>
            <Icon icon="ic:sharp-arrow-back" width="20" height="20" />
            {" "}back
        </div>
        
        <div  style={{maxWidth: '768px', margin: 'auto', display: 'flex', flexDirection: 'column', justifyItems: 'center', gap: '0.5rem'}}>
        <div style={{margin: 'auto'}}>
            <div style={{ height: '140px', width: '140px', borderRadius: '50%', border: '4px solid #000', padding: '2px', cursor: 'pointer' }}>
                <img 
                src={data?.brand?.images[0]} 
                height={'100%'} 
                width={'100%'} 
                alt='campaign' 
                style={{ borderRadius: '50%', objectFit: 'cover' }}
                />
            </div>
        </div>
        <div style={{fontSize: 'x-large', fontWeight: 'bold', textAlign: 'center'}}>{data?.brand?.name}</div>
        <div style={{fontSize: 'x-large', fontWeight: 'bold', textAlign: 'center'}}>{data?.details?.url}</div>

        {
          approvalDetails?.masked_url ? 
        // <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
        //     <button 
        //     className="btn btn-lg btn-custom"
        //     >
        //     <span>join campaign</span>
        //     </button>
            <button 
            className="btn btn-lg btn-custom"
            style={{width: 'fit-content', margin:'auto'}}
            onClick={onApprovalModalOpen}
            >
            <span>check approval</span>
            </button>
        :
        <button 
          className="btn btn-lg btn-custom"
          style={{width: 'fit-content', margin:'auto'}}
          onClick={onOpenModal}
        >
          <span>join campaign</span>
        </button>
        }

        <div style={{fontSize: 'x-large',
          border: '1px solid #f1f1f1',
          padding: '8px 16px',
          borderRadius: '8px',
        }}>
          {
            data?.brand?.description
          }
        </div>

        <div style={{display: 'flex', flexWrap: 'wrap', gap: '0.5rem', fontSize: 'large'}}>
          {
            data?.brand?.interests?.map((value, i )=> {
              return(
                <span key={i} className="badge rounded-pill" style={{border: '1px solid #000'}}>{value}</span>
              )
            })
          }
        </div>
        </div>

        <div className="card border-0 text-white py-3 px-2 px-md-4" style={{ background: "#120c34", borderRadius: "25px", maxWidth: '768px', margin: 'auto', marginBottom: '1rem', marginTop: '1rem' }}>
            <div className='pb-3' style={{fontSize: 'x-large', textAlign: 'center'}}>ambassador program</div>

            <div>
                <div className='pb-3' style={{
                    display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.4rem'
                }}>
                    <div>
                        <div style={{fontSize: 'large', textAlign: 'center'}}>payment per click</div>
                        <Badge pill bg="light" style={{color: '#000', fontSize: 'large', padding: '16px 0px', width: '100%', borderRadius: 'calc(infinity * 1px)'}}>
                            $ {data?.details?.costPerClick || ' - '} / click
                        </Badge>
                    </div>

                    <div>
                        <div style={{fontSize: 'large', textAlign: 'center'}}>payment per post</div>
                        <Badge pill bg="light" style={{color: '#000', fontSize: 'large', padding: '16px 0px', width: '100%', borderRadius: 'calc(infinity * 1px)'}}>
                            $ {data?.details?.costPerCost || ' - '} / click
                        </Badge>
                    </div>

                </div>
                {/* <div className='pb-3'>
            <div style={{fontSize: 'large', textAlign: 'center'}}>url</div>
            <Badge pill bg="light" style={{color: '#000', fontSize: 'large', padding: '16px 0px', width: '100%', borderRadius: 'calc(infinity * 1px)'}}>
                            $0.65/click
                        </Badge>
                </div> */}
            <div style={{fontSize: 'large', textAlign: 'center'}}>monthly budget</div>
            <Badge pill bg="light" style={{color: '#000', fontSize: 'large', padding: '16px 0px', width: '100%', borderRadius: 'calc(infinity * 1px)'}}>
                            $ {Number(data?.budget).toFixed(2)}
                        </Badge>
            </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4rem'}}>
        <button 
          className="btn btn-lg btn-custom"
          style={{width: 'fit-content', margin:'auto'}}
          onClick={onOpenModal}
        >
          <span>submit content for approval</span>
        </button>

        </div>


        <JoinCampaignModal 
          open={open}
          onCloseModal={onCloseModal}
          campaignData={data}
          data={approvalDetails}
        />

        <ApprovalModal
          open={checkApprovalModal}
          onCloseModal={onApprovalModalClose}
          data={approvalDetails}
        />
    </div>
  )
}

export default TrendingCampaignDetails


const JoinCampaignModal = ({open, onCloseModal, campaignData, data}) => {
  const [fileCount, setFileCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (files) => {
    setSelectedFiles(files);
    setFileCount(files.length);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const truncateFileName = (fileName) => {
    const words = fileName.split(" ");

    if (words.length > 5) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return fileName;
  };

  const uploadFileToAzure = async (file, sasUrl) => {
    try {
      const response = await fetch(sasUrl, {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": file.type || "application/octet-stream",
        },
        body: file,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file.");
      }
      // console.log('File uploaded successfully to:', sasUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Rethrow to handle in the calling function
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const token = localStorage.getItem("accessToken");
    const campaignId = campaignData?.id;

      if (!token) {
        console.error("No access token found");
        return;
      }

    if (selectedFiles.length > 0) {
      // Create an object to count files by extension
      const fileCountByExtension = selectedFiles.reduce((acc, file) => {
        const ext = file.name.split(".").pop();
        acc[ext] = (acc[ext] || 0) + 1;
        return acc;
      }, {});

      // Convert the object into the required format for the API
      const uploadUrlBody = {
        count: Object.entries(fileCountByExtension).map(
          ([extension, count]) => ({
            extension,
            count,
          })
        ),
      };

      const uploadUrlOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(uploadUrlBody),
      };

      try {
        const uploadUrlResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/general/v1/upload-url`,
          uploadUrlOptions
        );
        const uploadUrlData = await uploadUrlResponse.json();

        if (!uploadUrlResponse.ok) {
          throw new Error(
            `Failed to obtain upload URLs: ${
              uploadUrlData.error || "Unknown Error"
            }`
          );
        }

        // console.log('Upload URLs:', uploadUrlData);
        if (uploadUrlData.status === "SUCCESS") {
          // Upload each file to the provided URL
          const attachments = uploadUrlData.data.map((item) => item.key);
          for (let i = 0; i < selectedFiles.length; i++) {
            await uploadFileToAzure(
              selectedFiles[i],
              uploadUrlData.data[i].url
            );
          }

          const campaignBody = {
            campaign_id: campaignId,
            caption: '',
            uploads: attachments,
          };

          const campaignOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(campaignBody),
          };

          const campaignResponse = await fetch(
            `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content`,
            campaignOptions
          );
          const campaignData = await campaignResponse.json();

          if (!campaignResponse.ok) {
            throw new Error(
              `Failed to submit campaign content: ${
                campaignData.error || "Unknown Error"
              }`
            );
          }
          toast.success("Campaign submitted successfully!");
          setSelectedFiles([]);
          window.location.reload()
        }
      } catch (error) {
        console.error(
          "Error fetching upload URLs or uploading files:",
          error
        );
        setIsLoading(false); // Stop the loading state on error
        return; // Stop the submission if file upload URLs cannot be obtained
      }
    }
    setIsLoading(false); // Stop the loading state on success
  };


  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      closeOnOverlayClick={true}
      center
      showCloseIcon={false}
      style={{
        background: 'grey'
      }}
    >
      <Icon
        onClick={onCloseModal}
        style={{position: 'absolute', top: '12px', right: '12px', cursor: 'pointer'}}
        icon="ic:twotone-close"
        width="32"
        height="32"
      />
      <div style={{marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', textAlign: 'center'}}>
      <div style={{fontSize: 'x-large', fontWeight: 'bold', textAlign: 'center'}}>ambassador program details</div>
      <div style={{fontSize: 'large', fontWeight: 'bold', textAlign: 'center'}}>get paid to create content and drive traffic for the brands you love!</div>
      <Badge pill bg="light" style={{color: '#000', fontSize: 'large', padding: '16px 24px', width: 'fit-content', borderRadius: 'calc(infinity * 1px)'}}>
          $ {campaignData?.balance} remaining
      </Badge>

      <div style={{cursor: 'pointer', borderRadius: '16px', padding: '12px', background: '#fff', color: '#000'}} 
      onClick={() => {
          document.getElementById('fileUpload').click();
      }}
      >
          <Icon icon="teenyicons:upload-solid" width="35" height="35" />
          <div>tap to upload</div>
          <input 
              type="file" 
              multiple
              id="fileUpload"
              style={{ display: 'none' }}
              onChange={(e) => {
                  const files = Array.from(e.target.files);
                  handleFileChange(files)
              }}
          />
      </div>
      {
          selectedFiles && <div>{selectedFiles?.length} Files Selected</div>
      }
      <div style={{fontSize: 'large', fontWeight: 'bold', textAlign: 'center'}}>Tap to upload your content here and get paid once its approved!</div>
      <Badge pill bg="light" style={{color: '#000', fontSize: 'large', padding: '8px 18px', width: 'fit-content', borderRadius: 'calc(infinity * 1px)', cursor: 'pointer'}}
      onClick={() => {
        navigator.clipboard.writeText(data?.masked_url);
        alert('Copied to Clipbord.')
      }}
      >
          {data?.masked_url} <Icon  icon="uil:copy" width="20" height="16" />
      </Badge>
      <div style={{fontSize: 'large', fontWeight: 'bold', textAlign: 'center'}}>drive traffic with this link, you get paid for every unique click you generate!</div>
      <button 
        className="btn btn-lg btn-custom"
        style={{minWidth: '120px'}}
        onClick={handleSubmit}
        disabled={selectedFiles?.length === 0}
      >
        <span>{data?.masked_url ? 'submit' : 'join'}</span>
      </button>

      </div>
    </Modal>
  )
}

const ApprovalModal = ({open, onCloseModal, data}) => {
  return(
    <Modal
      open={open}
      onClose={onCloseModal}
      closeOnOverlayClick={true}
      center
      showCloseIcon={false}
      style={{
        background: 'grey'
      }}
    >
      <Icon
        onClick={onCloseModal}
        style={{position: 'absolute', top: '12px', right: '12px', cursor: 'pointer'}}
        icon="ic:twotone-close"
        width="32"
        height="32"
      />
      <div style={{marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', textAlign: 'center'}}>
        <table
          className="table mb-0"
          style={{ background: "#120c34", color: "white" }}
        >
          <colgroup>
            <col className="image-col" />
            <col className="status-col" />
            <col className="input-col" />
          </colgroup>
          <thead className="text-center">
            <tr>
              <th scope="col">Content</th>
              <th scope="col">Status</th>
              <th scope="col">Upload URL</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {data?.results?.map((item) => (
              <tr key={item.id}>
                <td data-label="Image">
                  <a
                    href={item.image}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      width="50px"
                      style={{ height: "50px", aspectRatio: '1/1', objectFit: 'scale-down' }}
                      src={item.image}
                      alt="House"
                      className="img-fluid"
                    />
                  </a>
                </td>
                <td data-label="Status" >
                  <div  className="d-flex justify-content-center align-items-center" style={{height: '50px'}}>
                  {item.status === "Rejected" ? (
                    <span className="badge badge-danger">{item.status}</span>
                  ) : item.status === "Approved" ? (
                    <span className="badge badge-success">{item.status}</span>
                  ) : (
                    <span className="badge badge-dark">{item.status}</span>
                  )}
                  </div>
                </td>
                <td data-label="Enter Input" >
                  <div className="input-group" style={{width: '90%', margin: 'auto', marginTop: '0.25rem'}}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Upload your url"
                      // value={responses[item.id] || ""}
                      // onChange={(e) =>
                      //   handleInputChange(item.id, e.target.value)
                      // }
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-success"
                        // onClick={() => handleSaveClick(item.id)}
                      >
                        ✔
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </Modal>
  )
}