/* eslint-disable no-dupe-class-members */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
/* eslint-disable react/jsx-pascal-case */
import axios from "axios";
import React, { Component, useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import ModalComponent from "../../CommonComponents/ModalComponent";
import InterestsModal from "./InterestsModal";
import Animation_Custom from "../AnimationCustom/Animation_Custom";
import { toast } from "react-toastify";
import Errors from "../../CommonComponents/Errors";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import '../dashboard_main.css'
// import Apiloading_animation from '../Animation/Apiloading_animation';

export default class Favorites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      countries: [],
      loading: true,
      min_age: "",
      max_age: "",
      gender: "",
      followers_min: "",
      followers_max: "",
      interests: [],
      // Upper variables arre for filter
      isLoading: false,

      selected_creators: 0,
      isActive: false,
      showForm: false,
      currentStep: 1,
      page: 1,
      next: null,
      userID: "",
      startDate: "",
      requestType: "",
      Platform: "",
      requestInstructionsText: "",
      WebsiteUrl: "",
      file: null,
      countInput: 0, // in-feed photo post
      countInput1: 0, // 15-30 second reel
      countInput2: 0, // 3-frame photo story
      countInput3: 0, // 3-frame video story
      postFrequencyCount: 1, // post frequency select option
      postFrequencyPer: "week", // post frequency select option
      offerAmount: "",
      productIncluded: "",
      productURL: "",
      productValue: "",
      count: 0,
      interestOptions: [],
      shouldNavigate: false,
    };
  }

  handleShow = () => this.setState({ showModal: true });
  handleClose = () => this.setState({ showModal: false });

  fetchCountries = async () => {
    try {
      // console.log("Base URL:", process.env.REACT_APP_BASE_URL);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/general/v1/state`
      );
      const json = await response.json();
      if (json.status === "SUCCESS") {
        this.setState({ countries: json.data, loading: false });
        // console.log("countries: ", this.state.countries)
      } else {
        this.setState({ error: "Failed to fetch countries", loading: false });
      }
    } catch (err) {
      this.setState({ error: err.message, loading: false });
    }
  };

  //fetch interests
  fetchInterests = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/general/v1/interest`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          interestOptions: response.data.data, // Assuming data is in `data` field
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: "Failed to fetch data",
          loading: false,
        });
        console.error(error);
      });
  };

  renderCountriesOptions = () => {
    return this.state.countries.map((country) => (
      <option key={country.id} value={country.id}>
        {country.name} - {country.subcountry}
      </option>
    ));
  };

  render() {
    const { error, loading, countries } = this.state;
    const { selectedCountry, onCountryChange } = this.props;

    if (error) {
      return <div>Error: {error}</div>;
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <select
        className="custom-select"
        style={{ borderRadius: "25px" }}
        value={selectedCountry}
        onChange={onCountryChange}
      >
        {countries.map((country) => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
      </select>
    );
  }

  handleExpInput = (event) => {
    let input = event.target.value.replace(/[^\d]/g, ""); // Remove non-digits

    // Automatically add slashes
    if (input.length > 2 && input.length <= 4) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }
    if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    // Validate the input before returning
    if (/^\d{0,2}\/?\d{0,2}\/?\d{0,4}$/.test(input)) {
      return input; // Return the formatted date
    }
    return event.target.value; // Return original input if it doesn't match the format
  };

  onDate = (event) => {
    // this.handleExpInput(event); // Formats the date
    // this.handleInputFormChange('startDate', event.target.value, event); // Handles additional logic
    const formattedDate = this.handleExpInput(event); // Get the formatted date
    this.handleInputFormChange("startDate", formattedDate);
  };

  convertDate = (inputDate) => {
    // Parse the date from MM/DD/YYYY format
    const dateParts = inputDate.split("/");
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1; // Adjust month from 1-12 to 0-11 for JS Date
    const day = parseInt(dateParts[1], 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Format the date as YYYY-MM-DDT00:00:00.000000Z
    const isoString = date.toISOString(); // Returns YYYY-MM-DDTHH:MM:SS.sssZ
    const startDatetime = isoString.split("T")[0] + "T00:00:00.000000Z"; // Restrict time to 00:00:00.000000Z

    // Example to show how to use it in state, if needed
    // this.setState({ start_datetime: startDatetime });

    return startDatetime;
  };

  truncateAddress(address, wordLimit) {
    const parts = address.split(",");
    if (parts.length === 2) {
      return parts[1].trim();
    }
    const truncatedParts = parts.slice(-2);
    let result = truncatedParts.join(", ").trim();

    result = result.replace(/\b\d{5}(?:-\d{4})?\b/g, "").trim();

    return result;
  }

  isLogedin = () => {
    return window.localStorage.getItem("isLogedin"); // Convert string to boolean
  };

  // Detect when user reaches the bottom of the page
  handleScroll = () => {
    const { isLoading, next } = this.state;

    // Calculate the scroll position
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;

    // Check if the user has reached the bottom of the page
    const bottom = scrollPosition >= scrollHeight - 5; // Allow a small tolerance (5px)

    // If we're at the bottom, not loading, and there's a next page to load
    if (bottom && !isLoading && next) {
      this.setState(
        (prevState) => ({ page: prevState.page + 1 }),
        () => this.fetchData() // Fetch data after page state update
      );
    }
  };

  // Cleanup the scroll event listener when the component unmounts
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.fetchData(); // Fetch initial data on mount
    window.addEventListener("scroll", this.handleScroll); // Add scroll event listener
    this.fetchCountries();
    this.fetchInterests();
  }

  fetchData = async (reset = false) => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("accessToken");
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
    });

    let { min_age, max_age, gender, followers_min, followers_max, interests } =
      this.state;
    if (gender === "Male") {
      gender = "1";
    } else if (gender === "Female") {
      gender = "2";
    }

    const queryParams = new URLSearchParams();
    if (min_age) queryParams.append("min_age", min_age);
    if (max_age) queryParams.append("max_age", max_age);
    if (gender) queryParams.append("gender", gender);
    if (followers_min) queryParams.append("followers_min", followers_min);
    if (followers_max) queryParams.append("followers_max", followers_max);
    if (interests?.length) queryParams.append("interests", interests);

    if (reset) this.setState({ page: 1 });

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }api/general/v1/marketplace?is_favorite=true&${queryParams.toString()}&page=${
          reset ? 1 : this.state.page
        }&page_size=9`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const result = await response.json();

      if (response.ok && result.status === "SUCCESS") {
        if (reset) {
          this.setState({
            data: result.data.results,
          });
        } else {
          this.setState((prevState) => {
            // Check if the last item of the current content is the same as the last item of the new data
            const lastCurrentItem = prevState.data[prevState.data.length - 1];
            const lastNewItem =
              result.data.results[result.data.results.length - 1];

            // Compare the last items by relevant properties (e.g., id, title, body)
            const isSame =
              lastCurrentItem?.id === lastNewItem?.id &&
              lastCurrentItem?.title === lastNewItem?.title &&
              lastCurrentItem?.body === lastNewItem?.body;
            // If the last items are not the same, append the new data
            if (!isSame) {
              return {
                data: [...prevState.data, ...result.data.results],
              };
            }

            // Return the previous state if the last items are the same
            return prevState;
          });
        }
        this.setState({
          isLoading: false,
          next: result.data.next,
        });

        // this.setState((prevState) => ({
        //   data:
        //     page === 1
        //       ? result.data.results
        //       : [...prevState.data, ...result.data.results], // Only append if not page 1
        //   isLoading: false,
        //   // Do not increment page here
        // }));
      } else {
        if (result.code === "token_not_valid") {
          window.localStorage.removeItem("refreshToken");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("userName");
          window.localStorage.removeItem("isLogedin");
          console.error("Token is invalid or expired");
          window.location.href = "/signin";
        } else {
          throw new Error("Failed to fetch data");
        }
      }
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
      console.error(error.message);
    }
    this.setState({
      isLoading: false,
    });
  };

  loadMore = () => {
    const nextPage = this.state.page + 1; // Calculate the next page
    this.fetchData(); // Fetch data for the next page
    this.setState({ page: nextPage }); // Then increment the page state
  };

  formatNumber(number) {
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(1) + "K";
    } else {
      return number.toString();
    }
  }

  handleDateSelect = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    this.setState({ startDate: formattedDate });
  };

  handleDateChange = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    this.setState({ startDate: date });
    this.handleInputFormChange("startDate", formattedDate);
  };

  // Handle checkbox change
  handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    this.setState(
      (prevState) => {
        const interests = checked
          ? [...prevState.interests, value] // Add value if checked
          : prevState.interests.filter((id) => id?.toString() !== value); // Remove value if unchecked

        return { interests };
      },
      () => {
        // console.log(`Updated ${field}:`, this.state[field]);
        this.fetchData(true);
        // console.log(this.filterData())
      }
    );
  };

  resetModal = () => {
    this.setState({
      currentStep: 1,
      showForm: false,
      startDate: "",
      requestType: "",
      Platform: "",
      requestInstructionsText: "",
      WebsiteUrl: "",
      file: null,
      countInput: 0,
      countInput1: 0,
      countInput2: 0,
      countInput3: 0,
      postFrequencyCount: 1,
      postFrequencyPer: "week",
      offerAmount: "",
      productIncluded: "",
      productURL: "",
      productValue: "",
    });
  };

  renderCards() {
    const { data } = this.state;
    return data.map((user) => (
      <div
      className=" col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3"
        key={user.id}
        data-toggle="modal"
        data-target={`#modal-${user.id}`}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          // Close modal when clicked outside
          if (e.target.classList.contains("modal")) {
            this.resetModal();
          }
        }}
      >
        <div
          className="card border-0"
          style={{
            background: "#120c34",
            color: "white",
            borderRadius: "25px",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              className="text-center"
              style={{
                position: "relative",
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "25px",
                overflow: "hidden",
                width: "100%",
                paddingBottom: "100%",
              }}
            >
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${
                    user.images ? user.images[0] : "/images/person.png"
                  })`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "25px",
                  filter: "blur(40px)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              ></div>
              <div
                className="text-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  className="original-image"
                  src={user.images ? user.images[0] : "/images/person.png"}
                  alt="User"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    // width:"100%",
                    position: "absolute",
                    top: 0,
                    // left: 0,
                    zIndex: 2,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>
              {user.favorite ? (
                <a
                  className="bg-transparent border-0"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onClickSelectedCreators(event, user.id);
                    this.setState((prevState) => ({
                      data: prevState.data.map((item) =>
                        item.id === user.id
                          ? { ...item, favorite: !item.favorite }
                          : item
                      ),
                    }));
                  }}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    cursor: "pointer",
                    width: "35px",
                    height: "35px",
                    zIndex: 3,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="red"
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                  >
                    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                  </svg>
                </a>
              ) : (
                <a
                  className="bg-transparent border-0"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onClickSelectedCreators(event, user.id);
                    this.setState((prevState) => ({
                      data: prevState.data.map((item) =>
                        item.id === user.id
                          ? { ...item, favorite: !item.favorite }
                          : item
                      ),
                    }));
                  }}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    cursor: "pointer",
                    width: "35px",
                    height: "35px",
                    zIndex: 3,
                  }}
                >
                  <i
                    className="fa-regular fa-heart fa-3x"
                    style={{ color: "white" }}
                  ></i>
                </a>
              )}
            </div>
          </div>
          <div className="card-body1  px-3 pt-2">
            <span
              style={{ fontSize: "20px", fontWeight: 800 }}
              className="card-text alignleft font-type"
            >
              {user.name}
            </span>
            {user.location && (
              <small className="card-text alignright">
                {user.location
                  ? this.truncateAddress(user.location, 5)
                  : "No location"}{" "}
                <i className="fa-solid fa-location-dot"></i>
              </small>
            )}
          </div>
          <div className="card-body2 px-3 d-grid">
            {user.social &&
              user.social.instagram &&
              user.social.instagram !== 0 && (
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "2px" }}
                >
                  <a href="#" style={{ textDecoration: "none" }}>
                    <img
                      src="/images/instagram.png"
                      alt=""
                      style={{ width: "24px", height: "24px" }}
                    />
                  </a>
                  <span className="social_font">
                    {this.formatNumber(user.social.instagram)}
                  </span>
                </div>
              )}
            <br />
            {user.social && user.social.tiktok && user.social.tiktok !== 0 && (
              <div className="d-flex align-items-center">
                <a href="#" style={{ textDecoration: "none" }}>
                  <img
                    src="/images/tiktok.png"
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
                <span className="social_font">
                  {this.formatNumber(user.social.tiktok)}
                </span>
              </div>
            )}
            <br />
          </div>
          <div className="d-grid px-3 mb-2">
            {/* Additional buttons or information */}
            {user.interests?.length > 0 &&
              user.interests?.slice(0, 4)?.map((item, index) => (
                <button key={index} className="small-rounded-button mr-1 px-1">
                  <small>{item}</small>
                </button>
              ))}
            <small>
              {user.interests?.length > 4 &&
                `+${user.interests?.length - 4} more`}
            </small>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg p-0"
          id={`modal-${user.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          style={{ borderRadius: "25px" }}
        >
          <div
            className="modal-dialog modal-lg"
            role="document"
            style={{ color: "white", borderRadius: "25px", maxWidth: "700px" }}
          >
            <div className="modal-content" style={{ borderRadius: "25px" }}>
              <div
                className="modal-body p-0"
                onClick={(e) =>
                  this.handleInputFormChange1("userID", user.id, e)
                }
                style={{ borderRadius: "25px" }}
              >
                {this.renderModalContent(user)}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  isLogedin = () => {
    return window.localStorage.getItem("isLogedin"); // Convert string to boolean
  };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  nextStep = (event) => {
    event.preventDefault();
    const { currentStep } = this.state;
    let valid = true;
    const errors = {}; // To collect error messages for each field

    try {
      switch (currentStep) {
        case 1:
          if (!this.state.startDate) {
            errors.startDate = "Please select a start date.";
            valid = false;
          }
          if (!this.state.requestType) {
            errors.requestType = "Please select a request type.";
            valid = false;
          }
          if (!this.state.Platform) {
            errors.Platform = "Please select a platform.";
            valid = false;
          }
          break;
        case 2:
          if (!this.state.requestInstructionsText) {
            errors.requestInstructionsText =
              "Please enter a request instructions text.";
            valid = false;
          }
          if (!this.state.WebsiteUrl) {
            errors.WebsiteUrl = "Please enter a url.";
            valid = false;
          }
          break;
        // case 3:
        // if (this.state.contentGoal === "Content") {
        //   this.setState({ Platform: "" });
        //   if (!this.state.contentFormate) {
        //     errors.contentFormate = "Please select content format.";
        //     valid = false;
        //   }
        //   if (!this.state.minimumDileverable) {
        //     errors.minimumDileverable = "Please provide minimum deliverable.";
        //     valid = false;
        //   }
        // } else {
        //   if (!this.state.Platform) {
        //     errors.Platform = "Please select a platform.";
        //     valid = false;
        //   }
        //   if (!this.state.contentFormate) {
        //     errors.contentFormate = "Please select content format.";
        //     valid = false;
        //   }
        //   if (!this.state.minimumDileverable) {
        //     errors.minimumDileverable = "Please provide minimum deliverable.";
        //     valid = false;
        //   }
        // }
        // break;
        case 4:
          if (!this.state.offerAmount) {
            errors.offerAmount = "Please enter a offerAmount";
            valid = false;
          }

          if (!this.state.productIncluded) {
            errors.productIncluded = "Please select a product included";
            valid = false;
          }
          if (this.state.productIncluded === "yes" && !this.state.productURL) {
            errors.productURL = "Please enter a product url.";
            valid = false;
          }
          if (
            this.state.productIncluded === "yes" &&
            !this.state.productValue
          ) {
            errors.productValue = "Please enter a product value";
            valid = false;
          }
          break;
        case 5:
          if (!this.state.maximumBudget) {
            errors.maximumBudget = "Please enter the maximum budget.";
            valid = false;
          }
          if (!this.state.maximumAudience) {
            errors.maximumAudience = "Please enter the maximum audience size.";
            valid = false;
          }
          if (
            !this.state.participantAudienceSizeMIN ||
            !this.state.participantAudienceSizeMAX
          ) {
            errors.participantSize =
              "Please enter the min and max participant audience size.";
            valid = false;
          }
          if (!this.state.estimatedParticipants) {
            errors.estimatedParticipants =
              "Please enter the estimated number of participants.";
            valid = false;
          }
          if (!this.state.costPerTarget) {
            errors.costPerTarget = "Please enter the cost per target.";
            valid = false;
          }
          break;
        default:
          break;
      }
      if (valid) {
        this.setState((prevState) => ({
          currentStep: prevState.currentStep + 1,
          errors: {}, // Reset errors on successful validation
        }));
        if (currentStep + 1 > 5) this.formRef.current.requestSubmit();
      } else {
        // If there are validation errors, update the errors state
        this.setState({ errors });
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };

  prevStep = () => {
    if (this.state.currentStep === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 1,
      }));
    }
  };

  handleInputFormChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]);
    });
  };
  handleInputFormChange1 = (key, value, e) => {
    e.stopPropagation();
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
    });
  };

  handleInputChange = (field, value) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [field]: value,
      }),
      () => {
        // console.log(`Updated ${field}:`, this.state[field]);
        this.fetchData(true);
        // console.log(this.filterData())
      }
    );
  };

  handleToggleBTN = () => {
    if (this.state.isActive == true) {
      this.setState({ isActive: false });
    } else {
      this.setState({ isActive: true });
    }
  };
  onClickSelectedCreators = async (event, userId) => {
    event.stopPropagation();

    // Update the state with the new selected_creators count
    this.setState(
      (prevState) => ({
        selected_creators: event.target.checked
          ? prevState.selected_creators + 1
          : prevState.selected_creators - 1,
      }),
      () => {
        // console.log(this.state.selected_creators); // This logs the updated state value

        // Proceed with the API call after state update
        const token = localStorage.getItem("accessToken");
        if (!token) {
          console.error("Access token not found");
          return;
        }

        const url = `${process.env.REACT_APP_BASE_URL}api/general/v1/favorite`;
        const headers = new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        });

        const body = JSON.stringify({ creator: userId });

        // Call the API
        fetch(url, {
          method: "POST",
          headers: headers,
          body: body,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error status: ${response.status}`);
            }
            return response.json(); // Parse the JSON response
          })
          .then((data) => {
            // console.log(data); // Handle successful response
            if (data.message === "Added to favorite!") {
              toast.success(data.message);
            } else {
              toast.warning(data.message);
            }
          })
          .catch((error) => {
            console.error("Error calling API:", error);
          });
      }
    );
  };
  handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from submitting traditionally
    const token = localStorage.getItem("accessToken");
    const campaignTypeLabels = {
      1: "UGC",
      2: "Posted Content",
      3: "Growth",
      4: "Content",
    };
    const requestBody = {
      title: "Market Place Request",
      creator: parseInt(this.state.userID), // Assuming static for the example
      brand_id: 1, // Assuming static for the example
      start_datetime: this.convertDate(this.state.startDate),
      campaign_type: this.state.contentGoal == "UGC" ? 1 : 2, // Assuming contentGoal corresponds to campaign_type
      budget: parseInt(this.state.offerAmount), // Parse the budget to an integer
      details: {
        creator_id: this.state.userID,
        platform: this.state.Platform,
        description: this.state.requestInstructionsText,
        url: this.state.WebsiteUrl, // Assuming url corresponds to WebsiteUrl in state
        attachments: [], // Assuming no attachments
        photo_post: parseInt(this.state.countInput), // Conversion to integer from countInput
        reel: parseInt(this.state.countInput1), // Conversion to integer from countInput1
        photo_story: parseInt(this.state.countInput2), // Conversion to integer from countInput2
        video_story: parseInt(this.state.countInput3), // Conversion to integer from countInput3
        post_frequency: parseInt(this.state.postFrequencyCount), // Extract number from postFrequencyCount
        post_schedule: this.state.postFrequencyPer, // Corresponds to postFrequencyPer in state
        product_included: this.state.productIncluded,
        product_url: this.state.productURL, // Matches productURL in state
        product_value: parseFloat(this.state.productValue), // Assuming this should be a float
      },
    };
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include the Bearer token in the header
      },
      body: JSON.stringify(requestBody),
    };

    // Make the API request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign`,
        fetchOptions
      );
      const responseData = await response.json(); // Assuming the server responds with JSON
      // console.log('API Response:', responseData);

      // Handle response data or errors here
      if (response.ok) {
        // Successful POST request (e.g., status code 200-299)
        // console.log('Campaign created successfully:', responseData);
        this.setState({ showForm: false });
        window.location.href = "/manage_req";
      } else {
        // Server responded with a status code outside the 200 range (e.g., error)
        console.error("Failed to create campaign:", responseData);
      }
    } catch (error) {
      // An error occurred during the fetch operation
      console.error("Error during fetch:", error);
    }
  };

  renderForm() {
    const {
      currentStep,
      startDate,
      requestType,
      selectedLogo,
      requestInstructions,
      url,
      file,
      count,
    } = this.state;
    switch (currentStep) {
      case 1:
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
              padding: "20px",
            }}
          >
            <ul id="progressbar" className="d-flex justify-content-center p-0" style={{gap: '0.8rem'}}>
              <li
                className="active"
                id="step1"
                
              >
                <strong></strong>
              </li>
              <li id="step2">
                <strong></strong>
              </li>
              <li id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            {/* <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required /> */}
            <h3
              className="mt-5"
              style={{ fontWeight: "800", fontSize: "1.2em" }}
            >
              request start date
            </h3>
            <DatePicker
              selected={
                this.state.startDate
                  ? format(this.state.startDate, "MM/dd/yyyy", new Date())
                  : null
              }
              onSelect={this.handleDateSelect}
              onChange={this.handleDateChange}
              dateFormat="MM/dd/yyyy"
              placeholderText="mm/dd/yyyy"
              minDate={new Date()}
              customInput={
                <input
                  type="text"
                  onChange={this.handleInputChange}
                  onClick={(e) => e.stopPropagation()}
                  name="startDate"
                  className="input-box text-center rounded-pill margin-responsive"
                  style={{
                    fontWeight: "800",
                    fontSize: "1em",
                    backgroundColor: "white",
                    width: "80%",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  value={
                    this.state.startDate
                      ? format(this.state.startDate, "MM/dd/yyyy")
                      : ""
                  }
                  placeholder="MM/DD/YYYY"
                />
              }
            />
            <Errors mid={true} msg={this.state?.errors?.startDate} />

            <h3
              className="mt-5"
              style={{ fontWeight: "800", fontSize: "1.2em" }}
            >
              request type
            </h3>
            <div className="row mt-3">
              <div className="col-12 col-sm-6 mb-3">
                <input
                  onClick={(e) =>
                    this.handleInputFormChange1(
                      "requestType",
                      e.target.value,
                      e
                    )
                  }
                  type="button"
                  className="rounded-pill margin-responsive selected"
                  style={{
                    color:
                      this.state.requestType === "Posted Content"
                        ? "#120c34"
                        : "white",
                    border: "2px solid white",
                    fontSize: "1em",
                    backgroundColor:
                      this.state.requestType === "Posted Content"
                        ? "white"
                        : "transparent",
                    width: "100%",
                    padding: "15px",
                    borderRadius: "25px",
                  }}
                  value="Posted Content"
                />
              </div>
              <div className="col-12 col-sm-6">
                <input
                  onClick={(e) =>
                    this.handleInputFormChange1(
                      "requestType",
                      e.target.value,
                      e
                    )
                  }
                  type="button"
                  className="rounded-pill margin-responsive"
                  style={{
                    color:
                      this.state.requestType === "UGC" ? "#120c34" : "white",
                    border: "2px solid white",
                    fontSize: "1em",
                    backgroundColor:
                      this.state.requestType === "UGC"
                        ? "white"
                        : "transparent",
                    width: "100%",
                    padding: "15px",
                    borderRadius: "25px",
                  }}
                  value="UGC"
                />
              </div>
            </div>
            <Errors mid={true} msg={this.state?.errors?.requestType} />

            <div className="text-center">
              <h3
                className="mt-5"
                style={{ fontWeight: "800", fontSize: "1.2em" }}
              >
                platform
              </h3>
              <div className="container">
                <div className="row">
                  <div className="col-4 text-center">
                    <label htmlFor="logo1">
                      <img
                        src="/images/instagram.png"
                        onClick={(e) => e.stopPropagation()}
                        alt="Instagram Logo"
                        style={{
                          width: "80px",
                          height: "80px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onChange={(e) =>
                          this.handleInputFormChange1(
                            "Platform",
                            e.target.value,
                            e
                          )
                        }
                        onClick={(e) => e.stopPropagation()}
                        id="logo1"
                        name="logo"
                        value="instagram"
                      />
                    </label>
                  </div>
                  <div className="col-4 text-center">
                    <label htmlFor="logo2">
                      <img
                        src="/images/tiktok.png"
                        onClick={(e) => e.stopPropagation()}
                        alt="TikTok Logo"
                        style={{
                          width: "80px",
                          height: "80px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputFormChange("Platform", e.target.value)
                        }
                        id="logo2"
                        name="logo"
                        value="tiktok"
                      />
                    </label>
                  </div>
                  <div className="col-4 text-center">
                    <label htmlFor="logo3">
                      <img
                        src="/images/youtube.png"
                        onClick={(e) => e.stopPropagation()}
                        alt="YouTube Logo"
                        style={{
                          width: "80px",
                          height: "80px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputFormChange("Platform", e.target.value)
                        }
                        id="logo3"
                        name="logo"
                        value="youtube"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <Errors mid={true} msg={this.state?.errors?.Platform} />
            </div>

            <div className="row mt-5 pb-3">
              <div className="col-6 text-center">
                <input
                  type="button"
                  onClick={this.prevStep}
                  className="rounded-pill bg-transparent text-white"
                  style={{
                    border: "2px solid white",
                    fontSize: "1em",
                    fontWeight: 600,
                    backgroundColor: "",
                    padding: "16px",
                    lineHeight: "normal",
                    borderRadius: "25px",
                    width: "80%",
                  }}
                  value="cancel"
                />
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn btn-lg form-button"
                  onClick={this.nextStep}
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                    width: "80%",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    continue
                  </span>
                </button>
              </div>
            </div>
           
          </div>
        );
      case 2:
        const maxLength = 800;
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
              padding: "20px",
            }}
          >
            <ul id="progressbar" className="d-flex justify-content-center p-0" style={{gap: '0.8rem'}}>
              <li className="" id="step1" >
                <strong></strong>
              </li>
              <li className="active" id="step2">
                <strong></strong>
              </li>
              <li id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              request instruction
            </h3>

            <div className=" mx-3">
              <div className=" position-relative">
                <textarea
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    this.handleInputFormChange(
                      "requestInstructionsText",
                      e.target.value
                    )
                  }
                  value={this.state.requestInstructionsText}
                  className="form-control"
                  style={{
                    height: "20em",
                    borderRadius: "25px",
                    resize: "none",
                    fontSize: "18px",
                  }}
                  placeholder="Tell the creators what you'd like to post"
                  maxLength={maxLength}
                />
                <div
                  className="position-absolute"
                  style={{
                    bottom: "5px",
                    right: "10px",
                    fontSize: "12px",
                    color: "gray",
                  }}
                >
                  {/* Placeholder text, since we're not managing state here */}
                  <b
                    style={{ color: "#120c34" }}
                  >{`${maxLength}/${maxLength} characters remaining`}</b>
                </div>
              </div>
              <Errors msg={this.state?.errors?.requestInstructionsText} />
            </div>

            <div
              className="card mx-3 mt-2 px-2"
              style={{ borderRadius: "25px" }}
            >
              <div className="d-flex align-items-center">
                <span style={{ color: "#120c34" }}>
                  <b>url:</b>
                </span>
                <input
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    this.handleInputFormChange("WebsiteUrl", e.target.value)
                  }
                  value={this.state.WebsiteUrl}
                  className="border  border-0 form-control"
                  style={{
                    border: "0",
                    borderRadius: "25px",
                    boxShadow: "none",
                    border: "none",
                  }}
                  type="text"
                  placeholder="yourwebiste.com"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <Errors className="mx-3" msg={this.state?.errors?.WebsiteUrl} />

              <div className="card mx-3 mt-3 px-2" style={{ borderRadius: "25px" }}>
    <div className="d-flex" style={{gap: '0.5rem'}}>
      <h4 className="my-2" style={{ color: "#120c34", fontSize: "clamp(16px, 3vw, 18px)" }}>
        <b>Attachment (optional):</b>
      </h4>
      <input
        type="file"
        id="fileInput"
        style={{ margin: "10px 0", display: "none" }}
      />
      <label
        htmlFor="fileInput"
        className=""
        style={{ cursor: "pointer", color: "#120c34" }}
      >
        <div className="d-flex align-items-center gap-3">
          <i
            className="fa fa-upload my-2 me-2"
            style={{ fontSize: "1.5em" }}
          ></i>
          <h4 className="my-2" style={{ fontSize: "clamp(16px, 3vw, 18px)" }}>Click to upload</h4>
        </div>
      </label>
    </div>
  </div>

            <div className="row mt-5 pb-3">
              <div className="col-6 col-sm-6">
                <input
                  type="button"
                  onClick={this.prevStep}
                  className=" rounded-pill mx-3 bg-transparent text-white"
                  style={{
                    border: "2px solid white",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    backgroundColor: "",
                    padding: "16px",
                    lineHeight: "normal",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  value="back"
                />
              </div>
              <div className="col-6 col-sm-6">
                <button
                  className="btn btn-lg form-button"
                  onClick={this.nextStep}
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    continue
                  </span>
                </button>
              </div>
            </div>
          </div>
        );
      case 3:
        const increment = (id, e) => {
          e.stopPropagation();
          const input = document.getElementById(id);
          let currentValue = parseInt(input.value, 10) || 0; // Ensure a number is always parsed
          let newValue = currentValue + 1;
          input.value = newValue; // Update the input's value
          this.handleInputFormChange(id, newValue); // Call your function with the new value
        };

        const decrement = (id, e) => {
          e.stopPropagation();
          const input = document.getElementById(id);
          let currentValue = parseInt(input.value, 10) || 0;
          let newValue = currentValue === 0 ? 0 : currentValue - 1;
          input.value = newValue;
          this.handleInputFormChange(id, newValue);
        };

        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
              padding: "20px",
            }}
          >
           <ul id="progressbar" className="d-flex justify-content-center p-0" style={{gap: '0.8rem'}}>
              <li className="" id="step1" >
                <strong></strong>
              </li>
              <li className="" id="step2">
                <strong></strong>
              </li>
              <li className="active" id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              <b>select deliverable</b>
            </h3>

            <div className="container-fluid mt-3">
              <div className="row  mt-3">
                <div
                  className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h2
                    className="my-3 form_market_font"
                    style={{ fontWeight: "600" }}
                  >
                    in-feed photo post{" "}
                  </h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div
                    className="d-flex align-items-center justify-content-center form_market_input"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={(e) => decrement("countInput", e)}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent "
                      id="countInput"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                      name="countInput"
                      value={this.state.countInput}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={(e) => increment("countInput", e)}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div
                  className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h2
                    className="my-3 form_market_font"
                    style={{ fontWeight: "600" }}
                  >
                    15-30 second reel
                  </h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div
                    className="d-flex align-items-center justify-content-center form_market_input"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={(e) => decrement("countInput1", e)}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent "
                      id="countInput1"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                      name="countInput1"
                      value={this.state.countInput1}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={(e) => increment("countInput1", e)}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div
                  className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h2
                    className="my-3 form_market_font"
                    style={{ fontWeight: "600" }}
                  >
                    3-frame photo story
                  </h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div
                    className="d-flex align-items-center justify-content-center form_market_input  "
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={(e) => decrement("countInput2", e)}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent "
                      id="countInput2"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                      name="countInput2"
                      value={this.state.countInput2}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={(e) => increment("countInput2", e)}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div
                  className="col-6 col-sm-6 align-items-center justify-content-center text-right"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h2
                    className="my-3 form_market_font"
                    style={{ fontWeight: "600" }}
                  >
                    3-frame video story
                  </h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div
                    className="d-flex align-items-center justify-content-center form_market_input"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={(e) => decrement("countInput3", e)}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent"
                      id="countInput3"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                      name="countInput3"
                      value={this.state.countInput3}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={(e) => increment("countInput3", e)}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <h3 className="mt-3" style={{ fontWeight: "800" }}>
                <b>post frequency</b>
              </h3>

              <div className="row mt-3">
                <div className="col-5 col-sm-5">
                  <div
                    className="dropdown show"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <div className="card border-0 text-center p-0">
                      <select
                        className="custom-select"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          this.handleInputFormChange(
                            "postFrequencyCount",
                            e.target.value
                          )
                        }
                        style={{
                          border: 0,
                          background: "transparent",
                          fontWeight: "600",
                          color: "#120c34",
                          textAlignLast: "center",
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                          appearance: "none",
                        }}
                        aria-label="Post selection"
                      >
                        <option value={1} selected>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </select>
                      <div
                        className="dropdown-toggle"
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "12px",
                          pointerEvents: "none",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-2 col-sm-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h2
                    className="my-3 form_market_font"
                    style={{ fontWeight: "600" }}
                  >
                    per{" "}
                  </h2>
                </div>
                <div className="col-5 col-sm-5">
                  <div
                    className="dropdown show"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <div className="card border-0 text-center p-0">
                      <select
                        className="custom-select"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          this.handleInputFormChange(
                            "postFrequencyPer",
                            e.target.value
                          )
                        }
                        style={{
                          border: 0,
                          background: "transparent",
                          fontWeight: "600",
                          color: "#120c34",
                          textAlignLast: "center",
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                          appearance: "none",
                        }}
                        aria-label="Post selection"
                      >
                        <option value="day" selected>
                          day
                        </option>
                        <option value="week">week</option>
                        <option value="month">month</option>
                      </select>
                      <div
                        className="dropdown-toggle"
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "12px",
                          pointerEvents: "none",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-6 col-sm-6">
                  <input
                    type="button"
                    onClick={this.prevStep}
                    className=" rounded-pill mx-3 bg-transparent text-white"
                    style={{
                      border: "2px solid white",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      backgroundColor: "",
                      padding: "16px",
                      lineHeight: "normal",
                      borderRadius: "25px",
                      color: "#120c34",
                    }}
                    value="back"
                  />
                </div>
                <div className="col-6 col-sm-6">
                  <button
                    className="btn btn-lg form-button"
                    onClick={this.nextStep}
                    style={{
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      continue
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
              padding: "20px",
            }}
          >
            <ul id="progressbar" className="d-flex justify-content-center p-0" style={{gap: '0.8rem'}}>
              <li className="" id="step1" >
                <strong></strong>
              </li>
              <li className="" id="step2">
                <strong></strong>
              </li>
              <li className="" id="step3">
                <strong></strong>
              </li>
              <li className="active" id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              <b>make an offer</b>
            </h3>
            <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "x-large",
                    color: '#000'
                  }}
                >
                  $
                </span>
                <input
                  type="number"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    this.handleInputFormChange("offerAmount", e.target.value)
                  }
                  style={{
                    border: "#120c34 2px solid",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    paddingLeft: "30px",
                  }}
                  placeholder="2,000"
                  className="input-box rounded-pill p-2 text-center marketplace_form_fields"
                  name="offerAmount"
                  value={this.state.offerAmount}
                />
              </div>
            <Errors mid={true} msg={this.state?.errors?.offerAmount} />

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              product included
            </h3>
            <div className="row mt-3">
              <div className="col-md-6">
                <input
                  type="button"
                  className=" rounded-pill mx-3 selected"
                  onClick={(e) =>
                    this.handleInputFormChange1(
                      "productIncluded",
                      e.target.value,
                      e
                    )
                  }
                  style={{
                    fontWeight: "800",
                    fontSize: "x-large",
                    width: "50%",
                    padding: "15px",
                    borderRadius: "25px",
                    color:
                      this.state.productIncluded === "yes"
                        ? "#120c34"
                        : "white",
                    border: "2px solid white",
                    fontSize: "x-large",
                    backgroundColor:
                      this.state.productIncluded === "yes"
                        ? "white"
                        : "transparent",
                  }}
                  value="yes"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="button"
                  className=" rounded-pill mx-3"
                  onClick={(e) =>
                    this.handleInputFormChange1(
                      "productIncluded",
                      e.target.value,
                      e
                    )
                  }
                  style={{
                    fontWeight: "800",
                    fontSize: "x-large",
                    width: "50%",
                    padding: "15px",
                    borderRadius: "25px",
                    color:
                      this.state.productIncluded === "no" ? "#120c34" : "white",
                    border: "2px solid white",
                    fontSize: "x-large",
                    backgroundColor:
                      this.state.productIncluded === "no"
                        ? "white"
                        : "transparent",
                  }}
                  value="no"
                />
              </div>
            </div>
            <Errors mid={true} msg={this.state?.errors?.productIncluded} />

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              product url
            </h3>
            <div
              className="card mx-5 mt-2 px-2"
              style={{ borderRadius: "25px" }}
            >
              <div className="row align-items-center">
                <div className="col-md-12">
                  {/* <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4> */}
                  <input
                    className="border  border-0 form-control"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      this.handleInputFormChange("productURL", e.target.value)
                    }
                    style={{
                      fontWeight: "800",
                      fontSize: "x-large",
                      border: "0",
                      borderRadius: "25px",
                    }}
                    name="productURL"
                    value={this.state.productURL}
                    type="text"
                    placeholder="yourwebiste.com"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
            <Errors mid={true} msg={this.state?.errors?.productURL} />

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              <b>product value</b>
            </h3>
              <div
      style={{
        position: "relative",
        display: "inline-block",
      }}
    >
      <span
        style={{
          position: "absolute",
          left: "15px",
          top: "50%",
          transform: "translateY(-50%)",
          fontSize: "x-large",
          color: '#000'
        }}
      >
        $
      </span>
      <input
        type="number"
        onClick={(e) => e.stopPropagation()}
        onChange={(e) =>
          this.handleInputFormChange("productValue", e.target.value)
        }
        style={{
          border: "#120c34 2px solid",
          borderRadius: "25px",
          fontSize: "x-large",
          width: "100%",
          paddingLeft: "30px",
        }}
        placeholder="0"
        className="input-box rounded-pill p-2 text-center marketplace_form_fields"
        value={this.state.productValue}
        name="productValue"
      />
    </div>
            <Errors mid={true} msg={this.state?.errors?.productValue} />

            <div className="container">
              <div className="row mt-5 pb-3">
                <div className="col-6 col-sm-6">
                  <input
                    type="button"
                    onClick={this.prevStep}
                    className=" rounded-pill mx-3 bg-transparent text-white"
                    style={{
                      fontWeight: "800",
                      fontSize: "x-large",
                      border: "2px solid white",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      backgroundColor: "",
                      padding: "16px",
                      lineHeight: "normal",
                      borderRadius: "25px",
                      color: "#120c34",
                    }}
                    defaultValue="back"
                  />
                </div>
                <div className="col-6 col-sm-6">
                  <button
                    className="btn btn-lg form-button"
                    onClick={this.nextStep}
                    style={{
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                        fontWeight: "800",
                        fontSize: "x-large",
                      }}
                    >
                      continue
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
              padding: "20px",
            }}
          >
            <ul id="progressbar" className="d-flex justify-content-center p-0" style={{gap: '0.8rem'}}>
              <li className="" id="step1" >
                <strong></strong>
              </li>
              <li className="" id="step2">
                <strong></strong>
              </li>
              <li className="" id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li className="active" id="step5">
                <strong></strong>
              </li>
            </ul>

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              <b>select deliverable</b>
            </h3>

            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="row text-center">
                    <div className="col-md-12 mb-1 text-center">
                      <h5 className="text-center" style={{ fontWeight: "800" }}>
                        <b>request details</b>
                      </h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          Start Date : {this.state.startDate}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          {this.state.requestType}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 mb-2">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          platform : {this.state.Platform}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-12 mb-1 text-center">
                      <h5 className="text-center" style={{ fontWeight: "800" }}>
                        <b>deliverables</b>
                      </h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          {this.state.countInput} in-feed photo post
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          {this.state.countInput1} 15-30 second reel
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          {this.state.countInput2} frame photo story
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          {this.state.countInput3} frame video story
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          {this.state.postFrequencyCount} per week
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 mb-1 text-center">
                    <h5 className="text-center" style={{ fontWeight: "800" }}>
                      <b>Instructions</b>
                    </h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <textarea
                        style={{
                          textAlign: "center",
                          borderRadius: "25px",
                          fontSize: "large", // Note: Adjusted to use a string value instead of "large"
                          border: "none", // Adjusted to use 'none' instead of "0"
                          fontWeight: "800",
                          width: "100%",
                          color: "#120c34", // Applied to the textarea itself
                          resize: "none", // Prevents the user from resizing the textarea
                          overflow: "hidden", // Ensures the text does not overflow the textarea
                          height: "150px", // Equivalent to 5 rows of text (assuming an average line height)
                        }}
                        rows={5}
                        disabled
                      >
                        {this.state.requestInstructionsText}
                      </textarea>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <h5 className="my-2" style={{ color: "#120c34" }}>
                          <b>url:</b>
                        </h5>
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          {this.state.WebsiteUrl}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div
                        className="d-flex align-items-center gap-3"
                        style={{ gap: "8px" }}
                      >
                        <i
                          className="fa fa-upload my-2 me-2"
                          style={{ fontSize: "1.5em", color: "#120c34" }}
                        ></i>
                        <h4 className="my-2" style={{ color: "#120c34" }}>
                          photo.jpeg
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 text-center">
                    <h5 className="text-center" style={{ fontWeight: "800" }}>
                      <b>Offer</b>
                    </h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          $ {this.state.offerAmount}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          Product URL : {this.state.productURL}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <span
                          className="py-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          Product Value $ {this.state.productValue}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-6 col-sm-6">
                  <input
                    type="button"
                    onClick={this.prevStep}
                    className=" rounded-pill mx-3 bg-transparent text-white"
                    style={{
                      fontWeight: "800",
                      fontSize: "x-large",
                      border: "2px solid white",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      backgroundColor: "",
                      padding: "16px",
                      lineHeight: "normal",
                      borderRadius: "25px",
                      color: "#120c34",
                    }}
                    defaultValue="back"
                  />
                </div>
                <div className="col-6 col-sm-6">
                  <button
                    type="submit"
                    className="btn btn-lg form-button"
                    style={{
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                        fontWeight: "800",
                        fontSize: "x-large",
                      }}
                    >
                      Submit
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  renderModalContent(user) {
    if (this.state.showForm) {
      // return <form>{this.renderForm()}</form>;
      return (
        <form
          style={{ width: "700px", maxWidth: "100%", margin: "0 auto" }}
          onSubmit={this.handleSubmit}
        >
          {/* <input type="text" onChange={e => this.handleInputFormChange1('userId', user.id, e)} style={{display:"none"}} /> */}
          {this.renderForm()}
        </form>
      );
    } else {
      return (
        <div
  style={{
    maxWidth: "550px",
    margin: "0 auto",
    borderRadius: "25px",
    backgroundColor: "#ddd",
    backgroundImage: "linear-gradient(47deg,#ddd 30%,#f1f1f1)",
    overflow: "hidden",
  }}
>
  <div
    style={{
      position: "relative",
      margin: 'auto',
      width: "70%",
      height: "0",
      paddingTop: "90%", // For portrait aspect ratio (3:2)
      overflow: "hidden",
    }}
  >
    <div
      style={{
        background: "#ddd",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
        // aspectRatio: "3/2"
      }}
    >
      <img
        src={user.images && user.images.length > 0
          ? user.images[0]
          : "/images/person.png"}
        className="img-fluid"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          // aspectRatio: "3/2"
        }}
        alt="Content"
      />
    </div>
  </div>


  <div
    className="profile"
    style={{
      background: "#120c34",
      padding: "16px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }}
  >
    <div
      className="card-body1 d-flex justify-content-between align-items-center"
      style={{
        background: "#120c34",
        flexDirection: "row",
        gap: "10px",
        flexWrap: "wrap", // Make it wrap on small screens
      }}
    >
      <h3
        style={{
          fontSize: "clamp(20px, 4vw, 24px)", 
          textAlign: "left",
          color: "#fff",
        }}
        className="card-text mb-0"
      >
        <b>{user.name}</b>
      </h3>
      <h3
        className="card-text"
        style={{
          fontSize: "clamp(14px, 3vw, 16px)", 
          color: "#ccc",
        }}
      >
        {user.location ? user.location : "No location"}
        &nbsp; <i className="fa-solid fa-location-dot"></i>
      </h3>
    </div>

    <div className="card-body2 d-grid pb-2">
      {user.social && user.social.instagram && (
        <a
          href={user.social?.instagram_handle_link}
          target="_blank"
          style={{
            textDecoration: "none",
            display: "flex",
            gap: "8px",
            alignItems: "center",
            color: "white",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/instagram.png"
            className="social_logo"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
          <h3
            className="social_font mb-0"
            style={{
              fontWeight: "bold",
              fontSize: "clamp(16px, 4vw, 18px)", 
            }}
          >
            {user.social.instagram.toLocaleString()}
          </h3>
        </a>
      )}
      <br />
      {user.social && user.social.tiktok && (
        <a
          href={user.social.tiktok_handle_link}
          target="_blank"
          style={{
            textDecoration: "none",
            display: "flex",
            gap: "8px",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <img
            src="/images/tiktok.png"
            className="social_logo"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
          <span
            className="social_font"
            style={{
              fontWeight: "bold",
              fontSize: "clamp(16px, 4vw, 18px)", 
            }}
          >
            {user.social.tiktok.toLocaleString()}
          </span>
        </a>
      )}
    </div>

    <div className="d-grid mb-2">
      {user?.interests?.map((item, index) => (
        <button
          key={index}
          className="small-rounded-button-modal"
          style={{
            borderRadius: "25px",
            fontWeight: "600",
            cursor: "default",
            color: "#120c34",
            backgroundColor: "#e0e0e0",
            margin: "4px",
            padding: "6px 12px",
            fontSize: "clamp(12px, 3vw, 14px)", 
          }}
          disabled
        >
          {item}
        </button>
      ))}
    </div>

    <div className="text-center pb-3">
      <button
        className="btn btn-lg form-button"
        onClick={this.toggleForm}
        style={{
          letterSpacing: "-.02em",
          textTransform: "none",
          backgroundColor: "#3898ec",
          backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
          borderRadius: "25px",
          fontFamily: "sofia-pro,sans-serif",
          fontSize: "clamp(16px, 4vw, 18px)", 
          fontWeight: 600,
          lineHeight: 1,
          transition: "transform .3s",
          display: "inline-block",
          padding: "10px 20px",
        }}
      >
        <span
          style={{
            color: "#fff",
            lineHeight: "inherit",
            cursor: "pointer",
            padding: "5px 10px",
            textDecoration: "none",
            display: "inline-block",
          }}
        >
          start request
        </span>
      </button>
    </div>
  </div>
</div>
      );
    }
  }

  render() {
    // const { isLoading } = this.state;
    const { loading, error } = this.state;
    const { selectedCountry, onCountryChange } = this.props;

    if (!this.isLogedin()) {
      // Redirect to /signin if not logged in
      return <Navigate to="/signin" replace />;
    }

    if (this.state.shouldNavigate) {
      return <Navigate to="/manage_req" replace />;
    }

    return (
      <div className="bg-white">
        <div className="container-fluid " style={{ height: "70vh" }}>
          <div className="row mb-3">
            <div className="col-md-3  " style={{ heigt: "80vh" }}>
              <p
                className="font-type mb-1"
                style={{
                  maxWidth: "none",
                  color: "#120c34",
                  textAlign: "center",
                  fontSize: "3em",
                }}
              >
                favorites
              </p>

              <div
                className="container-fluid mb-3 hidden-on-mobile "
                style={{
                  background: "#120c34",
                  borderRadius: "20px",
                  marginTop: "23px",
                }}
              >
                <h1
                  className="text-white text-center p-3"
                  style={{ fontWeight: "800" }}
                >
                  filters
                </h1>

                <div className="box p-3">
                  <h4
                    className="text-white text-center"
                    style={{ fontWeight: "800" }}
                  >
                    age
                  </h4>
                  <div
                    className="filter_option_wrapper"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      className="text-black text-center filter_option"
                      type="number"
                      style={{
                        fontWeight: "800",
                        borderRadius: "25px",
                        backgroundColor: "white",
                        color: "#120c34",
                        padding: "10px 20px",
                        fontSize: "1em",
                        border: "none",
                        cursor: "pointer",
                        margin: "0 5px",
                        cursor: "text",
                      }}
                      placeholder="min"
                      value={this.state.min}
                      onChange={(e) =>
                        this.handleInputChange("min_age", e.target.value)
                      }
                    />
                    <input
                      className="text-black text-center filter_option"
                      type="number"
                      style={{
                        fontWeight: "800",
                        borderRadius: "25px",
                        backgroundColor: "white",
                        color: "#120c34",
                        padding: "10px 20px",
                        fontSize: "1em",
                        border: "none",
                        cursor: "pointer",
                        margin: "0 5px",
                        cursor: "text",
                      }}
                      placeholder="max"
                      value={this.state.max}
                      onChange={(e) =>
                        this.handleInputChange("max_age", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="box1 p-3">
                  <h4
                    className="text-white text-center"
                    style={{ fontWeight: "800" }}
                  >
                    gender
                  </h4>
                  <div
                   className="filter_option_wrapper"
                   style={{
                     display: "flex",
                     justifyContent: "space-around",
                   }}
                  >
                    <input
                      onClick={(e) =>
                        this.handleInputChange("gender", e.target.value)
                      }
                      type="button"
                      className="btn btn-lg rounded-pill border-light filter_option"
                      style={{
                        color:
                          this.state.gender === "Male" ? "white" : "#120c34",
                        fontSize: "1em",
                        backgroundColor:
                          this.state.gender === "Male" ? "#120c34" : "white",
                        borderRadius: "25px",
                        fontWeight: "800",
                        border: "#120c34 2px solid",
                      }}
                      value="Male"
                    />
                    <input
                      onClick={(e) =>
                        this.handleInputChange("gender", e.target.value)
                      }
                      type="button"
                      className="btn btn-lg rounded-pill filter_option border-light"
                      style={{
                        color:
                          this.state.gender === "Female" ? "white" : "#120c34",
                        fontSize: "1em",
                        backgroundColor:
                          this.state.gender === "Female" ? "#120c34" : "white",
                        borderRadius: "25px",
                        fontWeight: "800",
                        border: "#120c34 2px solid",
                      }}
                      value="Female"
                    />
                  </div>
                </div>

                {/* <div className="box1 p-3">
                  <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Location</h4>
                  <select className="custom-select" style={{ borderRadius: "25px" }} value={selectedCountry} onChange={onCountryChange}>
                    {this.renderCountriesOptions()}
                  </select>
                </div> */}

                <div className="box2 p-3">
                  <h4
                    className="text-white text-center"
                    style={{ fontWeight: "800" }}
                  >
                    follower range
                  </h4>
                  <div
                    className="filter_option_wrapper"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {/* <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }} className='btn btn-lg'>min</button>
                    <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }}>max</button> */}
                    <input
                      className="text-black text-center filter_option"
                      type="number"
                      style={{
                        fontWeight: "800",
                        borderRadius: "25px",
                        backgroundColor: "white",
                        color: "#120c34",
                        padding: "10px 20px",
                        fontSize: "1em",
                        border: "none",
                        cursor: "pointer",
                        margin: "0 5px",
                        cursor: "text",
                      }}
                      placeholder="min"
                      value={this.state.min_range}
                      onChange={(e) =>
                        this.handleInputChange("followers_min", e.target.value)
                      }
                    />
                    <input
                      className="text-black text-center filter_option"
                      type="number"
                      style={{
                        fontWeight: "800",
                        borderRadius: "25px",
                        backgroundColor: "white",
                        color: "#120c34",
                        padding: "10px 20px",
                        fontSize: "1em",
                        border: "none",
                        cursor: "pointer",
                        margin: "0 5px",
                        cursor: "text",
                      }}
                      placeholder="max"
                      value={this.state.max_range}
                      onChange={(e) =>
                        this.handleInputChange("followers_max", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="box3 pb-3">
                  <h4
                    className="text-white text-center"
                    style={{ fontWeight: "800" }}
                  >
                    interests
                  </h4>
                  <div>
                    <div className="row text-center mx-1 mb-3">
                      <div className="d-flex flex-wrap align-items-center">
                        {this.state.interestOptions?.map((item) => (
                          <label
                            key={item.id}
                            style={{
                              display: "block",
                              cursor: "pointer",
                              borderRadius: "25px",
                              padding: "10px 20px",
                              fontWeight: "800",
                              fontSize: "0.8em",
                              backgroundColor: this.state.interests.includes(
                                item.id?.toString()
                              )
                                ? "#120c34"
                                : "white",
                              color: this.state.interests.includes(
                                item.id?.toString()
                              )
                                ? "white"
                                : "#120c34",
                              border: this.state.interests.includes(
                                item.id?.toString()
                              )
                                ? "1px solid white"
                                : "none",
                              margin: "4px 5px 0 0",
                              transition: "background-color 0.3s ease",
                            }}
                          >
                            <input
                              type="checkbox"
                              value={item.id}
                              checked={this.state.interests.includes(
                                item.id?.toString()
                              )}
                              onChange={this.handleCheckboxChange}
                              style={{ display: "none" }} // Hide the default checkbox input
                            />
                            {item.name}
                          </label>
                        ))}
                        {/* <div
                          onClick={() => this.handleShow()}
                          className="cursor-pointer text-white"
                          style={{ cursor: "pointer" }}
                        >
                          + {this.state.interestOptions?.length - 4} more
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <div
                className="pt-3 embassy-filter"
                style={{ display: "flex",  justifyContent: "flex-end" }}
              >
                <button
                  className="btn btn-lg mr-5"
                  style={{
                    display:
                      this.state.selected_creators >= 1 ? "block" : "none",
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    send request
                  </span>
                </button>
                <Link
                  to="/marketplace"
                  className="pl-2 btn btn-lg mr-5 embassy-tabs"
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    borderRadius: "25px",
                    borderColor: "#120c34 ",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#120c34",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    marketplace
                  </span>
                </Link>
                <Link
                  to="/manage_req"
                  className="btn btn-lg embassy-tabs"
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    manage requests
                  </span>
                </Link>
              </div>
              <div
                className="container-fluid mt-3"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <a
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  className="hidden-on-web mr-0 "
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    borderRadius: "25px",
                    borderColor: "#120c34 ",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                  }}
                >
                  <span
                    style={{
                      color: "#120c34",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    <i class="fa-solid fa-filter"></i> Filter
                  </span>
                </a>
              </div>
              <div class="collapse" id="collapseExample">
                <div
                  className="container-fluid mb-3 "
                  style={{ background: "#120c34", borderRadius: "20px" }}
                >
                  <h1
                    className="text-white text-center p-3"
                    style={{ fontWeight: "800" }}
                  >
                    filters
                  </h1>

                  <div className="box p-3">
                    <h4
                      className="text-white text-center"
                      style={{ fontWeight: "800" }}
                    >
                      age
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "30%",
                      }}
                    >
                      <input
                        className="text-black text-center"
                        type="number"
                        style={{
                          fontWeight: "800",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#120c34",
                          padding: "10px 20px",
                          fontSize: "1em",
                          border: "none",
                          cursor: "pointer",
                          margin: "0 5px",
                          width: "30%",
                          cursor: "text",
                        }}
                        placeholder="min"
                        value={this.state.min}
                        onChange={(e) =>
                          this.handleInputChange("min_age", e.target.value)
                        }
                      />
                      <input
                        className="text-black text-center"
                        type="number"
                        style={{
                          fontWeight: "800",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#120c34",
                          padding: "10px 20px",
                          fontSize: "1em",
                          border: "none",
                          cursor: "pointer",
                          margin: "0 5px",
                          width: "30%",
                          cursor: "text",
                        }}
                        placeholder="max"
                        value={this.state.max}
                        onChange={(e) =>
                          this.handleInputChange("max_age", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="box1 p-3">
                    <h4
                      className="text-white text-center"
                      style={{ fontWeight: "800" }}
                    >
                      gender
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "30%",
                      }}
                    >
                      <input
                        onClick={(e) =>
                          this.handleInputChange("gender", e.target.value)
                        }
                        type="button"
                        className="btn btn-lg rounded-pill mx-3 border-light"
                        style={{
                          color:
                            this.state.gender === "Male" ? "white" : "#120c34",
                          fontSize: "1em",
                          backgroundColor:
                            this.state.gender === "Male" ? "#120c34" : "white",
                          width: "30%",
                          borderRadius: "25px",
                          fontWeight: "800",
                          border: "#120c34 2px solid",
                        }}
                        value="Male"
                      />
                      <input
                        onClick={(e) =>
                          this.handleInputChange("gender", e.target.value)
                        }
                        type="button"
                        className="btn btn-lg rounded-pill mx-3 border-light"
                        style={{
                          color:
                            this.state.gender === "Female"
                              ? "white"
                              : "#120c34",
                          fontSize: "1em",
                          backgroundColor:
                            this.state.gender === "Female"
                              ? "#120c34"
                              : "white",
                          width: "30%",
                          borderRadius: "25px",
                          fontWeight: "800",
                          border: "#120c34 2px solid",
                        }}
                        value="Female"
                      />
                    </div>
                  </div>

                  {/* <div className="box1 p-3">
                    <h4
                      className="text-white text-center"
                      style={{ fontWeight: "800" }}
                    >
                      Location
                    </h4>
                    <select
                      className="custom-select"
                      style={{ borderRadius: "25px" }}
                      value={selectedCountry}
                      onChange={onCountryChange}
                    >
                      {this.renderCountriesOptions()}
                    </select>
                  </div> */}

                  <div className="box2 p-3">
                    <h4
                      className="text-white text-center"
                      style={{ fontWeight: "800" }}
                    >
                      follower range
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "30%",
                      }}
                    >
                      {/* <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }} className='btn btn-lg'>min</button>
                    <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }}>max</button> */}
                      <input
                        className="text-black text-center"
                        type="number"
                        style={{
                          fontWeight: "800",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#120c34",
                          padding: "10px 20px",
                          fontSize: "1em",
                          border: "none",
                          cursor: "pointer",
                          margin: "0 5px",
                          width: "30%",
                          cursor: "text",
                        }}
                        placeholder="min"
                        value={this.state.min_range}
                        onChange={(e) =>
                          this.handleInputChange(
                            "followers_min",
                            e.target.value
                          )
                        }
                      />
                      <input
                        className="text-black text-center"
                        type="number"
                        style={{
                          fontWeight: "800",
                          borderRadius: "25px",
                          backgroundColor: "white",
                          color: "#120c34",
                          padding: "10px 20px",
                          fontSize: "1em",
                          border: "none",
                          cursor: "pointer",
                          margin: "0 5px",
                          width: "30%",
                          cursor: "text",
                        }}
                        placeholder="max"
                        value={this.state.max_range}
                        onChange={(e) =>
                          this.handleInputChange(
                            "followers_max",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="box3 pb-3">
                    <h4
                      className="text-white text-center"
                      style={{ fontWeight: "800" }}
                    >
                      interests
                    </h4>
                    <div>
                      <div className="row text-center mx-1 mb-3">
                        <div className="d-flex flex-wrap align-items-center">
                          {this.state.interestOptions?.map((item) => (
                            <label
                              key={item.id}
                              style={{
                                display: "block",
                                cursor: "pointer",
                                borderRadius: "25px",
                                padding: "10px 20px",
                                fontWeight: "800",
                                fontSize: "0.8em",
                                backgroundColor: this.state.interests.includes(
                                  item.id?.toString()
                                )
                                  ? "#120c34"
                                  : "white",
                                color: this.state.interests.includes(
                                  item.id?.toString()
                                )
                                  ? "white"
                                  : "#120c34",
                                border: this.state.interests.includes(
                                  item.id?.toString()
                                )
                                  ? "1px solid white"
                                  : "none",
                                margin: "4px 5px 0 0",
                                transition: "background-color 0.3s ease",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={item.id}
                                checked={this.state.interests.includes(
                                  item.id?.toString()
                                )}
                                onChange={this.handleCheckboxChange}
                                style={{ display: "none" }} // Hide the default checkbox input
                              />
                              {item.name}
                            </label>
                          ))}
                          {/* <div
                            onClick={() => this.handleShow()}
                            className="cursor-pointer text-white"
                            style={{ cursor: "pointer" }}
                          >
                            + {this.state.interestOptions?.length - 4} more
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid mt-2 full-height-column">
                {/* <div className="row">

                  <div className="col-4 mb-3" data-toggle="modal" data-target=".bd-example-modal-lg">
                    <div className="card" style={{ width: "18rem", background: "#120c34", color: "white", borderRadius: "25px" }}>
                      <div style={{ position: 'relative' }}>
                        <img className="card-img-top" src="/images/dior.jpg" alt="Card image cap" style={{ borderTopRightRadius: '25px', borderTopLeftRadius: '25px', height: "35vh" }} />
                        <input type="checkbox" onChange={this.onClickSelectedCreators} onClick={(e) => e.stopPropagation()} style={{
                          position: 'absolute',
                          top: '10px', // Adjust these values as needed
                          right: '10px',
                          cursor: 'pointer',
                          width: "35px",
                          height: "35px"
                        }} />
                      </div>
                      <div className="card-body1  px-3 pt-2">
                        <p className="card-text alignleft font-type">Alice</p>
                        <small className="card-text alignright">Los Angles, CA <i className="fa-solid fa-location-dot"></i></small>
                      </div>

                      <div className="card-body2 px-3  d-grid">
                        <a href="" style={{ textDecoration: 'none' }}>
                          <img src="/images/instagram.png" alt="" style={{ width: "24px", height: "24px" }} />
                        </a>
                        <p>6.5M</p>
                        <br />
                        <a href="" style={{ textDecoration: 'none' }}>
                          <img src="/images/tiktok.png" alt="" style={{ width: "24px", height: "24px" }} />
                        </a>
                        <p>6.5M</p>
                      </div>

                      <div className="d-grid px-3 mb-2">
                        <button className='small-rounded-button mr-1 px-1'><small>ABC</small></button>
                        <button className="small-rounded-button mr-1 px-1"><small>Click Me</small></button>
                        <button className='small-rounded-button mr-1 px-1'><small>abc</small></button>
                        <small>+2 more</small>
                      </div>
                    </div>
                  </div>


                  

                  <div className="modal fade bd-example-modal-lg p-0" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ borderRadius: "25px" }}>
                    <div className="modal-dialog modal-lg" role="document" style={{ color: "white", borderRadius: "25px", maxWidth: "700px" }}>
                      <div className="modal-content" style={{ borderRadius: "25px" }} >
                        <div className="modal-body p-0" style={{ borderRadius: "25px" }} >

                          {this.renderModalContent()}

                        </div>
                      </div>
                    </div>
                  </div>

                </div> */}
                {/* <MarketplaceFetcher /> */}

                <div className="row">
                  {this.renderCards()}
                  {/* {isLoading ? (
                  <Apiloading_animation/> // Show loading animation here
                ) : (
                  this.renderCards() // Otherwise, render the cards
                )} */}
                </div>
              </div>

              {this.state.isLoading && (
                <div className="container d-flex justify-content-center align-items-center">
                  <Animation_Custom />
                </div>
              )}
              {!this.state.isLoading && this.state?.data?.length === 0 && (
                <div className="container d-flex justify-content-center align-items-center ">
                  <small
                    className="m-3 text-center"
                    style={{
                      fontSize: "20px",
                      color: "#120c34",
                      cursor: "pointer",
                    }}
                  >
                    no creators found
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalComponent
          show={this.state.showModal}
          onHide={this.handleClose}
          title="interests"
          bodyContent={
            <InterestsModal
              data={this.state.interestOptions}
              value={this.state.interests}
              handleChange={this.handleCheckboxChange}
            />
          }
          customClass="custom-modal-content"
        />
      </div>
    );
  }
}
