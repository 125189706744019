import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  convertToAmPm,
  formatDate,
} from "../../CommonComponents/CommonFunction";
import Animation_Custom from "../AnimationCustom/Animation_Custom";
import ModalComponent from "../../CommonComponents/ModalComponent";
import EditMarketplaceRequest from "../MarketPlace/EditMarketplaceRequest";
import Apiloading_animation from "../Animation/Apiloading_animation";

const DateTimeComponent = ({ isoString }) => {
  // Step 1: Parse the ISO 8601 string to a Date object
  // console.log(isoString)
  const date = new Date(isoString);

  // Check if the date is valid
  if (isNaN(date)) {
    return <p>Invalid Date</p>;
  }

  // Step 2: Format the date and time components together
  const formattedTime = date.toLocaleTimeString();

  // Step 3: Format the date component to dd/mm/yyyy
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  const formattedDateTime = `${convertToAmPm(formattedTime)} - ${formatDate(
    formattedDate
  )}`;

  return formattedDateTime;
};

function CampaignCards({ onCardSelect }) {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(null);
  const scrollContainerRef = useRef(null);

  const handleCardClick = (id) => {
    // If the same card is already selected, return early without updating the state
    if (selectedCardId === id) {
      return;
    }

    // Toggle selection: if a different card is clicked, select the new one
    setSelectedCardId(id);
  };

    const fetchCampaigns = async (currentPage) => {
      setLoading(true);
      const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?page=${currentPage}&page_size=10&campaign_type=1,2&status=2`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.status === "SUCCESS") {
          updateData(data.data.results);
          setNext(data.data.next);
        } else {
          console.error("Failed to fetch campaigns:", data.message);
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
      finally{
        setLoading(false);
      }
    };

    const updateData = (newData) => {
      setCampaigns((prevData) => {
        // Check if the last item of the current content is the same as the last item of the new data
        const lastCurrentItem = prevData[prevData.length - 1];
        const lastNewItem = newData[newData.length - 1];
  
        // Compare the last items by relevant properties (e.g., id, title, body)
        const isSame =
          lastCurrentItem?.id === lastNewItem?.id &&
          lastCurrentItem?.title === lastNewItem?.title &&
          lastCurrentItem?.body === lastNewItem?.body;
  
        // If the last items are not the same, append the new data
        if (!isSame) {
          return [...prevData, ...newData];
        }
  
        // Return the previous state if the last items are the same
        return prevData;
      });
    };
  
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (!container) return;
  
      const { scrollTop, scrollHeight, clientHeight } = container;
  
      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && next) {
        setPage((prevPage) => prevPage + 1);
      }
    };
  
    useEffect(() => {
      fetchCampaigns(page);
    }, [page]);
  
    useEffect(() => {
      if (campaigns.length > 0 && selectedCardId === null) {
        const firstCampaign = campaigns[0];
        setSelectedCardId(firstCampaign.id);
        onCardSelect(firstCampaign);
      }
    }, [campaigns, selectedCardId, onCardSelect]);
  
    useEffect(() => {
      const container = scrollContainerRef.current;
      if (!container) return;
  
      // Attach scroll event listener to the container
      container.addEventListener("scroll", handleScroll);
  
      // Cleanup the event listener on unmount
      return () => container.removeEventListener("scroll", handleScroll);
    }, [loading, next]);

  return (
    <div
    ref={scrollContainerRef}
      style={{ overflowY: "scroll", height: "70vh" }}
      className="hide-scrollbar"
    >
      {campaigns.map((campaign, index) => (
        <div
          key={campaign.id}
          className="card"
          style={{
            border:
              selectedCardId === campaign.id ? "none" : "#120c34 2px solid",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
          }}
          onClick={() => {
            handleCardClick(campaign.id);
            onCardSelect(campaign);
            if (window.innerWidth <= 768) {
              const element = document.getElementsByClassName('progress-details-container-view')[0];
              if (element) {
                element.scrollIntoView({
                  behavior: 'smooth'
                });
              }
            }
          }}
        >
          {/* <div className="card-body my-2 py-0">
            <div className="row">
              <div className="col-sm-2 col-md-4 col-lg-2">
                <img
                  src={campaign.brand?.images?.[0] || "/images/sample1.png"}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "#120c34 3px solid",
                  }}
                />
              </div>
              <div className="col-sm-10 col-md-8 col-lg-10" style={{ cursor: "pointer" }}>
                <div style={{ textAlign: "start" }}>
                  <div style={{ marginBottom: "10px" }}>
                  <h4 className="request_list_heading" style={{ color: "#120c34", fontWeight: "bold" }}>
                      {campaign.creator?.name || "Unknown"}
                    </h4>
                  </div>
                  <div>
                    <h6 style={{ color: "#120c34" }}>
                      <i>
                        <DateTimeComponent isoString={campaign.updated_at} />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
           <div className="card-body my-2 py-0">
              <div className="row" style={{alignItems: 'center'}}>
                <div className="col-sm-2 col-3 col-lg-2">
                  <img
                    src={campaign.brand?.images?.[0] || "/images/sample1.png"}
                    alt=""
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "#120c34 3px solid",
                    }}
                  />
                </div>
                <div className="col-sm-6 col-6 col-lg-6" style={{ cursor: "pointer" }}>
                  <div style={{ textAlign: "start" }}>
                    <div className="request_list_heading" style={{ color: "#120c34", fontWeight: "bold"}}>
                        {campaign.brand?.name || "Unknown"}
                    </div>
                    <div>  {campaign?.deliverables} </div>
                    <div>  {campaign?.received_on} </div>
                  </div>
                </div>
                <div className="col-sm-4 col-3 col-lg-4" style={{textAlign: 'end', fontWeight: 600}}>
                    <div>${campaign?.budget}</div>
                </div>
              </div>
            </div>
        </div>
      ))}
      {loading && (
        <div className="container d-flex justify-content-center align-items-center">
          <Animation_Custom />
        </div>
      )}
    </div>
  );
}
const AcceptCampaignButton = ({ campaign }) => {
  const handleAcceptCampaign = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No access token found");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/accept/${campaign.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Campaign accepted:', data);
      toast.success("Campaign accepted ");
      window.location.reload();
    } catch (error) {
      console.error("Failed to accept campaign:", error);
      toast.error("Error! ");
    }
  };

  return (
    <button
      onClick={handleAcceptCampaign}
      className="btn bg-transparent"
      style={{
        // backgroundColor: "#3898ec",
        // backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
        border: "#120c34 3px solid",
        // color: "white",
        borderRadius: "25px",
        fontSize: "x-large",
      }}
    >
      accept request
    </button>
  );
};
const RejectCampaignButton = ({ campaign }) => {
  const handleRejectCampaign = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No access token found");
      return;
    }

    try {
      const response = await fetch(
        `https://api.embassy.io/api/campaign/v1/campaign/reject/${campaign.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Campaign Rejected:', data);
      toast.warning("Campaign Rejected ");
      window.location.reload();
    } catch (error) {
      console.error("Failed to accept campaign:", error);
      toast.error("Error! ");
    }
  };

  return (
    <button
      onClick={handleRejectCampaign}
      className="btn bg-transparent"
      style={{
        border: "#120c34 3px solid",
        borderRadius: "25px",
        fontSize: "x-large",
      }}
    >
      reject request
    </button>
  );
};
function CampaignCardsInProgress({ onCardSelect }) {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [next, setNext] = useState(null); // Track current page
  const scrollContainerRef = useRef(null);

  const handleCardClick = (id) => {
    // If the same card is already selected, return early without updating the state
    if (selectedCardId === id) {
      return;
    }

    // Toggle selection: if a different card is clicked, select the new one
    setSelectedCardId(id);
  };

  const updateData = (newData) => {
    setCampaigns((prevData) => {
      // Check if the last item of the current content is the same as the last item of the new data
      const lastCurrentItem = prevData[prevData.length - 1];
      const lastNewItem = newData[newData.length - 1];

      // Compare the last items by relevant properties (e.g., id, title, body)
      const isSame =
        lastCurrentItem?.id === lastNewItem?.id &&
        lastCurrentItem?.title === lastNewItem?.title &&
        lastCurrentItem?.body === lastNewItem?.body;

      // If the last items are not the same, append the new data
      if (!isSame) {
        return [...prevData, ...newData];
      }

      // Return the previous state if the last items are the same
      return prevData;
    });
  };

  const fetchCampaigns = async (page) => {
    setLoading(true);
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?page=${page}&page_size=10&campaign_type=1,2&status=3`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        updateData(data.data.results);
        setNext(data.data.next);
        setLoading(false);
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;

    // Check if the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Fetch initial data on component mount
  useEffect(() => {
    fetchCampaigns(page);
  }, [page, onCardSelect]); // Fetch data whenever page changes

  useEffect(() => {
    if (campaigns.length > 0) {
      // Automatically select the first campaign
      const firstCampaign = campaigns[0];
      setSelectedCardId(firstCampaign.id);
      onCardSelect(firstCampaign);
    }
  }, [campaigns]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    // Attach scroll event listener to the container
    container.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on unmount
    return () => container.removeEventListener("scroll", handleScroll);
  }, [loading, next]);

  return (
    <div
      className="hide-scrollbar"
      ref={scrollContainerRef}
      style={{ height: "70vh", overflowY: "scroll" }}
    >
      {campaigns?.length &&
        campaigns?.map((campaign, index) => (
        <div
          key={campaign.id}
          className="card"
          style={{
            border:
              selectedCardId === campaign.id ? "none" : "#120c34 2px solid",
              borderLeft: "none",
          }}
          onClick={() => {
            handleCardClick(campaign.id);
            onCardSelect(campaign);
            if (window.innerWidth <= 768) {
              const element = document.getElementsByClassName('progress-details-container-view')[0];
              if (element) {
                element.scrollIntoView({
                  behavior: 'smooth'
                });
              }
            }
          }}
        >
          {/* <div className="card-body my-2 py-0">
            <div className="row">
            <div className="col-sm-2 col-md-4 col-lg-2">
                <img
                  src={campaign.brand?.images?.[0] || "/images/sample1.png"}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "#120c34 3px solid",
                  }}
                />
              </div>
              <div className="col-sm-10 col-md-8 col-lg-10" style={{ cursor: "pointer" }}>
                <div style={{ textAlign: "start" }}>
                  <div style={{ marginBottom: "10px" }}>
                    {" "}
                    <h4
                     className="request_list_heading"
                     style={{
                       color: "#120c34",
                       fontWeight: "bold",
                     }}
                    >
                      {campaign.brand?.name || "Unknown"}
                    </h4>
                  </div>
                  <div>
                    <h6 style={{ color: "#120c34" }}>
                      <i>
                        <DateTimeComponent isoString={campaign.updated_at} />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

<div className="card-body my-2 py-0">
              <div className="row" style={{alignItems: 'center'}}>
                <div className="col-sm-2 col-3 col-lg-2">
                  <img
                    src={campaign.brand?.images?.[0] || "/images/sample1.png"}
                    alt=""
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "#120c34 3px solid",
                    }}
                  />
                </div>
                <div className="col-sm-6 col-6 col-lg-6" style={{ cursor: "pointer" }}>
                  <div style={{ textAlign: "start" }}>
                    <div className="request_list_heading" style={{ color: "#120c34", fontWeight: "bold"}}>
                        {campaign.brand?.name || "Unknown"}
                    </div>
                    <div>  {campaign?.deliverables} </div>
                    <div>  {campaign?.received_on} </div>
                  </div>
                </div>
                <div className="col-sm-4 col-3 col-lg-4" style={{textAlign: 'end', fontWeight: 600}}>
                    <div>${campaign?.budget}</div>
                </div>
              </div>
            </div>
        </div>
      ))}
      {loading && (
        <div className="container d-flex justify-content-center align-items-center">
          <Animation_Custom />
        </div>
      )}
    </div>
  );
}

const CampaignDetails_Check_Approval = ({ campaign, handleOpen }) => {
  // State to manage the API result
  const [apiResult, setApiResult] = useState(null);

  // Function to fetch the API result
  const fetchApiResult = async () => {
    try {
      setApiResult(campaign.status);
    } catch (error) {
      console.error("Error fetching API result:", error);
    }
  };

  useEffect(() => {
    fetchApiResult();
  }, []);
  // console.log("// console.log(apiResult): ", apiResult)
  return (
    <div>
      {apiResult === 2 && (
        <div className="row mt-3">
          <div className="col-md-4 d-flex justify-content-center">
          {/* <div className="col-md-4 "> */}
            {/* <button className='btn bg-transparent' style={{   border: "#120c34 2px solid", borderRadius: "25px", fontSize: "x-large" }}> Reject request</button> */}
            <RejectCampaignButton campaign={campaign} />
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <button
              className="btn bg-transparent"
              style={{
                border: "#120c34 3px solid",
                borderRadius: "25px",
                fontSize: "x-large",
              }}
              onClick={() => handleOpen(2)}
            >
              counter request
            </button>
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            <AcceptCampaignButton campaign={campaign} />
          </div>
        </div>
      )}
      {apiResult === 3 && (
        <div className="row mt-3">
          <div className="col-md-9">
            <div
              className="d-flex align-items-center"
              style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
            >
              <h4 className="my-2 px-2">Accepted:</h4>
              <input
                type="text"
                style={{ fontSize: "x-large", outline: "none" }}
                className="input-box rounded-pill p-1 border-0"
                placeholder="your request has been accepted"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <button
                className="btn bg-transparent"
                style={{
                  border: "#120c34 3px solid",
                  borderRadius: "25px",
                  fontSize: "x-large",
                }}
              >
                cancel request
              </button>
            </div>
          </div>
        </div>
      )}
      {apiResult === 5 && (
        <div className="row mt-3">
          <div className="col-md-9">
            <div
              className="d-flex align-items-center"
              style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
            >
              <h4 className="my-2 px-2">Rejected:</h4>
              <input
                type="text"
                style={{ fontSize: "x-large", outline: "none" }}
                className="input-box rounded-pill p-1 border-0"
                placeholder="your request has been rejected"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <button
                className="btn bg-transparent"
                style={{
                  border: "#120c34 3px solid",
                  borderRadius: "25px",
                  fontSize: "x-large",
                }}
              >
                cancel request
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function CampaignDetails({ campaign, handleOpen }) {
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleCardClick = (id) => {
    // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
    setSelectedCardId(selectedCardId === id ? null : id);
  };
  const platformImages = {
    instagram: "/images/instagram.png",
    youtube: "/images/youtube.png",
    tiktok: "/images/tiktok.png",
  };

  if (!campaign) {
    return (
      <div className="container-fluid text-center mt-5" style={{}}>
        <i class="fa-solid fa-code-pull-request fa-3x"></i>
        <h1>select a campaign to see details</h1>
      </div>
    );
  }

  // Assuming 'platform' is directly under 'details' and is always a string.
  const platform = campaign.details?.platform; // Access the platform information more safely
  const getImageForPlatform = (platform) => {
    return platformImages[platform.toLowerCase()] || "/images/placeholder.png";
  };

  const imageSrc = platform
    ? getImageForPlatform(platform)
    : "/images/placeholder.png";

  return (
    <div className="container-fluid progress-details-container-view" style={{maxHeight: '70vh', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', '&::-webkit-scrollbar': {display: 'none'}}}>
      <div className="row">
        <div className="col-lg-4">
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            proposed content
          </h5>
          <textarea
            className="p-2"
            cols="30"
            rows="9"
            style={{
              borderRadius: "25px",
              border: "#120c34 3px solid",
              width: "100%",
              fontSize: "24px",
            }}
            value={campaign.details.description || ""}
            readOnly
          />
        </div>
        <div className="col-md-8" style={{display: 'flex', flexDirection: 'column'}}>
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            deliverables
          </h5>
          <div
            className="card"
            style={{ border: "#120c34 3px solid", borderRadius: "25px", flex:1 }}
          >
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={imageSrc}
                    alt={`platform: ${platform || "Unknown"}`}
                    className="img-fluid-deliverables"
                  />
                </div>
                <div className="col-md-8 mt-3">
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.video_story || "None"
                  } : three-frame Video stories`}</h3>
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_story || "None"
                  } : three-frame Photo stories`}</h3>
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_post || "None"
                  } : in-feed photo post `}</h3>
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.reel || "None"
                  } : 15-20 sec reel`}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`Frequency ${
                    campaign.details?.post_frequency || "None"
                  } : per week`}</h3>
                </div>
              </div>
            </div>
          </div>
          {
            campaign.details.product_included === "yes" &&
            <div>
              <h5 style={{ fontWeight: 700 }} className="text-center mt-4">
                offer: cash + product
              </h5>
              <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <input
                  type="text"
                  style={{
                    border: "#120c34 3px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "80%",
                  }}
                  placeholder={`$${campaign.details?.product_value || "None"}`}
                  className="input-box rounded-pill p-1 text-center"
                  readOnly
                />
                <input
                  type="text"
                  style={{
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "80%",
                    border: "#120c34 3px solid",
                  }}
                  placeholder={`${campaign.details?.product_url || "None"}`}
                  className="input-box rounded-pill p-1 text-center"
                  readOnly
                />
              </div>
            </div>
          }
        </div>
      </div>

      <div className="row mt-3">
      <div className="col-12 col-lg-9">
          <div
            className="d-flex align-items-center flex-column flex-md-row"
            style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
          >
            <h4 className="my-2 px-2" style={{ fontWeight: 700 }}>
              attachment:
            </h4>
            <input
              type="text"
              style={{ fontSize: "22px", outline: "none", minWidth: '160px', maxWidth: '600px' }}
              className="input-box rounded-pill p-1 border-0"
              placeholder={campaign.details.attachment || "attachment.jpeg"}
              readOnly
            />
          </div>
        </div>
        {/* <div className="col-md-3">
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>

                        <button className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 3px solid", borderRadius: "25px", fontSize: "xx-large" }}><i class="fa-solid fa-pen"></i> edit request</button>
                    </div>
                </div> */}
      </div>
      {/* <div className="row mt-3 " >
                <div className="col-md-4 ">
                    <button className='btn bg-transparent' style={{   border: "#120c34 3px solid", borderRadius: "25px", fontSize: "xx-large" }}> Reject request</button>
                </div>
                <div className="col-md-4 text-center">
                    <button className='btn bg-transparent' style={{ border: "#120c34 3px solid", borderRadius: "25px", fontSize: "xx-large" }}>Counter request</button>
                </div>
                <div className="col-md-4 text-right">
                    <button className='btn bg-transparent' style={{backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)',color:"white", borderRadius: "25px", fontSize: "xx-large" }}>Accept request</button>
                </div>
            </div> */}
      {/* <h1 className='text-primary'>{campaign.status}</h1> */}
      <CampaignDetails_Check_Approval handleOpen={handleOpen} campaign={campaign} />
    </div>
  );
}

function CampaignDetailsProgress({ campaign }) {
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleCardClick = (id) => {
    // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
    setSelectedCardId(selectedCardId === id ? null : id);
  };
  const platformImages = {
    instagram: "/images/instagram.png",
    youtube: "/images/youtube.png",
    tiktok: "/images/tiktok.png",
  };

  if (!campaign) {
    return (
      <div className="container-fluid text-center mt-5" style={{}}>
        <i class="fa-solid fa-code-pull-request fa-3x"></i>
        <h1>select a campaign to see details</h1>
      </div>
    );
  }

  // Assuming 'platform' is directly under 'details' and is always a string.
  const platform = campaign.details?.platform; // Access the platform information more safely
  const getImageForPlatform = (platform) => {
    return platformImages[platform.toLowerCase()] || "/images/placeholder.png";
  };

  const imageSrc = platform
    ? getImageForPlatform(platform)
    : "/images/placeholder.png";




  return (
    <div className="container-fluid progress-details-container-view" style={{maxHeight: '70vh', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none', '&::-webkit-scrollbar': {display: 'none'}}}>
      <div className="row">
        <div className="col-lg-4">
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            proposed content
          </h5>
          <textarea
            className="p-2"
            cols="30"
            rows="9"
            style={{
              borderRadius: "25px",
              border: "#120c34 3px solid",
              width: "100%",
              fontSize:"24px"
            }}
            value={campaign.details.description || ""}
            readOnly
          />
        </div>
        <div className="col-lg-8" style={{display: 'flex', flexDirection: 'column'}}>
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            deliverables
          </h5>
          <div
            className="card"
            style={{ border: "#120c34 3px solid", borderRadius: "25px", flex: 1 }}
          >
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={imageSrc}
                    alt={`platform: ${platform || "Unknown"}`}
                    className="img-fluid-deliverables"
                  />
                </div>
                <div className="col-md-8 mt-3">
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.video_story || "None"
                  } : three-frame Video stories`}</h3>
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_story || "None"
                  } : three-frame Photo stories`}</h3>
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_post || "None"
                  } : in-feed photo post `}</h3>
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`${
                    campaign.details?.reel || "None"
                  } : 15-20 sec reel`}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3 className="name_font_title_deliverables" style={{ color: "#120c34" }}>{`Frequency ${
                    campaign.details?.post_frequency || "None"
                  } : per week`}</h3>
                </div>
              </div>
            </div>
          </div>
          {
            campaign.details.product_included === "yes" &&
            <div>
              <h5 style={{ fontWeight: 700 }} className="text-center mt-4">
                offer: cash + product
              </h5>
              <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <input
                  type="text"
                  style={{
                    border: "#120c34 3px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "80%",
                  }}
                  placeholder={`$${campaign.details?.product_value || "None"}`}
                  className="input-box rounded-pill p-1 text-center"
                  readOnly
                />
                <input
                  type="text"
                  style={{
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "80%",
                    border: "#120c34 3px solid",
                  }}
                  placeholder={`${campaign.details?.product_url || "None"}`}
                  className="input-box rounded-pill p-1 text-center"
                  readOnly
                />
              </div>
            </div>
          }
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-9">
          <div
            className="d-flex align-items-center"
            style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
          >
            <h4 className="my-2 px-2" style={{ fontWeight: 700 }}>
              URL:
            </h4>
            <input
              type="text"
              style={{ fontSize: "x-large", outline: "none", width: "80%" }}
              className="input-box rounded-pill p-1 border-0"
              placeholder={campaign.details.url}
              readOnly
            />
          </div>
        </div>
        <div className="col-md-3">
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {/* <input type="text" style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 3px solid" }} placeholder="Edit request" className=" datepicker input-box rounded-pill p-1 text-center" readOnly /> */}
            {/* <button className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}><i class="fa-solid fa-pen"></i> edit request</button> */}
          </div>
        </div>
      </div>
      <div className="row mt-3" style={{gap: '10px'}}>
        <div className="col-12 col-lg-9">
          <div
            className="d-flex align-items-center flex-column flex-md-row"
            style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
          >
            <h4 className="my-2 px-2" style={{ fontWeight: 700 }}>
              attachment:
            </h4>
            <input
              type="text"
              style={{ fontSize: "22px", outline: "none", minWidth: '160px', maxWidth: '600px' }}
              className="input-box rounded-pill p-1 border-0"
              placeholder={campaign.details.attachment || "attachment.jpeg"}
              readOnly
            />
          </div>
        </div>
        
      </div>

      <div className="d-flex justify-content-center justify-content-sm-end my-2" >
        <button
          data-toggle="modal"
          data-target="#exampleModal"
          className="btn btn-lg"
          style={{
            letterSpacing: "-.02em",
            textTransform: "none",
            backgroundColor: "#3898ec",
            backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
            borderRadius: "25px",
            fontFamily: "sofia-pro,sans-serif",
            fontSize: "1.2em",
            fontWeight: 600,
            lineHeight: 1,
            transition: "transform .3s",
            display: "inline-block",
          }}
        >
          <span
            style={{
              color: "#fff",
              lineHeight: "inherit",
              cursor: "pointer",
              border: "0",
              borderRadius: "0",
              padding: "9px 15px",
              textDecoration: "none",
              display: "inline-block",
            }}
          >
            submit content for approval
          </span>
        </button>
      </div>

      <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content" style={{ borderRadius: "25px" }}>
              <UploadForm campaignData={campaign} />
            </div>
          </div>
        </div>
    </div>
  );
}

const UploadForm = ({ campaignData }) => {

  const [fullfilledDeliverables, setFullfilledDeliverables] = useState(0);
  const [expectedDeliverables, setExpectedDeliverables] = useState(0);

  useEffect(() => {
    if(campaignData?.deliverables) {
      try {
        const totalDeliverables = parseInt(campaignData?.deliverables?.slice(2,3));
        const filledDeliverables = parseInt(campaignData?.deliverables?.slice(0,1))
        setFullfilledDeliverables(filledDeliverables)
        setExpectedDeliverables(totalDeliverables)
      }
      catch(e) {
        console.error(e)
      }
    }
  }, [campaignData] )

  console.log(campaignData)


  const [desc, setDesc] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const modalRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setFileCount(files.length);
    // console.log('Files selected:', files);
  };

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };
  const truncateFileName = (fileName) => {
    // Split the file name by spaces into an array of words
    const words = fileName.split(" ");

    // If there are more than 5 words, return the first 5 joined with a space and add ellipsis
    if (words.length > 5) {
      return words.slice(0, 4).join(" ") + "...";
    }

    // If there are 5 or fewer words, return the full name
    return fileName;
  };

  const uploadFileToAzure = async (file, sasUrl) => {
    try {
      const response = await fetch(sasUrl, {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": file.type || "application/octet-stream",
        },
        body: file,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file.");
      }
      // console.log('File uploaded successfully to:', sasUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Rethrow to handle in the calling function
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const campaignID = campaignData?.id;
    const token = localStorage.getItem("accessToken");
    if (selectedFiles.length > 0) {
      // Create an object to count files by extension
      const fileCountByExtension = selectedFiles.reduce((acc, file) => {
        const ext = file.name.split(".").pop();
        acc[ext] = (acc[ext] || 0) + 1;
        return acc;
      }, {});

      // Convert the object into the required format for the API
      const uploadUrlBody = {
        count: Object.entries(fileCountByExtension).map(
          ([extension, count]) => ({
            extension,
            count,
          })
        ),
      };

      const uploadUrlOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(uploadUrlBody),
      };

      try {
        const uploadUrlResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/general/v1/upload-url`,
          uploadUrlOptions
        );
        const uploadUrlData = await uploadUrlResponse.json();

        if (!uploadUrlResponse.ok) {
          throw new Error(
            `Failed to obtain upload URLs: ${
              uploadUrlData.error || "Unknown Error"
            }`
          );
        }

        // console.log('Upload URLs:', uploadUrlData);
        if (uploadUrlData.status === "SUCCESS") {
          // Upload each file to the provided URL
          const attachments = uploadUrlData.data.map((item) => item.key);
          for (let i = 0; i < selectedFiles.length; i++) {
            await uploadFileToAzure(
              selectedFiles[i],
              uploadUrlData.data[i].url
            );
          }

          // Create the final request body for the campaign API
          const campaignBody = {
            campaign_id: campaignID,
            caption: desc,
            uploads: attachments,
          };

          const campaignOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(campaignBody),
          };

          const campaignResponse = await fetch(
            `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content`,
            campaignOptions
          );
          const campaignData = await campaignResponse.json();

          if (!campaignResponse.ok) {
            throw new Error(
              `Failed to submit campaign content: ${
                campaignData.error || "Unknown Error"
              }`
            );
          }

          // console.log('Campaign submitted successfully:', campaignData);
          toast.success("Campaign submitted successfully!");
          setSelectedFiles([]);
          setDesc("");

          //close modal automatically
          const modal = document.getElementById("exampleModal");
          if (modal) {
            modal.classList.remove("show");
            modal.setAttribute("aria-hidden", "true");
            modal.setAttribute("style", "display: none");
            const modalBackdrop = document.querySelector(".modal-backdrop");
            if (modalBackdrop) {
              modalBackdrop.remove();
            }
            document.body.classList.remove("modal-open");
          }
        }
      } catch (error) {
        console.error(
          "Error fetching upload URLs or uploading files:",
          error
        );
        setIsLoading(false); // Stop the loading state on error
        return; // Stop the submission if file upload URLs cannot be obtained
      }
    }
    setIsLoading(false); // Stop the loading state on success
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="modal-body border border-light"
        style={{ background: "#120c34", borderRadius: "25px" }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="card"
            style={{ width: "50%", borderRadius: "25px" }}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              multiple
              disabled={selectedFiles?.length === (expectedDeliverables - fullfilledDeliverables)}
              accept="image/*"
              onChange={(e) => {
                if (e.target.files.length > (expectedDeliverables - fullfilledDeliverables)) {
                  toast.error(`You can only upload up to ${(expectedDeliverables - fullfilledDeliverables)} files`);
                  e.target.value = null;
                  return;
                }
                handleFileChange(e);
              }}
            />
            <div
              onClick={handleLabelClick}
              style={{ cursor: "pointer", color: "#120c34" }}
            >
              <div className="text-center">
                <i className="fa-solid fa-upload fa-4x mt-3"></i>
                <p style={{ fontWeight: "800", fontSize: "large" }}>
                  Tap to upload
                </p>
              </div>
            </div>
          </div>
        </div>
        <p
          className="text-light text-center mt-2"
          style={{ fontWeight: "800" }}
        >
          {selectedFiles.length + fullfilledDeliverables}/{expectedDeliverables} maximum deliverables submitted
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "20px",
                padding: "10px",
                background: "#f9f9f9",
              }}
            >
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={{
                  width: "30px",
                  height: "30px",
                  objectFit: "cover",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              />
              <div style={{ flex: 1 }}>
                <p style={{ margin: 0 }}>{truncateFileName(file.name)}</p>
              </div>
              <button
                type="button"
                onClick={() => handleRemoveFile(index)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "red",
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
          ))}
        </div>

        <div className="text-center">
          <textarea
            className="px-3 mr-3"
            style={{
              width: "100%",
              height: "20em",
              borderRadius: "25px",
              resize: "none",
              cursor: "default",
              fontSize: "18px",
            }}
            placeholder="Tell the creators what you'd like to post"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          {/* <div className="" style={{display:"flex",justifyContent:"center",fontWeight:"bold"}}>
                          <a className='text-white' href={maskedUrl}>{maskedUrl}</a>
                      </div> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              alignItems: "center",
            }}
          >
            <a
              className="text-white"
              href={maskedUrl}
              style={{ marginRight: "10px" }}
            >
              {maskedUrl}
            </a>
            <FaCopy
              style={{ cursor: "pointer", color: "white" }}
              onClick={copyToClipboard}
            />
          </div> */}
          <div className="row text-center">
            {/* <p>{maskedurl}</p> */}
            <div className="col-12 text-center mb-3 mt-3">
              <button
                type="submit"
                className="btn btn-lg"
                style={{
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage:
                    "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    border: "0",
                    borderRadius: "0",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  {isLoading ? (
                    <>
                      {/* <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
                                              Loading... */}
                      <Apiloading_animation />
                      uploading...
                    </>
                  ) : (
                    "submit content"
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

function CampaignCardsCompleted({ onCardSelect }) {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [next, setNext] = useState(null); // Track current page
  const scrollContainerRef = useRef(null);

  const handleCardClick = (id) => {
    // If the same card is already selected, return early without updating the state
    if (selectedCardId === id) {
      return;
    }

    // Toggle selection: if a different card is clicked, select the new one
    setSelectedCardId(id);
  };

  const updateData = (newData) => {
    setCampaigns((prevData) => {
      // Check if the last item of the current content is the same as the last item of the new data
      const lastCurrentItem = prevData[prevData.length - 1];
      const lastNewItem = newData[newData.length - 1];

      // Compare the last items by relevant properties (e.g., id, title, body)
      const isSame =
        lastCurrentItem?.id === lastNewItem?.id &&
        lastCurrentItem?.title === lastNewItem?.title &&
        lastCurrentItem?.body === lastNewItem?.body;

      // If the last items are not the same, append the new data
      if (!isSame) {
        return [...prevData, ...newData];
      }

      // Return the previous state if the last items are the same
      return prevData;
    });
  };

  const fetchCampaigns = async (page) => {
    setLoading(true);
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?page=${page}&page_size=10&campaign_type=1,2&status=4`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        updateData(data.data.results);
        setNext(data.data.next);
        setLoading(false);
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;

    // Check if the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 10 && !loading && next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Fetch initial data on component mount
  useEffect(() => {
    fetchCampaigns(page);
  }, [page, onCardSelect]); // Fetch data whenever page changes

  useEffect(() => {
    if (campaigns.length > 0) {
      // Automatically select the first campaign
      const firstCampaign = campaigns[0];
      setSelectedCardId(firstCampaign.id);
      onCardSelect(firstCampaign);
    }
  }, [campaigns]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    // Attach scroll event listener to the container
    container.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on unmount
    return () => container.removeEventListener("scroll", handleScroll);
  }, [loading, next]);

  return (
    <div
      className="hide-scrollbar"
      ref={scrollContainerRef}
      style={{ height: "70vh", overflowY: "scroll" }}
    >
      {campaigns?.length
        ? campaigns?.map((campaign, index) => (
            <div
              key={campaign.id}
              className="card"
              style={{
                border:
                  selectedCardId === campaign.id ? "none" : "#120c34 2px solid",
                borderLeft: "none",
              }}
              onClick={() => {
                handleCardClick(campaign.id);
                onCardSelect(campaign);
                if (window.innerWidth <= 768) {
                  const element = document.getElementsByClassName('progress-details-container-view')[0];
                  if (element) {
                    element.scrollIntoView({
                      behavior: 'smooth'
                    });
                  }
                }
              }}
            >
              <div className="card-body my-2 py-0">
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src={
                        campaign.creator?.images?.[0] || "/images/sample1.png"
                      }
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "#120c34 3px solid",
                      }}
                    />
                  </div>
                  <div className="col-md-10" style={{ cursor: "pointer" }}>
                    <div style={{ textAlign: "start" }}>
                      <div style={{ marginBottom: "10px" }}>
                        {" "}
                        <h4
                          style={{
                            color: "#120c34",
                            fontWeight: "bold",
                          }}
                        >
                          {campaign.creator?.name || "Unknown"}
                        </h4>
                      </div>
                      <div>
                        <h6 style={{ color: "#120c34" }}>
                          <i>
                            {" "}
                            <DateTimeComponent
                              isoString={campaign.updated_at}
                            />
                          </i>
                        </h6>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : !loading && (
            <div
              style={{ paddingTop: "30px" }}
              className="container d-flex justify-content-center align-items-center"
            >
              no data found{" "}
            </div>
          )}
      {loading && (
        <div className="container d-flex justify-content-center align-items-center">
          <Animation_Custom />
        </div>
      )}
    </div>
  );
}

export default function Manage_req() {
  const [activeTab, setActiveTab] = useState("pending"); // Default active tab
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedCampaignP, setSelectedCampaignP] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [counter, setCounter] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowEditModal(false);
  };

  const handleOpen = (counter) => {
    setShowEditModal(true);
    setCounter(counter);
  };

  useEffect(() => {
    const loggedInStatus = window.localStorage.getItem("isLogedin") === "true";
    // console.log("t1=", loggedInStatus);
    setIsLoggedIn(loggedInStatus);

    if (!loggedInStatus) {
      navigate("/signin", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    // console.log("t2=", isLoggedIn);
  }, [isLoggedIn]);

  const handleCardClick = (id) => {
    // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
    setSelectedCardId(selectedCardId === id ? null : id);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedCampaign(null);
    setSelectedCampaignP(null);
  };
  const backButtonStyle = {
    background: "none",
    border: "none",
    color: "inherit",
    padding: 0,
    fontSize: "inherit",
    cursor: "pointer",
    marginBottom: "5px",
    fontSize: "25px",
  };
  const getBackgroundColor = (tabName) => {
    return activeTab === tabName ? "#120c34" : "#fff"; // Change #fff to your default or inactive color
  };
  const getTextColor = (tabName) => {
    return activeTab === tabName ? "#fff" : "#120c34"; // Active text color #fff, inactive text color #120c34
  };

  if (!isLoggedIn) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div>
      <div className="container-fluid pb-2">
        <div className="row ">
          <button
            className="ml-3 d-flex align-items-center"
            onClick={() => window.history.back()}
            style={backButtonStyle}
          >
            <i
              className="fa fa-arrow-left mr-1"
              style={{ fontSize: "13px" }}
            ></i>
            back
          </button>
        </div>
        <div style={{width: '98%', margin: '0 auto', marginTop: "16px"}}>
            <h3 style={{ fontSize: "38px" }}>requests</h3>
          </div>
        <div className="row">
          <div  className="col-4 col-sm-4" style={{paddingRight: '4px'}}>
            <div className="card border-0">
              <div
                className="card-body tabs-padding"
                style={{
                  border: "#120c34 3px solid",
                  background: getBackgroundColor("pending"),
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  cursor: "pointer",
                }}
                onClick={() => handleTabClick("pending")}
              >
                <h3
                  className="tabs-heading text-center"
                  style={{ cursor: "pointer", color: getTextColor("pending"), fontWeight: 700, }}
                >
                  pending
                </h3>
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-4" style={{paddingRight: '4px', paddingLeft: '4px'}}>
            <div className="card border-0">
              <div
                className="card-body tabs-padding"
                style={{
                  border: "#120c34 3px solid",
                  background: getBackgroundColor("in progress"),
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  cursor: "pointer",
                }}
                onClick={() => handleTabClick("in progress")}
              >
                <h3
                  className="tabs-heading text-center"
                  style={{
                    cursor: "pointer",
                    color: getTextColor("in progress"),
                    fontWeight: 700,
                  }}
                >
                  in progress
                </h3>
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-4" style={{paddingLeft: '4px'}}>
            <div className="card border-0">
              <div
                className="card-body tabs-padding"
                style={{
                  border: "#120c34 3px solid",
                  background: getBackgroundColor("completed"),
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  cursor: "pointer",
                }}
                onClick={() => handleTabClick("completed")}
              >
                <h3
                  className="tabs-heading text-center"
                  style={{
                    cursor: "pointer",
                    color: getTextColor("completed"),
                    fontWeight: 700,
                  }}
                >
                  completed
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row container-fluid m-0"
          style={{ background: "#120c34" }}
        >
          {activeTab === "pending" && (
            <div
              style={{ borderRadius: "25px", overflow: "hidden !important" }}
              className="container-fluid mt-4 bg-white"
            >
              <div className="row">
                <div
                  className="col-md-4 px-0 hide-scrollbar"
                  style={{
                    overflowY: "scroll",
                    height: "70vh",
                    borderRadius: "25px 0 0 25px",
                  }}
                >
                  <CampaignCards onCardSelect={setSelectedCampaign} />
                </div>
                <div className="col-md-8 px-0">
                  <CampaignDetails handleOpen={handleOpen} campaign={selectedCampaign} />
                </div>
              </div>
            </div>
          )}
          {activeTab === "in progress" && (
            <div
              style={{ borderRadius: "25px", overflow: "hidden !important" }}
              className="container-fluid mt-4 bg-white"
            >
              <div className="row">
                <div
                  className="col-md-4 px-0 hide-scrollbar"
                  style={{
                    overflowY: "scroll",
                    height: "70vh",
                    borderRadius: "25px 0 0 25px",
                  }}
                >
                  <CampaignCardsInProgress
                    onCardSelect={setSelectedCampaignP}
                  />
                </div>
                <div className="col-md-8 px-0">
                  <CampaignDetailsProgress campaign={selectedCampaignP} />
                </div>
              </div>
            </div>
          )}
          {activeTab === "completed" &&
          <div
          style={{ borderRadius: "25px", overflow: "hidden !important" }}
          className="container-fluid mt-4 bg-white"
        >
          <div className="row">
            <div
              className="col-md-4 px-0 hide-scrollbar"
              style={{
                overflowY: "scroll",
                height: "70vh",
                borderRadius: "25px 0 0 25px",
              }}
            >
              <CampaignCardsCompleted onCardSelect={setSelectedCampaignP} />
            </div>
            <div className="col-md-8 px-0">
              <CampaignDetailsProgress
                campaign={selectedCampaignP}
              />
            </div>
          </div>
        </div>
          }
        </div>
      </div>
      <ToastContainer />
      <ModalComponent
          type={'requests'}
          show={showEditModal}
          onHide={handleClose}
          bodyClass="edit-body-class"
          bodyContent={
            <EditMarketplaceRequest
              data={selectedCampaign}
              handleClose={handleClose}
              counter={counter}
            />
          }
        />
    </div>
  );
}
