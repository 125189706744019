import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import React from "react";
import "../../App.css"
const EmbassyViewModal = ({
  item = [],
  handleApprove,
  handleReject,
  handleImageError,
  handleClose,
}) => {
  return (
    <div
      className="p-0"
      style={{
        background: "#120c34",
        borderRadius: "25px",
        // margin: "-25px",
      }}
    >
      <div style={{ borderRadius: "25px", overflow: "hidden" }}>
        <div style={{ position: "relative" }}>
          <div
            className="text-center"
            style={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            <button
              type="button"
              className="close"
              onClick={() => handleClose()}
              aria-label="Close"
              style={{
                zIndex: "10000000000",
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "1.5rem",
                color: "#fff",
              }}
            >
              <i
                className="fa-solid fa-xmark fa-2x"
                style={{ color: "white" }}
              ></i>
            </button>
            <img
              className="original-image modal_image_ratio"
              src={item.image}
              alt="User"
              style={{
                maxHeight: "65vh",
                width: "100%", // Make the image scale to full width
                objectFit: "scale-down", // Ensure aspect ratio is preserved
                position: "relative",
                zIndex: 200,
                aspectRatio: '4/3'
              }}
              onError={(event) => handleImageError(event, item.image)}
            />
          </div>
          <div className="blur-effect"></div>
          <div className="shade-effect "></div>
        </div>
      </div>
      <a
        style={{
          fontSize: "1.2rem", // Adjust font size for better scaling
          textDecoration: "none",
          wordBreak: "break-word", // Handle long creator names
        }}
        href={item?.creator_link}
        className="text-white px-3 py-1 decoration-none"
      >
        @{item.creator}
      </a>
      <p
        className="text-white px-3"
        style={{ fontSize: "1rem", wordBreak: "break-word" }} // Add word break for captions
      >
        {item.caption}
      </p>
      {item.status === "Pending" && (
        <div
          style={{ gap: "16px", paddingBottom: "28px" }}
          className="p-3 d-flex justify-content-center align-items-center gap-3 flex-wrap" // Flex-wrap for responsiveness
        >
          <div
          className="d-flex justify-content-center align-items-center"
            style={{ borderRadius: "50%", width: "48px", height: "48px", background: 'red', cursor: 'pointer' }}
            onClick={() => handleReject(item.id)}
          >
            <Icon icon="mingcute:close-fill" width="24" height="24" style={{color: '#fff'}} />
          </div>
          <div
          className="d-flex justify-content-center align-items-center"
            style={{ borderRadius: "50%", width: "48px", height: "48px", background: 'green', cursor: 'pointer' }}
            onClick={() => handleApprove(item.id)}
          >
            <Icon icon="fluent-mdl2:accept-medium" width="24" height="24" style={{color: '#fff'}} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmbassyViewModal;
