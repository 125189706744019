/* eslint-disable default-case */
import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import InfoCard from "./InfoCard";
import "./dashboard_main.css";
import { loadStripe } from "@stripe/stripe-js";
import MultiSelectDropdown from "./Campaign/MultiSelectDropdown";
import Animation_Custom from "./AnimationCustom/Animation_Custom";
import ModalComponent from "../CommonComponents/ModalComponent";
import EmbassyViewModal from "./Dashboad/EmbassyViewModal";
import Errors from "../CommonComponents/Errors";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";

export default class Dashboard_main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataCards: [],
      loading: false,
      page: 1,
      account_type: "2",
      selected_creators: 0,
      isActive: false,
      showForm: false,
      currentStep: 1,
      currentStepAmbassador: 1,
      startDate: "",
      requestType: "",
      Platform: "",
      requestInstructionsText: "",
      WebsiteUrl: "",
      file: null,
      currentItem: null,
      currentIndex: 0,
      count: 0,
      showModal: false,
      PayPerClick: "",
      PayPerCost: "",
      MonthBudget: "",
      fundsError: "",
      url: "",
      Addfunds: "",
      payData: [],
      next: null,
      balance: 0,
      post: 0,
      ambassador: 0,
      clicks: 0,
      status: { value: "ambassador", label: "ambassador" },
      content: [],
      campaigns: [],
      approved: false,
      selectedPhotoOption: "Photos",
      selectedCampaignOption: "Campaigns",
    };
  }
  isLogedin = () => {
    return window.localStorage.getItem("isLogedin"); // Convert string to boolean
  };
  checkAccount = () => {
    if (this.state.account_type == window.localStorage.getItem("account")) {
      // console.log("matched", this.state.account_type, window.localStorage.getItem("account"));
      return true;
    }
  };
  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ approved: isChecked }, () => {
      this.fetchContent(true);
    });
  };

  handleNext = () => {
    if (this.state.currentIndex < this.state.content?.length - 1) {
      this.setState((prev) => ({
        currentIndex: prev.currentIndex + 1,
        currentItem: prev.content[prev.currentIndex + 1],
      }));
    }
  };

  handlePrev = () => {
    if (this.state.currentIndex > 0) {
      this.setState((prev) => ({
        currentIndex: prev.currentIndex - 1,
        currentItem: prev.content[prev.currentIndex - 1],
      }));
    }
  };

  handleSubmit_Ambassador = (event) => {
    event.preventDefault();

    const { PayPerClick, url, MonthBudget, PayPerCost } = this.state;
    const brand_id = this.state.data.id; // Replace with the actual brand ID
    const token = localStorage.getItem("accessToken");

    const apiUrl = `https://api.embassy.io/api/campaign/v1/ambassador/${brand_id}`;

    const requestData = {
      campaign_type: 5,
      budget: MonthBudget,
      details: {
        url: url,
        costPerClick: PayPerClick,
        costPerCost: PayPerCost,
      },
    };

    fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        toast.success("Changes Saved!");
        // Reload the page after successful API execution
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Error!");
      });
  };
  fetchAmbassadorDetails = (id) => {
    const token = localStorage.getItem("accessToken");

    const apiUrl = `https://api.embassy.io/api/campaign/v1/ambassador/${id}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Adjusting the structure to match the actual API response
        const data = responseData.data;

        if (data && data.details) {
          this.setState({
            PayPerClick: data.details.costPerClick,
            url: data.details.url,
            MonthBudget: data.budget,
            PayPerCost: data.details.costPerCost,
          });
        } else {
          console.error("Unexpected API response structure:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching the data:", error);
      });
  };

  truncateAddress(address, wordLimit) {
    const parts = address.split(",");
    if (parts.length === 2) {
      return parts[1].trim();
    }
    const truncatedParts = parts.slice(-2);
    let result = truncatedParts.join(", ").trim();

    result = result.replace(/\b\d{5}(?:-\d{4})?\b/g, "").trim();

    return result;
  }
  formatNumber(number) {
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(1) + "K";
    } else {
      return number.toString();
    }
  }

  handleShow = () => this.setState({ showModal: true });
  handleClose = () => this.setState({ showModal: false });

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value }, () => this.fetchContent(true));
  };

  fetchData = async () => {
    const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
    const headers = new Headers({
      Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/auth/v1/user`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const result = await response.json();
      if (response.ok && result.status === "SUCCESS") {
        this.setState({ data: result.data });
        this.fetchAmbassadorDetails(result.data.id);
      } else {
        if (result.code === "token_not_valid") {
          window.localStorage.removeItem("refreshToken");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("userName");
          window.localStorage.removeItem("isLogedin");
          console.error("Token is invalid or expired");
          window.localStorage.removeItem("refreshToken");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("userName");
          window.localStorage.removeItem("isLogedin");
          window.location.href = "/signin"; // Automatically redirect to /signin
        } else {
          throw new Error("Failed to fetch data");
        }
      }
    } catch (error) {
      this.setState({ error: error.message });
      console.error(error.message);
    }
  };

  fetchDashboardData = async () => {
    const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
    const f_log = localStorage.getItem("f_log");
    if (f_log === "false") {
      this.setState({ currentStepAmbassador: 3 });
    }
    const headers = new Headers({
      Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/general/v1/dashboard`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const result = await response.json();

      if (response.ok && result.status === "SUCCESS") {
        // Update the state with data from the response
        this.setState({
          balance: result.data.user.ambassador_balance,
          post: result.data.post < 0 ? 0 : result.data.post,
          ambassador: result.data.ambassador < 0 ? 0 : result.data.ambassador,
          clicks: result.data.clicks < 0 ? 0 : result.data.clicks,
          error: null,
        });
      } else {
        if (result.code === "token_not_valid") {
          window.localStorage.removeItem("refreshToken");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("userName");
          window.localStorage.removeItem("isLogedin");
          window.localStorage.removeItem("refreshToken");
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("userName");
          window.localStorage.removeItem("isLogedin");
          window.location.href = "/signin"; // Automatically redirect to /signin
        } else {
          throw new Error("Failed to fetch data");
        }
      }
    } catch (error) {
      this.setState({ error: error.message });
      console.error(error.message);
    }
  };

  componentDidMount() {
    this.fetchContent();
    window.addEventListener("scroll", this.handleScroll);
    this.fetchDashboardData();
    this.fetchData();
  }
  handleImageError = (event, videoSrc) => {
    // Create an iframe to replace the failed image (for YouTube video embedding)
    const iframeElement = document.createElement("iframe");
    iframeElement.setAttribute("width", "100%");
    iframeElement.setAttribute("height", "100%");
    iframeElement.setAttribute(
      "src",
      `https://www.youtube.com/embed/g1yirsZQCAE`
    );
    iframeElement.setAttribute("frameborder", "0");
    iframeElement.setAttribute(
      "allow",
      "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    );
    iframeElement.setAttribute(
      "style",
      "height: 65vh; position: relative; z-index: 2;"
    );

    // Replace the image with the iframe (YouTube video)
    event.target.replaceWith(iframeElement);

    // Replace the background image with a video background
    const parentDiv = event.target.closest(".media-container");
    if (parentDiv) {
      parentDiv.style.backgroundImage = "none";

      // Create a background video element
      const backgroundVideoElement = document.createElement("video");
      backgroundVideoElement.setAttribute("autoplay", "false");
      backgroundVideoElement.setAttribute("loop", "true");
      backgroundVideoElement.setAttribute("muted", "true");
      backgroundVideoElement.setAttribute(
        "src",
        `https://www.youtube.com/embed/g1yirsZQCAE`
      ); // Assuming videoSrc is a direct video URL
      backgroundVideoElement.setAttribute(
        "style",
        "position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; z-index: 1; filter: blur(40px);"
      );

      // Insert the background video before any other content in the parentDiv
      parentDiv.insertBefore(backgroundVideoElement, parentDiv.firstChild);
    }
  };

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  nextStep = () => {
    this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }));
  };

  prevStep = () => {
    if (this.state.currentStep === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 1,
      }));
    }
  };

  handleInputFormChange1 = (key, value, e) => {
    e.stopPropagation();
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
    });
  };
  nextStepA = () => {
    this.setState((prevState) => ({
      currentStepAmbassador: prevState.currentStepAmbassador + 1,
    }));
  };

  prevStepA = () => {
    if (this.state.currentStepAmbassador === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState((prevState) => ({
        currentStepAmbassador: prevState.currentStepAmbassador - 1,
      }));
    }
  };
  // currentStepAmbassador

  handleInputChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
    });
  };
  handleInputChangePayperClick = (key, value) => {
    // Check if the value is empty or a valid positive number (including decimals)
    if (value === "" || (Number(value) >= 0 && /^\d*\.?\d{1,4}$/.test(value))) {
      this.setState({ [key]: value });
    }
  };

  handleToggleBTN = () => {
    if (this.state.isActive == true) {
      this.setState({ isActive: false });
    } else {
      this.setState({ isActive: true });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handlePopupClose = () => {
    const popup = document.querySelector(".popup"); // Replace with your popup class or id
    const iframeElement = popup.querySelector("iframe");
    const backgroundVideoElement = popup.querySelector("video");

    // Pause the iframe (YouTube video)
    if (iframeElement) {
      const iframeSrc = iframeElement.src;
      iframeElement.src = ""; // Temporarily remove the src to stop the video
      iframeElement.src = iframeSrc; // Reset the src to restart the video if needed
    }

    // Pause the background video
    if (backgroundVideoElement) {
      backgroundVideoElement.pause();
    }
  };

  encryptData = (payData, secretKey) => {
    // console.log("${process.env.REACT_APP_CRYPTO_ENC_TOKEN}: ",secretKey)
    // return CryptoJS.AES.encrypt(JSON.stringify(payData), secretKey).toString();
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(payData), secretKey, {
      mode: CryptoJS.mode.ECB,
    });
    encrypted = encrypted.toString();
    // console.log('encrypted',encrypted );
    return encrypted;
  };

  onClickSelectedCreators = async (event, userId) => {
    event.stopPropagation();

    // Update the state with the new selected_creators count
    this.setState(
      (prevState) => ({
        selected_creators: event.target.checked
          ? prevState.selected_creators + 1
          : prevState.selected_creators - 1,
      }),
      () => {
        // console.log(this.state.selected_creators); // This logs the updated state value

        // Proceed with the API call after state update
        const token = localStorage.getItem("accessToken");
        if (!token) {
          console.error("Access token not found");
          return;
        }

        const url = `${process.env.REACT_APP_BASE_URL}api/general/v1/favorite`;
        const headers = new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        });

        const body = JSON.stringify({ creator: userId });

        // Call the API
        fetch(url, {
          method: "POST",
          headers: headers,
          body: body,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error status: ${response.status}`);
            }
            return response.json(); // Parse the JSON response
          })
          .then((data) => {
            // console.log(data); // Handle successful response
            toast.success("Added to favorite! ");
          })
          .catch((error) => {
            console.error("Error calling API:", error);
          });
      }
    );
  };

  handleCheckout = async (event) => {
    event.preventDefault();
    try{
      if (this.state.Addfunds) {
        const stripePromise = loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        const stripe = await stripePromise;
        const isLogedin = window.localStorage.getItem("accessToken");
  
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}api/auth/v1/checkout`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${isLogedin}`,
              },
              body: JSON.stringify({
                amount: parseFloat(this.state.Addfunds),
                wallet: "ambassador",
              }), // Convert to cents
            }
          );
  
          const {
            data: { id: sessionId },
          } = await response.json();
  
          if(sessionId){
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
              console.error("Error:", error);
            }
          }
          else {
            toast.error("Unable to connect to payment portal! Try again later.")
            setTimeout(()=> {
              window.location.reload();
            }, 2000)
          }
        } catch (error) {
          console.error("Checkout error:", error);
        }
      } else {
        this.setState({ fundsError: "funds is required" });
        setTimeout(() => {
         window.location.reload();
        }, 2000);
      }
    }
    catch(error){
      console.error("Error calling payment API:", error);
      window.location.reload();
    }
  };

  renderAmbassdorProgramne() {
    const { currentStepAmbassador, PayPerClick, url, MonthBudget, PayPerCost } =
      this.state;
    // currentStepAmbassador
    switch (currentStepAmbassador) {
      case 1:
        return (
          <>
            <div className="container-fluid">
              <h5
                className="text-center set_font_mainPage mt-3"
                style={{ color: "#120c34" }}
              >
                Welcome to Embassy!
              </h5>
              <h4 className="text-center " style={{ color: "#120c34" }}>
                Embassy allow you to grow effortlessly by advertising through
                your brand community.
              </h4>

              <img className="img-fluid" src="/images/Popup.png" alt="" />
              <div className="row">
                <div
                  className="col-12 pr-5"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <h4
                    className="mt-5"
                    onClick={this.nextStepA}
                    style={{ cursor: "pointer" }}
                  >
                    next <i class="fa-solid fa-right-long"></i>{" "}
                  </h4>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="container-fluid">
              <div>
                <h5
                  className="text-center set_font_mainPage "
                  style={{ color: "#120c34" }}
                >
                  explore features
                </h5>
                <h4 className="text-center mt-3" style={{ color: "#120c34" }}>
                  brand embassy
                </h4>
                <div className="card border border-0">
                  <div
                    className="card-body p-2"
                    style={{
                      border: "1px solid #120c34",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className=" text-center" style={{ color: "#120c34" }}>
                      your brand embassy hosts an ongoing ambassador through
                      which your customer are paid per click for traffic they
                      drive to your website. <br />
                      just add funds they'll do the rest.{" "}
                    </h4>
                  </div>
                </div>
              </div>
              {/*  */}
              <div>
                <h4 className="text-center mt-3" style={{ color: "#120c34" }}>
                  campaigns
                </h4>
                <div className="card border border-0">
                  <div
                    className="card-body p-2"
                    style={{
                      border: "1px solid #120c34",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className=" text-center" style={{ color: "#120c34" }}>
                      Create custom campaigns, in which target participamnts are
                      compensated for genrating either content or driving
                      traffic for your brand.{" "}
                    </h4>
                  </div>
                </div>
              </div>
              {/*  */}
              <div>
                <h4 className="text-center mt-3" style={{ color: "#120c34" }}>
                  marketplace
                </h4>
                <div className="card border border-0">
                  <div
                    className="card-body p-2"
                    style={{
                      border: "1px solid #120c34",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className=" text-center" style={{ color: "#120c34" }}>
                      Submit requests directly to specific creator.
                    </h4>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4">
                <Link
                  onClick={this.nextStepA}
                  className="btn btn-lg"
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    Get Started
                  </span>
                </Link>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div
              className="text-center"
              style={{ 
                // maxHeight: "80dvh",
                 overflowY: "auto" }}
            >
              <div className="d-flex align-items-baseline justify-content-center">
                {" "}
                <p
                  className="text-center set_font_mainPage me-1"
                  style={{ color: "#120c34" }}
                >
                  brand embassy{" "}
                </p>
                <InfoCard infoText="your brand Embassy is an ongoing program in which your ambassadors are compensated per click for traffic they drive to a custom url." />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="card p-0"
                  style={{
                    width: "90%",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                  }}
                >
                  <div className="d-flex flex-column h-100">
                    <div className="row p-2 flex-grow-1">
                      <div className="col-6 col-lg-6 col-sm-12 p-lg-2 text-center text-white">
                        <h4
                          className="my-2 name_font"
                          style={{ fontWeight: "600" }}
                        >
                          ${this.state.balance}
                        </h4>
                        <div
                          style={{
                            width: "100%",
                            height: "3px",
                            background: "white",
                          }}
                        ></div>
                        <h5
                          className="my-2 name_font"
                          style={{ fontWeight: "800" }}
                        >
                          remaining budget
                        </h5>
                      </div>
                      <div className="col-6 col-lg-6 col-sm-12 pt-sm-4 pt-lg-0 d-flex align-items-center justify-content-center">
                        <button
                          type="button"
                          className="btn btn-light px-4 mr-3 manage_button"
                          style={{ borderRadius: "25px" }}
                          onClick={this.nextStepA}
                        >
                          add funds
                        </button>
                        {/* <Link type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} to="/make-payment">Manage</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <form
                onSubmit={this.handleSubmit_Ambassador}
                style={{ marginTop: "2rem" }}
              >
                <div className="form-field-container">
                  <div className="col-md-6 text-center">
                    <p
                      className="text-center manage-form-label"
                      style={{ marginBottom: "4px" }}
                    >
                      payment per click{" "}
                      <InfoCard infoText="This is the amount that ambassdor paid for each click they drive to your website." />{" "}
                    </p>
                    <div className="input_wrapper">
                      <Icon
                        icon="bx:dollar"
                        width="24"
                        height="24"
                        style={{ color: "#525252" }}
                      />
                      <input
                        type="number"
                        // value={PayPerClick}
                        // onChange={(e) => {
                        //   const value = e.target.value;
                        //   // Allow empty value or positive numbers including decimals
                        //   // if (value === "" || (!isNaN(value) && Number(value) > 0)) {
                        //     // this.handleDelayedInputChange(
                        //     //   "PayPerClick", 
                        //     //   value
                        //     // );
                        //   // }
                        // }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!isNaN(value) && Number(value) > 0) {
                            this.handleInputChangePayperClick(
                              "PayPerClick", 
                              e.target.value
                            );
                          }
                          else{
                            e.target.value= ""
                            this.setState({ PayPerClick: "" });
                          }
                        }}
                        className="input_font_size"
                        style={{
                          "&:focus-visible": {
                            border: "0px",
                            outline: "none",
                          },
                          "&:focus": {
                            border: "0px", 
                            outline: "none",
                          },
                          border: "0px",
                          outline: "none",
                          textAlign: "center",
                          width: "50%",
                        }}
                        placeholder="0.50"
                        min="0.01"
                        step="0.01"
                      />
                      <div className="input_font_size">/Click</div>
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <p
                      className="text-center manage-form-label"
                      style={{ marginBottom: "4px" }}
                    >
                      payment per post{" "}
                      <InfoCard infoText="This is the amount that the participant will be paid for each piece of content they create and you approve." />
                    </p>
                    <div className="input_wrapper">
                      <Icon
                        icon="bx:dollar"
                        width="24"
                        height="24"
                        style={{ color: "#525252" }}
                      />
                      <input
                        type="number"
                        // value={PayPerCost}
                        // onChange={(e) => {
                        //   const value = e.target.value;
                        //   if (value === "" || (!isNaN(value) && Number(value) > 0)) {
                        //     this.handleInputChangePayperClick(
                        //       "PayPerCost",
                        //       value
                        //     );
                        //   }
                        // }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!isNaN(value) && Number(value) > 0) {
                            this.handleInputChangePayperClick(
                              "PayPerCost", 
                              e.target.value
                            );
                          }
                          else{
                            e.target.value= ""
                            this.setState({ PayPerCost: "" });
                          }
                        }}
                        className="input_font_size"
                        style={{
                          "&:focus-visible": {
                            border: "0px",
                            outline: "none",
                          },
                          "&:focus": {
                            border: "0px",
                            outline: "none",
                          },
                          border: "0px",
                          outline: "none",
                          textAlign: "center",
                          width: "50%",
                        }}
                        placeholder="0.50"
                        min="0.01"
                        step="0.01"
                      />
                      <div className="input_font_size">/Click</div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="col-md-12 mt-3 text-center">
                    <p
                      className="text-center manage-form-label"
                      style={{ marginBottom: "4px" }}
                    >
                      url{" "}
                      <InfoCard infoText="This is url to which ambassadors will drive traffic." />{" "}
                    </p>
                    <div
                      className="input_wrapper"
                    >
                      <input
                        type="text"
                        value={url}
                        onChange={(e) =>
                          this.handleInputChange("url", e.target.value)
                        }
                        className="input_font_size"
                        style={{
                          "&:focus-visible": {
                            border: "0px",
                            outline: "none",
                          },
                          "&:focus": {
                            border: "0px",
                            outline: "none",
                          },
                          border: "0px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        placeholder="www.customdomain.com"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="col-md-12 mt-3 text-center">
                    <p
                      className="text-center manage-form-label"
                      style={{ marginBottom: "4px" }}
                    >
                      monthly budget{" "}
                      <InfoCard infoText="At the beginning of each month, your embassy will automatically refill back to your monthly budget." />
                    </p>
                    <div
                      className="input_wrapper"
                      style={{ maxWidth: "calc(max(50%, 320px))" }}
                    >
                      <Icon
                        icon="bx:dollar"
                        width="24"
                        height="24"
                        style={{ color: "#525252" }}
                      />
                      <input
                        type="number"
                        value={MonthBudget}
                        onChange={(e) =>
                          this.handleInputChange("MonthBudget", e.target.value)
                        }
                        className="input_font_size"
                        style={{
                          "&:focus-visible": {
                            border: "0px",
                            outline: "none",
                          },
                          "&:focus": {
                            border: "0px",
                            outline: "none",
                          },
                          border: "0px",
                          outline: "none",
                          textAlign: "center",
                          width: "50%",
                        }}
                        placeholder="10,000"
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <button
                    className="btn btn-lg"
                    style={{
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      save changes
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="container-fluid text-center">
              <div className="row">
                <div className="col-2">
                  <button
                    className="bg-transparent border-0 cursor-pointer d-flex align-items-center"
                    onClick={this.prevStepA}
                  >
                    <i
                      className="fa fa-arrow-left mr-1"
                      style={{ fontSize: "13px" }}
                    ></i>
                    back
                  </button>
                </div>
              </div>

              <h5
                className="text-center set_font_mainPage mb-4"
                style={{ color: "#120c34" }}
              >
                add funds
              </h5>
              <div className="text-center fs-md-2 mt-3 name_font" style={{ color: "#120c34" }}>
                how much would you like to add?
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-1 text-center">
              <div className="input_wrapper">
                      <Icon
                        icon="bx:dollar"
                        width="24"
                        height="24"
                        style={{ color: "#525252" }}
                      />
                      <input
                        type="number"
                        onChange={(e) =>
                          this.handleInputChangePayperClick(
                            "Addfunds",
                            e.target.value
                          )
                        }
                        className="input_font_size"
                        style={{
                          "&:focus-visible": {
                            border: "0px",
                            outline: "none",
                          },
                          "&:focus": {
                            border: "0px",
                            outline: "none",
                          },
                          border: "0px",
                          outline: "none",
                          textAlign: "center",
                          width: "50%",
                        }}
                        placeholder="add funds"
                        min="100"
                        value={this.state.Addfunds}
                      />
                      </div>
                <Errors msg={this.state?.fundsError} mid={true} />
              </div>
            </div>
            {/* <h5 className="text-center mt-3 name_font" style={{ color: "#120c34" }}>
              Save up to $800 by{" "}
              <Link style={{ color: "#90b5e9" }} onClick={this.nextStepA}>
                Subscribe today
              </Link>
            </h5> */}
            <h4 className="text-center mt-5 name_font" style={{ color: "#120c34" }}>
             <span style={{fontWeight: 600}}>Note: </span> <br />
              These funds will be added to your budget, but are only spent as
              participants genrate clicks.
            </h4>

            <div className="text-center mt-4">
              <button
                onClick={this.handleCheckout}
                className="btn btn-lg"
                style={{
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  // fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    // backgroundColor: '#3898ec',
                    border: "0",
                    borderRadius: "0",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  continue to payment
                </span>
              </button>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="container-fluid">
              <h5
                className="text-center set_font_mainPage mt-3"
                style={{ color: "#120c34" }}
              >
                Limited offer
              </h5>
              <h4 className="text-center " style={{ color: "#120c34" }}>
                Subscribe today and receive 30 days free and $100 towards your
                brand embassay ambassador progam.
              </h4>
              <h5
                className="text-center set_font_mainPage mt-3"
                style={{ color: "#120c34" }}
              >
                Subscription
              </h5>
              <div className="table-responsive p-0 mx-0">
                <table
                  className="table"
                  style={{
                    borderTop: "2px solid #120c34",
                    borderBottom: "2px solid #120c34",
                  }}
                >
                  <tbody>
                    <tr style={{ borderTop: "2px solid #120c34" }}>
                      <th>
                        <h4 className="text-center">Tier</h4>
                      </th>
                      <th>
                        <h4 className="text-center">Feature</h4>
                      </th>
                      <th>
                        <h4 className="text-center">Price</h4>
                      </th>
                    </tr>

                    <tr
                      style={{
                        borderTop: "2px solid #120c34",
                        borderBottom: "2px solid #120c34",
                        borderCollapse: "separate",
                        borderSpacing: "0",
                      }}
                    >
                      <td>
                        <h5>Micro</h5>
                      </td>
                      <td className="text-center">
                        <h5>
                          brand embassy : 15% transaction fee <br /> campaign :
                          15% transaction fee <br />
                          marketplace : unlock creator with less then 100k
                          followers
                        </h5>
                      </td>
                      <td>
                        <button
                          style={{
                            borderRadius: "25px",
                            border: "2px solid #120c33",
                            padding: "5px 10px",
                            backgroundColor: "transparent",
                            color: "#120c33",
                            cursor: "pointer",
                          }}
                          className="btn btn-lg "
                        >
                          $99/month
                        </button>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "2px solid #120c34",
                        borderBottom: "2px solid #120c34",
                      }}
                    >
                      <td>
                        <h5>Macro</h5>
                      </td>
                      <td className="text-center">
                        <h5>
                          brand embassy : 10% transaction fee <br /> campaign :
                          10% transaction fee <br />
                          marketplace : Unlock creator with less then 10M
                          followers
                        </h5>
                      </td>
                      <td>
                        <button
                          style={{
                            borderRadius: "25px",
                            border: "2px solid #120c33",
                            padding: "5px 10px",
                            backgroundColor: "transparent",
                            color: "#120c33",
                            cursor: "pointer",
                          }}
                          className="btn btn-lg "
                        >
                          $249/month
                        </button>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "2px solid #120c34",
                        borderBottom: "2px solid #120c34",
                      }}
                    >
                      <td>
                        <h5>Mega</h5>
                      </td>
                      <td className="text-center">
                        <h5>
                          brand embassy : 5% transaction fee <br /> campaign :
                          5% transaction fee <br />
                          marketplace : unlock all creators
                        </h5>
                      </td>
                      <td>
                        <button
                          style={{
                            borderRadius: "25px",
                            border: "2px solid #120c33",
                            padding: "5px 10px",
                            backgroundColor: "transparent",
                            color: "#120c33",
                            cursor: "pointer",
                          }}
                          className="btn btn-lg "
                        >
                          $999/month
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-center mt-3">
                <button
                  style={{
                    borderRadius: "25px",
                    border: "2px solid #120c34",
                    padding: "10px 20px",
                    backgroundColor: "transparent",
                    color: "#120c34",
                    cursor: "pointer",
                    fontSize: "x-large",
                    fontWeight: "bold",
                  }}
                  className="btn"
                >
                  Decline offer
                </button>
              </div>
            </div>
          </>
        );
    }
  }

  renderForm() {
    const {
      currentStep,
      startDate,
      requestType,
      selectedLogo,
      requestInstructions,
      url,
      file,
      count,
    } = this.state;
    switch (currentStep) {
      case 1:
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
            }}
          >
            <ul id="progressbar">
              <li
                className="active"
                id="step1"
              >
                <strong></strong>
              </li>
              <li id="step2">
                <strong></strong>
              </li>
              <li id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            {/* <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required /> */}
            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              request start date
            </h3>
            <input
              type="text"
              onChange={(e) =>
                this.handleInputChange("showDate", e.target.value)
              }
              name="startDate"
              className="input-box text-center rounded-pill mx-3"
              style={{
                fontWeight: "800",
                fontSize: "x-large",
                backgroundColor: "white",
                width: "50%",
                padding: "15px",
                borderRadius: "25px",
                color: "#120c34",
              }}
              placeholder="xx/xx/xxx"
            />

            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              request type
            </h3>
            <div className="row mt-3">
              <div className="col-6 col-sm-6">
                <input
                  onClick={(e) =>
                    this.handleInputChange("requestType", e.target.value)
                  }
                  type="button"
                  className=" rounded-pill mx-3 selected"
                  style={{
                    color:
                      this.state.requestType === "Posted Content"
                        ? "#120c34"
                        : "white",
                    border: "2px solid white",
                    fontSize: "x-large",
                    backgroundColor:
                      this.state.requestType === "Posted Content"
                        ? "white"
                        : "transparent",
                    width: "60%",
                    padding: "15px",
                    borderRadius: "25px",
                  }}
                  value="Posted Content"
                />
              </div>
              <div className="col-6 col-sm-6">
                <input
                  onClick={(e) =>
                    this.handleInputChange("requestType", e.target.value)
                  }
                  type="button"
                  className=" rounded-pill mx-3 "
                  style={{
                    color:
                      this.state.requestType === "UGC" ? "#120c34" : "white",
                    border: "2px solid white",
                    fontSize: "x-large",
                    backgroundColor:
                      this.state.requestType === "UGC"
                        ? "white"
                        : "transparent",
                    width: "50%",
                    padding: "15px",
                    borderRadius: "25px",
                  }}
                  value="UGC"
                />
              </div>
            </div>

            <div className="text-center  ">
              <h3 className="mt-5" style={{ fontWeight: "800" }}>
                platform
              </h3>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo1">
                      <img
                        src="/images/instagram.png"
                        alt="Instagram Logo"
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputChange("Platform", e.target.value)
                        }
                        id="logo1"
                        name="logo"
                        value="instagram"
                      />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo2">
                      <img
                        src="/images/tiktok.png"
                        alt="TikTok Logo"
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputChange("Platform", e.target.value)
                        }
                        id="logo2"
                        name="logo"
                        value="tiktok"
                      />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo3">
                      <img
                        src="/images/youtube.png"
                        alt="YouTube Logo"
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputChange("Platform", e.target.value)
                        }
                        id="logo3"
                        name="logo"
                        value="youtube"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 pb-3">
              <div className="col-6 col-sm-6">
                <input
                  type="button"
                  onClick={this.prevStep}
                  className=" rounded-pill mx-3 bg-transparent text-white"
                  style={{
                    border: "2px solid white",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    backgroundColor: "",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  value="cancel"
                />
              </div>
              <div className="col-6 col-sm-6">
                <button
                  className="btn btn-lg form-button"
                  onClick={this.nextStep}
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    continue
                  </span>
                </button>
              </div>
            </div>
          </div>
        );
      case 2:
        const maxLength = 400;
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
            }}
          >
            <ul id="progressbar">
              <li className="" id="step1">
                <strong></strong>
              </li>
              <li className="active" id="step2">
                <strong></strong>
              </li>
              <li id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              request instruction
            </h3>

            <div className=" mx-5">
              <div
                className=" position-relative"
                style={{ marginBottom: "20px" }}
              >
                <textarea
                  onChange={(e) =>
                    this.handleInputChange(
                      "requestInstructionsText",
                      e.target.value
                    )
                  }
                  className="form-control"
                  style={{
                    height: "20em",
                    borderRadius: "25px",
                    resize: "none",
                    fontSize: "18px",
                  }}
                  placeholder="Tell the creators what you'd like to post"
                  maxLength={maxLength}
                />
                <div
                  className="position-absolute"
                  style={{
                    bottom: "5px",
                    right: "10px",
                    fontSize: "12px",
                    color: "gray",
                  }}
                >
                  {/* Placeholder text, since we're not managing state here */}
                  <b
                    style={{ color: "#120c34" }}
                  >{`${maxLength}/${maxLength} characters remaining`}</b>
                </div>
              </div>
            </div>

            <div
              className="card mx-5 mt-2 px-2"
              style={{ borderRadius: "25px" }}
            >
              <div className="d-flex align-items-center">
                <h4 className="my-2" style={{ color: "#120c34" }}>
                  <b>URL:</b>
                </h4>
                <input
                  onChange={(e) =>
                    this.handleInputChange("WebsiteUrl", e.target.value)
                  }
                  className="border  border-0 form-control"
                  style={{ border: "0", borderRadius: "25px" }}
                  type="text"
                  placeholder="yourwebiste.com"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>

            <div
              className="card mx-5 mt-3 px-2"
              style={{ borderRadius: "25px" }}
            >
              <div className="d-flex">
                <h4 className="my-2 " style={{ color: "#120c34" }}>
                  <b>attachment (optinal):</b>
                </h4>
                <input
                  type="file"
                  id="fileInput"
                  style={{ margin: "10px 0", display: "none" }}
                />
                <label
                  htmlFor="fileInput"
                  className=""
                  style={{ cursor: "pointer", color: "#120c34" }}
                >
                  <div className="d-flex align-items-center gap-3">
                    <i
                      className="fa fa-upload my-2 me-2"
                      style={{ fontSize: "1.5em" }}
                    ></i>
                    <h4 className="my-2">Click to Upload</h4>
                  </div>
                </label>
              </div>
            </div>

            <div className="row mt-5 pb-3">
              <div className="col-md-6">
                <input
                  type="button"
                  onClick={this.prevStep}
                  className=" rounded-pill mx-3 bg-transparent text-white"
                  style={{
                    border: "2px solid white",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    backgroundColor: "",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  value="back"
                />
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-lg form-button"
                  onClick={this.nextStep}
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      // backgroundColor: '#3898ec',
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    continue
                  </span>
                </button>
              </div>
            </div>
          </div>
        );
      case 3:
        const increment = () => {
          const input = document.getElementById("countInput");
          let currentValue = parseInt(input.value, 10);
          input.value = currentValue + 1;
        };

        const decrement = () => {
          const input = document.getElementById("countInput");
          let currentValue = parseInt(input.value, 10);
          input.value = currentValue - 1;
        };
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
            }}
          >
            <ul id="progressbar">
              <li className="" id="step1" >
                <strong></strong>
              </li>
              <li className="" id="step2">
                <strong></strong>
              </li>
              <li className="active" id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              <b>select deliverable</b>
            </h3>

            <div className="container-fluid mt-3">
              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className="my-3" style={{ fontWeight: "600" }}>
                    in-feed photo post{" "}
                  </h2>
                </div>

                <div className="col-md-6">
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{
                      width: "70%",
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={decrement}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent"
                      id="countInput"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={increment}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className="my-3" style={{ fontWeight: "600" }}>
                    in-feed photo post{" "}
                  </h2>
                </div>

                <div className="col-md-6">
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{
                      width: "70%",
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={decrement}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent"
                      id="countInput"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={increment}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className="my-3" style={{ fontWeight: "600" }}>
                    in-feed photo post{" "}
                  </h2>
                </div>

                <div className="col-md-6">
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{
                      width: "70%",
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={decrement}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent"
                      id="countInput"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={increment}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className="my-3" style={{ fontWeight: "600" }}>
                    in-feed photo post{" "}
                  </h2>
                </div>

                <div className="col-md-6">
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{
                      width: "70%",
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    <i
                      className="fas fa-minus"
                      onClick={decrement}
                      style={{
                        fontSize: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                    <input
                      className="bg-transparent"
                      id="countInput"
                      type="text"
                      defaultValue="0"
                      readOnly
                      style={{
                        width: "50%",
                        textAlign: "center",
                        border: "0",
                        fontWeight: "600",
                      }}
                    />
                    <i
                      className="fas fa-plus"
                      onClick={increment}
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
              </div>

              <h3 className="mt-3" style={{ fontWeight: "800" }}>
                <b>select deliverable</b>
              </h3>

              <div className="row mt-3">
                <div className="col-md-5">
                  <div
                    className="dropdown show"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    {/* <a className="btn btn-light bg-white dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a> */}
                    <div
                      className="card border-0 text-center p-0"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-center ">
                        <h3
                          className="dropdown-toggle"
                          style={{ fontWeight: "600" }}
                        >
                          1 post
                        </h3>
                      </div>
                    </div>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <h2 className="my-3" style={{ fontWeight: "600" }}>
                    per{" "}
                  </h2>
                </div>
                <div className="col-md-5">
                  <div
                    className="dropdown show"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #ccc",
                      padding: "20px",
                      background: "white",
                      color: "#120c34",
                    }}
                  >
                    {/* <a className="btn btn-light bg-white dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a> */}
                    <div
                      className="card border-0 text-center p-0"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-center ">
                        <h3
                          className="dropdown-toggle"
                          style={{ fontWeight: "600" }}
                        >
                          1 post
                        </h3>
                      </div>
                    </div>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-md-6">
                  <input
                    type="button"
                    onClick={this.prevStep}
                    className=" rounded-pill mx-3 bg-transparent text-white"
                    style={{
                      border: "2px solid white",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      backgroundColor: "",
                      padding: "15px",
                      borderRadius: "25px",
                      color: "#120c34",
                    }}
                    value="back"
                  />
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-lg form-button"
                    onClick={this.nextStep}
                    style={{
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      continue
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
            }}
          >
            <ul id="progressbar">
              <li className="" id="step1" >
                <strong></strong>
              </li>
              <li className="" id="step2">
                <strong></strong>
              </li>
              <li className="" id="step3">
                <strong></strong>
              </li>
              <li className="active" id="step4">
                <strong></strong>
              </li>
              <li id="step5">
                <strong></strong>
              </li>
            </ul>
            <h3 className="mt-5" style={{ fontWeight: "800" }}>
              <b>select deliverable</b>
            </h3>
            <input
              type="button"
              className="btn btn-primary rounded-pill mx-3"
              style={{
                fontWeight: "800",
                fontSize: "x-large",
                backgroundColor: "white",
                width: "50%",
                padding: "15px",
                borderRadius: "25px",
                color: "#120c34",
              }}
              defaultValue="$2,000"
            />

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              product included
            </h3>
            <div className="row mt-3">
              <div className="col-md-6">
                <input
                  type="button"
                  className=" rounded-pill mx-3 selected"
                  style={{
                    fontWeight: "800",
                    fontSize: "x-large",
                    backgroundColor: "white",
                    width: "50%",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  defaultValue="Yes"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="button"
                  className=" rounded-pill mx-3 bg-transparent text-white"
                  style={{
                    fontWeight: "800",
                    border: "2px solid white",
                    fontSize: "x-large",
                    backgroundColor: "",
                    width: "50%",
                    padding: "15px",
                    borderRadius: "25px",
                    color: "#120c34",
                  }}
                  defaultValue="No"
                />
              </div>
            </div>

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              product URL
            </h3>
            <div
              className="card mx-5 mt-2 px-2"
              style={{ borderRadius: "25px" }}
            >
              <div className="row align-items-center">
                <div className="col-md-12">
                  {/* <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4> */}
                  <input
                    className="border  border-0 form-control"
                    style={{
                      fontWeight: "800",
                      fontSize: "x-large",
                      border: "0",
                      borderRadius: "25px",
                    }}
                    type="text"
                    placeholder="yourwebiste.com"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              <b>product value</b>
            </h3>
            <input
              type="number"
              className="text-center input-box rounded-pill mx-3"
              style={{
                fontWeight: "800",
                fontSize: "x-large",
                backgroundColor: "white",
                width: "50%",
                padding: "15px",
                borderRadius: "25px",
                color: "#120c34",
              }}
              placeholder="$0"
            />

            <div className="container">
              <div className="row mt-5 pb-3">
                <div className="col-md-6">
                  <input
                    type="button"
                    onClick={this.prevStep}
                    className=" rounded-pill mx-3 bg-transparent text-white"
                    style={{
                      fontWeight: "800",
                      fontSize: "x-large",
                      border: "2px solid white",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      backgroundColor: "",
                      padding: "15px",
                      borderRadius: "25px",
                      color: "#120c34",
                    }}
                    defaultValue="back"
                  />
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-lg form-button"
                    onClick={this.nextStep}
                    style={{
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                        fontWeight: "800",
                        fontSize: "x-large",
                      }}
                    >
                      continue
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div
            className="text-center"
            style={{
              background: "#120c34",
              width: "100%",
              borderRadius: "25px",
            }}
          >
            <ul id="progressbar">
              <li className="" id="step1" >
                <strong></strong>
              </li>
              <li className="" id="step2">
                <strong></strong>
              </li>
              <li className="" id="step3">
                <strong></strong>
              </li>
              <li id="step4">
                <strong></strong>
              </li>
              <li className="active" id="step5">
                <strong></strong>
              </li>
            </ul>

            <h3 className="mt-3" style={{ fontWeight: "800" }}>
              <b>select deliverable</b>
            </h3>

            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="row text-center">
                    <div className="col-md-12 mb-1 text-center">
                      <h5 className="text-center" style={{ fontWeight: "800" }}>
                        <b>select deliverable</b>
                      </h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-2">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12 mb-1 text-center">
                      <h5 className="text-center" style={{ fontWeight: "800" }}>
                        <b>select deliverable</b>
                      </h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div
                        className="card align-items-center "
                        style={{ borderRadius: "25px" }}
                      >
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 mb-1 text-center">
                    <h5 className="text-center" style={{ fontWeight: "800" }}>
                      <b>select deliverable</b>
                    </h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <textarea
                        className="pt-2"
                        style={{
                          textAlign: "center",
                          borderRadius: "25px",
                          fontSize: "large", // Note: Adjusted to use a string value instead of "large"
                          border: "none", // Adjusted to use 'none' instead of "0"
                          fontWeight: "800",
                          width: "100%",
                          color: "#120c34", // Applied to the textarea itself
                          resize: "none", // Prevents the user from resizing the textarea
                          overflow: "hidden", // Ensures the text does not overflow the textarea
                          height: "150px", // Equivalent to 5 rows of text (assuming an average line height)
                        }}
                        rows={5}
                      >
                        text here
                      </textarea>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <h5 className="my-2" style={{ color: "#120c34" }}>
                          <b>URL:</b>
                        </h5>
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center gap-3">
                        <i
                          className="fa fa-upload my-2 me-2"
                          style={{ fontSize: "1.5em", color: "#120c34" }}
                        ></i>
                        <h4 className="my-2" style={{ color: "#120c34" }}>
                          Click to Upload
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 text-center">
                    <h5 className="text-center" style={{ fontWeight: "800" }}>
                      <b>select deliverable</b>
                    </h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div
                      className="card align-items-center "
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <p
                          className="pt-2"
                          style={{
                            textAlign: "center",
                            fontSize: "large",
                            border: "0",
                            fontWeight: "800",
                            color: "#120c34",
                          }}
                        >
                          text here
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-md-6">
                  <input
                    type="button"
                    onClick={this.prevStep}
                    className=" rounded-pill mx-3 bg-transparent text-white"
                    style={{
                      fontWeight: "800",
                      fontSize: "x-large",
                      border: "2px solid white",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      backgroundColor: "",
                      padding: "15px",
                      borderRadius: "25px",
                      color: "#120c34",
                    }}
                    defaultValue="back"
                  />
                </div>
                <div className="col-md-6">
                  <button
                    type="submit"
                    className="btn btn-lg form-button"
                    style={{
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                        fontWeight: "800",
                        fontSize: "x-large",
                      }}
                    >
                      Submit
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  fetchContent = async (reset = false) => {
    if (this.state.loading) return;
    const isLogedin = window.localStorage.getItem("accessToken");
    const query = this.state.approved ? "&approved=true" : "";
    const statusValue = typeof this.state.status === 'object' ? this.state.status.value : this.state.status;
    const statusQuery = statusValue ? `&type=${statusValue}` : ""; // Ensure statusValue is used correctly

    this.setState({ loading: true });
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content?page=${
        reset ? 1 : this.state.page
      }&page_size=10${query}${statusQuery}`,
      {
        headers: {
          Authorization: `Bearer ${isLogedin}`,
        },
      }
    );
    if (reset) this.setState({ page: 1 });

    const result = await response.json();
    if (result.status === "SUCCESS") {
      if (reset) {
        this.setState({
          content: result.data.results,
        });
      } else {
        // this.setState({ content: result.data.results });
        this.setState((prevState) => {
          // Check if the last item of the current content is the same as the last item of the new data
          const lastCurrentItem =
            prevState.content[prevState.content.length - 1];
          const lastNewItem =
            result.data.results[result.data.results.length - 1];

          // Compare the last items by relevant properties (e.g., id, title, body)
          const isSame =
            lastCurrentItem?.id === lastNewItem?.id &&
            lastCurrentItem?.title === lastNewItem?.title &&
            lastCurrentItem?.body === lastNewItem?.body;
          // If the last items are not the same, append the new data
          if (!isSame) {
            return {
              content: [...prevState.content, ...result.data.results],
            };
          }

          // Return the previous state if the last items are the same
          return prevState;
        });
      }
      this.setState({ next: result.data.next });
    }
    this.setState({ loading: false });
  };

  handleScroll = () => {
    const { isLoading, next } = this.state;

    // Calculate the scroll position
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;

    // Check if the user has reached the bottom of the page
    const bottom = scrollPosition >= scrollHeight - 5; // Allow a small tolerance (5px)

    // If we're at the bottom, not loading, and there's a next page to load
    if (bottom && !isLoading && next) {
      this.setState(
        (prevState) => ({ page: prevState.page + 1 }),
        () => this.fetchContent() // Fetch data after page state update
      );
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchCampaigns = async () => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/dropdown-campaign?campaign_type=3,4`,
      {
        headers: {
          Authorization: `Bearer ${isLogedin}`,
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      this.setState({ campaigns: result.data });
    }
  };
  handleApprove = async (contentId) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/approve/${contentId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${isLogedin}`,
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      // Handle success (e.g., update the state or show a notification)
      // console.log('Content approved successfully');
      toast.success("Content approved! ");
      this.fetchContent(true);
      this.handleClose();
    } else if (
      result.status === "FAILURE" &&
      result.message === "Content action previously taken"
    ) {
      toast.error("You have already taken an action on this content");
    } else {
      // Handle error
      console.error("Error approving content");
    }
  };

  handleReject = async (contentId) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/reject/${contentId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${isLogedin}`,
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      // Handle success (e.g., update the state or show a notification)
      // console.log('Content rejected successfully');
      toast.error("Content Rejected! ");
      this.fetchContent(true);
      this.handleClose();
    } else if (
      result.status === "FAILURE" &&
      result.message === "Content action previously taken"
    ) {
      toast.error("You have already taken an action on this content");
    } else {
      // Handle error
      console.error("Error rejecting content");
    }
  };

  renderModalContent(user) {
    if (this.state.showForm) {
      // return <form>{this.renderForm()}</form>;
      return (
        <form onSubmit={this.handleSubmit}>
          {/* <input type="text" onChange={e => this.handleInputFormChange1('userId', user.id, e)} style={{display:"none"}} /> */}
          {this.renderForm()}
        </form>
      );
    } else {
      return (
        <div style={{ borderRadius: "25px" }}>
          {/* <img src={user.images && user.images.length > 0 ? user.images[0] : "/images/person.png"} className='' alt="" style={{ width: "100%", height: "64vh", borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }} /> */}
          <div style={{ position: "relative" }}>
            <div
              className="text-center"
              style={{ position: "relative", overflow: "hidden" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{
                  zIndex: "10000000000",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: "1.5rem",
                  color: "#fff",
                }}
              >
                <i
                  class="fa-solid fa-xmark fa-2x"
                  style={{ color: "white" }}
                ></i>
              </button>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${
                    user.images ? user.images[0] : "/images/person.png"
                  })`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  filter: "blur(40px)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              ></div>
              <img
                className="original-image"
                src={user.images ? user.images[0] : "/images/person.png"}
                alt="User"
                style={{
                  height: "65vh",
                  position: "relative",
                  zIndex: 2,
                }}
              />
            </div>
            <div class="blur-effect"></div>
            <div class="shade-effect "></div>
          </div>
          <div className="shade-effect1"></div>

          <div
            className="profile"
            style={{ borderRadius: "25px", background: "#120c34" }}
          >
            <div
              className="card-body1  px-3 pt-4"
              style={{ background: "#120c34" }}
            >
              <h3 className="card-text alignleft name_font">
                <b> {user.name}</b>
              </h3>
              <h3
                className="card-text alignright location_font"
                style={{ fontWeight: "bold", fontSize: "1.1rem" }}
              >
                {user.location
                  ? this.truncateAddress(user.location, 5)
                  : "No location"}
                &nbsp;<i className="fa-solid fa-location-dot"></i>
              </h3>
            </div>

            <div className="card-body2 px-3  d-grid py-2">
              <a href="" style={{ textDecoration: "none" }}>
                <img
                  src="/images/instagram.png"
                  className="social_logo"
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <h3 className="social_font" style={{ fontWeight: "bold" }}>
                {user.social && user.social.instagram
                  ? user.social.instagram.toLocaleString()
                  : "0"}
              </h3>
              <br />
              <a href="" style={{ textDecoration: "none" }}>
                <img
                  src="/images/tiktok.png"
                  className="social_logo"
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <h3 className="social_font" style={{ fontWeight: "bold" }}>
                {user.social && user.social.tiktok
                  ? user.social.tiktok.toLocaleString()
                  : "0"}
              </h3>
            </div>

            <div className="d-grid px-3 mb-2">
              <button
                className="small-rounded-button-modal mr-3 px-2"
                style={{
                  borderRadius: "25px",
                  fontWeight: "800",
                  cursor: "default",
                  color: "#120c34",
                }}
                disabled
              >
                cool
              </button>
              <button
                className="small-rounded-button-modal mr-3 px-2"
                style={{
                  borderRadius: "25px",
                  fontWeight: "800",
                  cursor: "default",
                  color: "#120c34",
                }}
                disabled
              >
                cool
              </button>
              <button
                className="small-rounded-button-modal mr-3 px-2"
                style={{
                  borderRadius: "25px",
                  fontWeight: "800",
                  cursor: "default",
                  color: "#120c34",
                }}
                disabled
              >
                click me
              </button>
            </div>

            <div className="text-center pb-3">
              <button
                className="btn btn-lg form-button"
                onClick={this.toggleForm}
                style={{
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  marginLeft: "-32px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  start request
                </span>
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { content, campaigns, approved } = this.state;
    if (!this.isLogedin()) {
      // Redirect to /signin if not logged in
      return <Navigate to="/signin" replace />;
    }
    if (!this.checkAccount()) {
      // console.log("matched and runned");
      return <Navigate to="/home" replace />;
    }
    const randomNumber1 = Math.floor(Math.random() * 100);
    const randomNumber2 = Math.floor(Math.random() * 100);
    const randomNumber3 = Math.floor(Math.random() * 100);
    const { data } = this.state;
    const normalizedStatus = typeof this.state.status === 'object' ? this.state.status.value : this.state.status;

    return (
      <div>
        <div className="container-fluid mt-3 px-md-5">
          <div
            className="container-fluid p-4 mt-4"
            style={{ background: "#120c34", borderRadius: "25px" }}
          >
            <div className="row m-0">
              <div className="col-md-4 col-sm-12 mb-4">
                <p className="text-center text-white set_font_mainPage">
                  brand {data.name}
                </p>
                <div
                  className="card p-0"
                  style={{
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                  }}
                >
                  <div className="d-flex flex-column h-100">
                    <div className="row p-2 flex-grow-1">
                      <div className="col-6 col-lg-6 col-sm-12 p-lg-2 text-center text-white">
                        <p
                          className="my-2 name_font"
                          style={{ fontWeight: "600" }}
                        >
                          ${this.state.balance}
                        </p>
                        <div
                          style={{
                            width: "100%",
                            height: "3px",
                            background: "white",
                          }}
                        ></div>
                        <h5
                          className="my-2 name_font_title"
                          style={{ fontWeight: "800" }}
                        >
                          remaining budget
                        </h5>
                      </div>
                      <div className="col-6 col-lg-6 col-sm-12 pt-sm-4 pt-lg-0 d-flex align-items-center justify-content-center">
                        <button
                          type="button"
                          className="btn btn-light px-4 mr-3 manage_button"
                          style={{ borderRadius: "25px" }}
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                        >
                          manage
                        </button>
                        {/* <Link type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} to="/make-payment">Manage</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-12 mx-auto ">
                <h5 className="text-center text-white set_font_mainPage">
                  ambassador performance
                </h5>
                <div className="row p-2">
                  <div className="col-4 col-sm-4 p-md-4 text-center text-white">
                    <h4 className="my-2" style={{ fontWeight: "600" }}>
                      {this.state.ambassador}
                    </h4>
                    <div
                      style={{
                        width: "100%",
                        height: "3px",
                        backgroundColor: "#3898ec",
                        backgroundImage:
                          "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      }}
                    ></div>
                    <h4
                      className="my-2 name_font"
                      style={{ fontWeight: "800" }}
                    >
                      ambassador
                    </h4>
                  </div>
                  <div className="col-4 col-sm-4 p-md-4 text-center text-white">
                    <h4 className="my-2" style={{ fontWeight: "600" }}>
                      {this.state.post}
                    </h4>
                    <div
                      style={{
                        width: "100%",
                        height: "3px",
                        backgroundColor: "#3898ec",
                        backgroundImage:
                          "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      }}
                    ></div>
                    <h4
                      className="my-2 name_font"
                      style={{ fontWeight: "800" }}
                    >
                      post
                    </h4>
                  </div>
                  <div className="col-4 col-sm-4 p-md-4 text-center text-white">
                    <h4 className="my-2" style={{ fontWeight: "600" }}>
                      {this.state.clicks}
                    </h4>
                    <div
                      style={{
                        width: "100%",
                        height: "3px",
                        backgroundColor: "#3898ec",
                        backgroundImage:
                          "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      }}
                    ></div>
                    <h4
                      className="my-2 name_font"
                      style={{ fontWeight: "800" }}
                    >
                      clicks
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade bd-example-modal-lg mx-4"
            id="exampleModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            onClick={(e) => {
              // Close modal when clicked outside
              if (e.target.classList.contains("modal")) {
                this.setState({ currentStepAmbassador: 3, fundsError: "" });
              }
            }}
            style={{ borderRadius: "25px" }}
          >
            <div
              class="modal-dialog modal-lg"
              style={{
                minHeight: "fit-content !important",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              role="document"
            >
              <div
                class="modal-content"
                style={{ borderRadius: "25px", overflow: "hidden" }}
              >
                <div class="modal-body">{this.renderAmbassdorProgramne()}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-4 mb-4 px-3 pb-3"
          style={{
            backgroundColor: "#120c34",
            borderRadius: "25px",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <br />

          <div
            className="d-flex flex-column flex-md-row justify-content-between align-item-center"
            style={{ gap: "16px" }}
          >
            <div className="">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span className="Sw-Choice text-white">pending</span>
                <label className="switch mb-0">
                  <input
                    type="checkbox"
                    checked={approved}
                    onChange={this.handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="Sw-Choice text-white">approved</span>
              </div>
            </div>
            <div className="">
              <h3 className="px-2 text-center text-white ">embassy library</h3>
            </div>
            {/* <div className="col-md-2"></div> */}
            <div className="">
              <select
                className="form-select"
                name="status"
                placeholder={"Select status"}
                style={{
                  borderRadius: "25px",
                  width: "100%",
                  background: "#fff",
                  border: "1px solid grey",
                  padding: "0.6rem 0.8rem",
                }}
                value={normalizedStatus}
                onChange={this.handleStatusChange}
              >
                {[
                  { value: "ambassador", label: "ambassador" },
                  { value: "marketplace", label: "marketplace" },
                ].map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div
            // className="row d-flex mt-3 bg-light p-2 m-1"
            className="mt-3 bg-light p-2 m-1 content-card-wrapper"
            style={{ borderRadius: "25px", gap: '12px' }}
          >
            {content?.length ? (
              content.map((item, index) => (
                <div
                  onClick={() => {
                    this.handleShow();
                    this.setState({ currentItem: item, currentIndex: index });
                  }}
                  style={{
                    cursor: "pointer",
                    aspectRatio: "1/1",
                    position: "relative",
                  }}
                  key={index}
                  >
                  <div
                    style={{
                      background: "#ddd",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      objectFit: "scale-down",
                      aspectRatio: "1/1",
                      borderRadius: "25px",
                    }}
                  >
                    <img
                      src={item.image}
                      className="img-fluid"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "scale-down",
                        aspectRatio: "1/1",
                        borderRadius: "25px",
                      }}
                      alt="Content"
                      onError={(e) => {
                        e.target.src = "/images/videologo.png";
                        e.target.alt = "Fallback Image";
                      }}
                    />
                  </div>

                  {/* <div
                    className="modal fade bd-example-modal-lg fit-content"
                    id={`modal-${item.id}`}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-lg modal-dialog-centered"
                      style={{ borderRadius: "25px" }}
                    >
                      <div
                        className="modal-content"
                        style={{ borderRadius: "25px", overflow: "hidden" }}
                      >
                        <div
                          className="modal-body p-0"
                          style={{ background: "#120c34" }}
                        >
                          <div style={{ borderRadius: "25px" }}>
                            <div style={{ position: "relative" }}>
                              <div
                                className="text-center"
                                style={{
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  style={{
                                    zIndex: "10000000000",
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    fontSize: "1.5rem",
                                    color: "#fff",
                                  }}
                                >
                                  <i
                                    class="fa-solid fa-xmark fa-2x"
                                    style={{ color: "white" }}
                                  ></i>
                                </button>
                                <img
                                  className="original-image"
                                  src={item.image}
                                  alt="User"
                                  style={{
                                    height: "65vh",
                                    position: "relative",
                                    zIndex: 2,
                                  }}
                                  onError={(event) =>
                                    this.handleImageError(event, item.image)
                                  }
                                />
                              </div>
                              <div class="blur-effect"></div>
                              <div class="shade-effect "></div>
                            </div>
                          </div>
                          <a
                            style={{
                              fontSize: "28px",
                              textDecoration: "none",
                            }}
                            href={item?.creator_link}
                            className="text-white px-3 py-1 decoration-none"
                          >
                            @{item.creator}
                          </a>
                          <p className="text-white px-3">{item.caption}</p>
                          {(!(item.status === "Approved") ||
                            !(item.status === "Rejected")) && (
                            <div
                              className="p-3"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "20%",
                              }}
                            >
                              <div
                                className="card bg-success"
                                style={{ borderRadius: "100%" }}
                                onClick={() => this.handleReject(item.id)}
                              >
                                <div
                                  className="card-body bg-danger text-center "
                                  style={{
                                    borderRadius: "100%",
                                    border: "white solid 3px",
                                  }}
                                >
                                  <i
                                    class="fa-solid fa-xmark fa-3x"
                                    style={{ color: "#120c34" }}
                                  ></i>
                                </div>
                              </div>
                              <div
                                className="card bg-success"
                                style={{ borderRadius: "100%" }}
                                onClick={() => this.handleApprove(item.id)}
                              >
                                <div
                                  className="card-body bg-sucess text-center "
                                  style={{
                                    borderRadius: "100%",
                                    border: "white solid 3px",
                                  }}
                                >
                                  <i
                                    class="fa-solid fa-check fa-3x"
                                    style={{ color: "#120c34" }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              ))
            ) : !this.state.loading ? (
              <div className="container d-flex justify-content-center align-items-center ">
                <small
                  className="m-3 text-center"
                  style={{
                    fontSize: "20px",
                    color: "#120c34",
                    cursor: "pointer",
                  }}
                >
                  no data found
                </small>
              </div>
            ) : (
              <div className="container d-flex justify-content-center align-items-center">
                <Animation_Custom />
              </div>
            )}
          </div>
          {this.state.loading && (
            <div className="container d-flex justify-content-center align-items-center">
              <Animation_Custom />
            </div>
          )}
        </div>
        <ModalComponent
          show={this.state.showModal}
          onHide={this.handleClose}
          showSliderBtn={true}
          handleNext={this.handleNext}
          handlePrev={this.handlePrev}
          bodyContent={
            <EmbassyViewModal
              item={this.state.currentItem}
              handleApprove={this.handleApprove}
              handleReject={this.handleReject}
              handleImageError={this.handleImageError}
              handleClose={this.handleClose}
            />
          }
          customClass="custom-modal-content"
        />
        <ToastContainer />
      </div>
    );
  }
}
