import { Icon } from '@iconify-icon/react/dist/iconify.mjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Carousel from './Carousal/Carousal';
import { loadStripe } from "@stripe/stripe-js";
import {toast} from 'react-toastify';

const DashboardMain = () => {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [addFunds, setAddFunds] = useState(0);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onPaymentModalOpen = () => setIsModalOpen(true);
  const onPaymentModalClose = () => {
    setIsModalOpen(false);
    setAddFunds(0);
  }


  const isLoggedIn = () => {
    return window.localStorage.getItem("isLogedin") === 'true';
  };

  const checkAccount = () => {
    return window.localStorage.getItem("account") === "1"; // Assuming "1" is the account type you want to check
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");
      const headers = new Headers({
        'Authorization': `Bearer ${token}`
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/dashboard`, {
          method: 'GET',
          headers: headers
        });
        const result = await response.json();

        if (response.ok && result.status === 'SUCCESS') {
          setData(result.data);
        } else if (result.code === 'token_not_valid') {
          window.localStorage.clear();
          console.error('Token is invalid or expired');
          window.location.href = '/signin'; // Automatically redirect to /signin
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        setError(error.message);
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleAddFundsToAccount = async () => {
    const token = localStorage.getItem("accessToken");
    const headers = new Headers({
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    });

    try {
      const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      );
      const stripe = await stripePromise;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/auth/v1/payout`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            amount: parseFloat(addFunds),
          }),
        }
      );
      const {
        data: { id: sessionId },
      } = await response.json();

      if(sessionId){
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error("Error:", error);
        }
      }
      else {
        toast.error("Unable to connect to payment portal! Try again later.")
        setTimeout(()=> {
          window.location.reload();
        }, 2000)
      }
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    }
    finally {
      setAddFunds(0)
    }
  }

  if (!isLoggedIn()) {
    return <Navigate to="/signin" replace />;
  }

  if (!checkAccount()) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="container-fluid mt-3 px-md-5">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card border-0 text-white px-3 px-sm-4 px-md-5 py-3" style={{ background: "#120c34", borderRadius: "25px" }}>
            <div className='d-flex align-items-center flex-wrap justify-content-between' style={{ gap: '1rem', paddingBottom: '2rem' }}>
              <div className='d-flex align-items-center flex-wrap justify-content-between' style={{ gap: '1rem' }}>
                <div style={{ borderRadius: '50%', height: '80px', width: '80px' }}>
                  <img 
                    src={data?.user?.image || '/default-avatar.png'} 
                    height={'100%'} 
                    width={'100%'} 
                    alt='user' 
                    style={{ borderRadius: '50%', objectFit: 'cover' }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/default-avatar.png';
                    }}
                  />
                </div>
                <div>
                  <div style={{ fontSize: 'x-large' }}>{data?.user?.name}</div>
                  <div style={{ fontSize: 'large' }}>{data?.user?.social_media?.text}</div>
                </div>
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={onOpenModal}
              >
                <Icon  icon="line-md:link" width="48" height="48" />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-0">
              <div className="text-left">
                <h4 style={{ fontSize: "2.1rem", padding: 0, margin: 0 }}>$ {(data?.user?.balance || 0).toFixed(2)}</h4>
                <div className="mb-0" style={{ fontSize: 'large' }}>current balance</div>
              </div>
              <button
                className="btn btn-lg btn-custom"
                style={{
                  letterSpacing: '-0.02em',
                  textTransform: 'none',
                  borderRadius: '25px',
                  fontFamily: 'sofia-pro, sansSerif',
                  fontSize: 'large',
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: 'transform 0.3s'
                }}
                onClick={onPaymentModalOpen}
              >
                <span style={{ color: '#fff', lineHeight: 'inherit', cursor: 'pointer', letterSpacing: '1px' }}>
                  manage
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-md-6 col-sm-12">
          <h3 className="px-md-2 mb-4">performance snapshot</h3>
          <div className="row text-center">
            <div className="col-12 col-md-4 mb-3">
              <input
                type="text"
                className="form-control input-custom bg-transparent text-center"
                value={data?.impression}
                readOnly
                style={{ fontSize: '2.1rem', color:' #120c34'}}
              />
              <small style={{ color: 'grey' }}>impression</small>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <input
                type="text"
                className="form-control input-custom bg-transparent text-center"
                value={data?.clicks}
                readOnly
                style={{ fontSize: '2.1rem', color:'#120c34'}}
              />
              <small style={{ color: 'grey' }}>clicks</small>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <input
                type="text"
                className="form-control input-custom bg-transparent text-center"
                value={data?.post}
                readOnly
                style={{ fontSize: '2.1rem', color:'#120c34'}}
              />
              <small style={{ color: 'grey' }}>posts</small>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={onCloseModal}
        closeOnOverlayClick={true}
        center
        showCloseIcon={false}
        style={{
          background: 'grey'
        }}
      >
        <Icon
          onClick={onCloseModal}
          style={{position: 'absolute', top: '12px', right: '12px', cursor: 'pointer'}}
          icon="ic:twotone-close"
          width="32"
          height="32"
        />
        <div style={{marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', textAlign: 'center'}}>
        <h2>
        Add this link to your social media bios or send it directly to brands so that they can submit requests to you!
        </h2>
        <div style={{background: '#fff', color: '#120c34', textOverflow: 'ellipsis', textAlign: 'center', padding: '8px 4px', borderRadius: '24px', width: '80%'}}>
          {data?.user?.social_media?.url}
        </div>
        <button 
          className="btn btn-lg btn-custom"
          onClick={() => {
            navigator.clipboard.writeText(data?.user?.social_media?.url);
            alert('Copied to Clipbord.')
          }}
        >
          <span>copy link</span>
        </button>

        </div>
      </Modal>

      <Modal
        open={isModalOpen}
        onClose={onPaymentModalClose}
        closeOnOverlayClick={true}
        center
        showCloseIcon={false}
        style={{
          background: 'grey'
        }}
      >
        <Icon
          onClick={onPaymentModalClose}
          style={{position: 'absolute', top: '12px', right: '12px', cursor: 'pointer'}}
          icon="ic:twotone-close"
          width="32"
          height="32"
        />
        <div style={{marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', textAlign: 'center'}}>
          <div style={{fontSize: 'xx-large', fontWeight: 600}}>withdraw funds</div>
        <div style={{fontSize: 'large'}}>
          how much would you like to withdraw?
        </div>
        <div
                style={{
                  position: "relative",
                  width: "50%",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "x-large",
                    color: '#000'
                  }}
                >
                  $
                </span>
                <input
                  type="number"
                  onChange={(e) => setAddFunds(e.target.value)}
                  style={{
                    border: "#120c34 2px solid",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    paddingLeft: "30px",
                  }}
                  placeholder="add funds"
                  className="input-box rounded-pill p-2 text-center"
                  min="100"
                  value={addFunds}
                />
              </div>
        <div>
        <div style={{fontWeight: 600}}>Note:</div>
        <div>These funds will be added to your default payment method that was previousy used.</div>
        </div>
        <button 
          className="btn btn-lg btn-custom"
          onClick={handleAddFundsToAccount}
          disabled={!addFunds || addFunds === 0}
        >
          <span>withdraw</span>
        </button>

        </div>
      </Modal>

<div className='my-4'>
    <Carousel Headline={'trending campaigns'} data={data?.campaigns} />
    <Carousel Headline={'trending brands'} data={data?.brands} />
</div>

    </div>
  );
};

export default DashboardMain;
