import React, { useRef, useEffect, useState } from 'react';
import './Carousal.scss'; // Import your CSS styles
import { Icon } from '@iconify-icon/react/dist/iconify.mjs';
import { useNavigate } from 'react-router-dom';

const Carousel = ({ Headline, data }) => {
  const contentRef = useRef(null);
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const [hasOverflow, setHasOverflow] = useState(false);
  
  const maxScrollWidth = contentRef.current ? contentRef.current.scrollWidth - contentRef.current.clientWidth : 0;

  const scrollTo = (offset) => {
    if (contentRef.current) {
      contentRef.current.scrollBy({
        left: offset,
        behavior: 'smooth',
      });
    }
  };

  const handleNextClick = () => {
    scrollTo(contentRef.current.clientWidth / 2);
  };

  const handlePrevClick = () => {
    scrollTo(-contentRef.current.clientWidth / 2);
  };

  const updateScrollPosition = () => {
    if (contentRef.current) {
      setScrollPosition(contentRef.current.scrollLeft);
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef?.current?.addEventListener('scroll', updateScrollPosition);
      return () => {
        contentRef?.current?.removeEventListener('scroll', updateScrollPosition);
      };
    }
  }, []);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const hasHorizontalOverflow = contentRef.current.scrollWidth > contentRef.current.clientWidth;
        setHasOverflow(hasHorizontalOverflow);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [data]);

  const handleCardClick = (id) => {
    if (Headline === 'trending campaigns') {
        navigate(`/trending-campaign/${id}`)
    }
    // else if (Headline === 'Trending Brands') {
    //     navigate(`/trending-campaign/${id}`)
    // }
  }

  return (
    <div className="carousel">
      <div className="carousel__wrapper">
        <div className="carousel__header">
          <div className='set_font_mainPage'>{Headline}</div>
          {hasOverflow && (
            <div className="carousel__controls">
              <Icon 
                icon="iconamoon:arrow-left-2-light" 
                width="24" 
                height="24" 
                className={`${scrollPosition <= 10 ? 'disabled' : ''}`}
                onClick={handlePrevClick}
                ref={prevButtonRef}
              />
              <Icon 
                icon="iconamoon:arrow-right-2-light" 
                width="24" 
                height="24"
                className={`${scrollPosition >= maxScrollWidth - 10 ? 'disabled' : ''}`}
                onClick={handleNextClick}
                ref={nextButtonRef}
              />
            </div>
          )}
        </div>
        <ul className="carousel__content" ref={contentRef}>
        {data?.map((element, i) => (
          <div key={i}
          className='circular-cards'
          onClick={() => handleCardClick(element?.id)}
          >
            <img 
              src={element?.image} 
              height={'100%'} 
              width={'100%'} 
              alt='campaign' 
              style={{ borderRadius: '50%', objectFit: 'cover' }}
            />
          </div>
        ))}
        </ul>
      </div>
    </div>
  );
};

export default Carousel;
