import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Apiloading_animation from "../Animation/Apiloading_animation";
import Animation_Custom from "../AnimationCustom/Animation_Custom";

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(null);

  useEffect(() => {
      const fetchData = async () => {
          const token = localStorage.getItem("accessToken");
          const url = `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?campaign_type=3,4&status=3&page=${page}`;

          try {
              setLoading(true)
              const response = await fetch(url, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              });

              const result = await response.json();

              if(result?.error_code === 400){
                  setHasMore(null);
              }

              if (result.status === 'SUCCESS') {
                  setCampaigns(prevCampaigns => [...prevCampaigns, ...result.data.results]);
                  setHasMore(result.data.next);
              } else {
                  if (result.code === 'token_not_valid') {
                      window.localStorage.removeItem("refreshToken");
                      window.localStorage.removeItem("accessToken");
                      window.localStorage.removeItem("userName");
                      window.localStorage.removeItem("isLogedin");
                      console.error('Token is invalid or expired');
                      window.location.href = '/signin';
                  } else {
                      throw new Error('Failed to fetch data');
                  }
              }
          } catch (error) {
              console.error('Failed to fetch campaigns:', error);
          } finally {
              setLoading(false);
          }
      };

      fetchData();
  }, [page]);

  const containerRef = useRef();

  useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const handleScroll = () => {

          const { scrollTop, scrollHeight, clientHeight } = container;
          // Check if user scrolled to bottom of container
          if (
              scrollTop + clientHeight >= scrollHeight - 10 && // Adjust threshold as needed
              hasMore && 
              !loading
          ) {
              setPage(prevPage => prevPage + 1); // Increment page number
          }
      };

      // Attach scroll event listener to the container
      container.addEventListener("scroll", handleScroll);
  
      // Cleanup the event listener on unmount
      return () => container.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading]);

  // if (!campaigns || campaigns.length === 0) {
  //     return <h1 className="text-center">No campaigns available <Link to='/campaigns_create' style={{color:"#120c34"}}><u>create</u></Link>.</h1>;
  // }
  if (!campaigns || campaigns.length === 0) {
    return (
      <>
        {/* <Apiloading_animation /> */}
        <div className="d-flex justify-content-center align-items-center">
          <Animation_Custom />
        </div>
      </>
    );
  }

  return (
    <div className="row"  ref={containerRef} id="campaign_container" style={{ height: "80vh", overflowY: "scroll", scrollbarWidth: "none", msOverflowStyle: "none", "&::-webkit-scrollbar": { display: "none" } }}>
      {campaigns.map((campaign, index) => (
        <div className="col-xs-12 col-sm-6 col-lg-4 col-xl-3 mb-3" key={index}>
          <div className="card border-0">
            <div
              className="card-body text-center text-white px-5"
              style={{ borderRadius: "25px", background: "#120c34" }}
            >
              <h2 className="mb-3">{campaign.title}</h2>
              <h4 className="mb-3">
                {campaign.details.startDate} to{" "}
                {campaign.details.endDate
                  ? campaign.details.endDate
                  : "ongoing"}
              </h4>
              <h4 className="mb-4">${campaign.budget} remaining</h4>
              <Link
                to={`/campaigns_manage?id=${campaign.id}`}
                className="btn btn-lg"
                style={{
                  width: "80%",
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    border: "0",
                    borderRadius: "0",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  manage
                </span>
              </Link>
            </div>
            {/* <span className="notification-badge">{index + 1}</span> */}
          </div>
        </div>
      ))}

{loading && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Animation_Custom />
                    </div>
                )}
    </div>
  );
};

const DraftCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?status=1&campaign_type=3,4`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status === "SUCCESS") {
        setCampaigns(result.data.results);
      } else {
        console.error("Failed to fetch campaigns");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="row">
      {campaigns.map((campaign, index) => (
        <div className="col-md-4 mb-3" key={index}>
          <div className="card">
            <div
              className="card-body text-center text-white px-5"
              style={{ borderRadius: "25px", background: "#120c34" }}
            >
              <h2 className="mb-3">{campaign.title}</h2>
              <h4 className="mb-3">
                {campaign.start_datetime.slice(0, 10)} to{" "}
                {campaign.end_datetime
                  ? campaign.end_datetime.slice(0, 10)
                  : "ongoing"}
              </h4>
              <h4 className="mb-4">
                ${campaign.budget}, {campaign.status} remaining
              </h4>
              <Link
                to={`/campaigns_manage?id=${campaign.id}`}
                className="btn btn-lg"
                style={{
                  width: "80%",
                  letterSpacing: "-.02em",
                  textTransform: "none",
                  backgroundColor: "#3898ec",
                  backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                  borderRadius: "25px",
                  fontFamily: "sofia-pro,sans-serif",
                  fontSize: "1.2em",
                  fontWeight: 600,
                  lineHeight: 1,
                  transition: "transform .3s",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    lineHeight: "inherit",
                    cursor: "pointer",
                    border: "0",
                    borderRadius: "0",
                    padding: "9px 15px",
                    textDecoration: "none",
                    display: "inline-block",
                  }}
                >
                  manage
                </span>
              </Link>
            </div>
            <span className="notification-badge">{index + 1}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default function Campaign_Dashboard() {
  return (
    <div>
      <div className="container-fluid px-5">
        <div className="row mt-3">
          <div className="col-md-4">
            <h3>campaigns</h3>
          </div>
          <div className="col-md-4">
            {/* <Link type="button" to="#" className="btn bg-transparent btn-lg" style={{ color: "#120c34", width: "100%", border: "#120c34 3px solid", borderRadius: "25px" }}>
                            Archived Campaigns
                             <span className="notification-badge">{1}</span>
                        </Link> */}
          </div>
          <div className="col-md-4 text-right">
            <Link
              to="/campaigns_create"
              className="btn btn-lg"
              style={{
                width: "100%",
                letterSpacing: "-.02em",
                textTransform: "none",
                backgroundColor: "#3898ec",
                backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                borderRadius: "25px",
                marginLeft: "-32px",
                fontFamily: "sofia-pro,sans-serif",
                fontSize: "1.2em",
                fontWeight: 600,
                lineHeight: 1,
                transition: "transform .3s",
                display: "inline-block",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  lineHeight: "inherit",
                  cursor: "pointer",
                  // backgroundColor: '#3898ec',
                  border: "0",
                  borderRadius: "0",
                  padding: "9px 15px",
                  textDecoration: "none",
                  display: "inline-block",
                }}
              >
                create a campaign
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid px-5 mt-5">
        <h3 className="mb-3">active campaign</h3>
        <Campaigns />
      </div>
      {/* <div className="container-fluid px-5 mt-5">
                <h3 className='mb-3'>Draft</h3>
                <DraftCampaigns/>
            </div> */}
    </div>
  );
}
