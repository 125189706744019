import { Icon } from "@iconify-icon/react";
import React, { Component } from "react";

class InfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
    };
  }

  // Show info when hovering over the icon
  handleMouseEnter = () => {
    this.setState({ showInfo: true });
  };

  // Hide info when mouse leaves the icon
  handleMouseLeave = () => {
    this.setState({ showInfo: false });
  };

  render() {
    const cardStyle = {
      position: "relative",
      display: "inline-block",
    };

    const iconStyle = {
      backgroundColor: "#d9d9d9",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
      textAlign: "center",
      lineHeight: "20px",
      fontSize: "14px",
      cursor: "pointer",
      display: "inline-block",
      marginLeft: "5px",
    };

    // Tooltip text style: position relative to the icon and visible only when showInfo is true
    const infoTextStyle = {
      position: "absolute",
      top: "35px", // Adjust to show the tooltip just below the icon
      left: "-200px",
      backgroundColor: "white",
      border: "2px solid #120c34",
      borderRadius: "25px",
      padding: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      width: "250px", // Ensure it fits within the div
      zIndex: 10,
      display: this.state.showInfo ? "block" : "none",
    };

    const textStyle = {
      margin: "0",
      fontSize: "14px",
      color: "#333",
    };

    return (
      <div
      style={cardStyle}
      >
        <div
          // style={iconStyle}
          onMouseEnter={this.handleMouseEnter} // Show the info card on hover
          onMouseLeave={this.handleMouseLeave} // Hide the info card when the mouse leaves
        >
          <Icon
            icon="ic:baseline-info"
            width="16"
            height="16"
            style={{ color: "#525252" }}
          />
        </div>
        <div style={infoTextStyle}>
          <p style={textStyle}>{this.props.infoText}</p>
        </div>
      </div>
    );
  }
}

export default InfoCard;
