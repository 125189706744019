import { useEffect, useState } from "react";

const useTabFocusCheck = (isLoggedIn) => {
    // const [tabFocused, setTabFocused] = useState(document.visibilityState === 'visible');
  
    useEffect(() => {
      const handleFocus = () => {
        // setTabFocused(true);
        if (!isLoggedIn) {
            window.location.reload();
          console.log('User is not logged in. Redirecting to login...');
        }
      };
  
      // const handleBlur = () => {
      //   setTabFocused(false);
      // };
  
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          handleFocus();
        } 
        // else {
        //   handleBlur();
        // }
      };
  
      window.addEventListener('focus', handleFocus);
      // window.addEventListener('blur', handleBlur);
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        window.removeEventListener('focus', handleFocus);
        // window.removeEventListener('blur', handleBlur);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, [isLoggedIn]);
  
    // return tabFocused;
  };

  export default useTabFocusCheck;