// ModalComponent.js
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../App.css"

const ModalComponent = ({
  type,
  show,
  onHide,
  title,
  bodyContent,
  footerContent,
  bodyClass,
  customClass,
  showSliderBtn = false,
  handleNext,
  handlePrev,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={customClass}
      style={{

        borderRadius: "25px", // Custom border radius for the modal
        border: "none", // Removing default border,
        // margin: "0 1rem",
        overflowY: type === 'requests' ? "auto" : "hidden",
        scrollbarWidth: "none", // Firefox
        msOverflowStyle: "none", // IE/Edge
        "&::-webkit-scrollbar": { // Chrome/Safari/Webkit
          display: "none"
        }
      }}
    >
      {showSliderBtn && (
        <>
          <div
            onClick={() => handlePrev()}
            style={{
              position: "absolute",
              top: "50%",
              cursor: "pointer",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              background: "#d2d2d2",
              border: "2px solid white",
              borderRadius: "100%",
              // left: "-240px",
              zIndex: 1000,
              // right: "110%",
            }}
            className="prev_image_icon"
          >
            {/* <i class="fa fa-chevron-left" aria-hidden="true"></i> */}
            <Icon icon="ooui:previous-ltr" width="20" height="20" />
          </div>
          <div
            onClick={() => handleNext()}
            style={{
              position: "absolute",
              top: "50%",
              cursor: "pointer",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              background: "#d2d2d2",
              border: "2px solid white",
              borderRadius: "100%",
              zIndex: 1000,
              // right: "-240px",
              // left: "110%",
            }}
            className="next_image_icon"
          >
            {/* <i class="fa fa-chevron-right" aria-hidden="true"></i> */}
            {/* <Icon icon="ic:round-navigate-prev" width="24" height="24" /> */}
            <Icon icon="ooui:previous-rtl" width="20" height="20" />
          </div>
        </>
      )}
      {title && (
        <Modal.Header>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{title}</span>
            <i
              onClick={() => onHide()}
              class="fa fa-times-circle"
              style={{ color: "#120c34", fill: "#120c34", cursor: "pointer" }}
              aria-hidden="true"
            ></i>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={{padding: '0px'}} className={bodyClass}>{bodyContent}</Modal.Body>
      {/* <Modal.Footer>
        {footerContent ? footerContent : (
          <>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={onHide}>
              Save changes
            </Button>
          </>
        )}
      </Modal.Footer> */}
    </Modal>
  );
};

export default ModalComponent;
